export const colors = {
    primary: {
        100: '#FFFFFF',
        150: '#FAFAFA',
        200: '#F6F6F6',
        250: '#DFE0EB',
        300: '#D9D9D9',
        400: '#BABABA',
        500: '#808080',
        600: '#3A4049',
        700: '#000000',
        800: '#7B8A93',
        900: '#3E474D',
    },
    secondary: {
        100: '#CAD5E8',
        200: '#CDD3E1',
        300: '#9FA2B4',
        400: '#636C7A',
        500: '#1781E2',
        600: '#5F6368',
        700: '#1C1D1F',
        800: '#2E57A3',
        850: '#FC9E19',
        900: '#06F810',
        950: '#04B20B',
    },
    success: 'green',
    error: {
        100: 'red',
        200: '#F32053',
        300: '#EB2323',
    },
};
