import { useEffect, useState } from "react";
import { Box, Grid, Skeleton, Tooltip, Typography } from "@mui/material";
import DollarSymbol from "../../assets/icons/DollarCircleOutlined.png";
import { colors } from "../../style/themes";
import { formatTextLimit } from "../../utils/formatTextLenght";
import { FinancialService } from "../../services/financial.service";

import { format } from "date-fns";
import { useSnackbar } from "notistack";
import dayjs from "dayjs";

interface IBodyInvoice {
  id: string;
  cost: number;
  paid: boolean;
  current: boolean;
  date: string;
  detail: {};
  enterpriseId: string;
  licenseId: string | null;
  status: string;
  amount: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
}

interface IInvoiceHistory {
  tokenUser: string;
}

export const InvoiceHistory = ({ tokenUser }: IInvoiceHistory) => {
  const { enqueueSnackbar } = useSnackbar();
  const idEnt = localStorage.getItem("idEnterprise") || "";
  const financialService = new FinancialService();
  const [dataInvoice, setDataInvoice] = useState<Array<IBodyInvoice> | null>();
  const [loadingInvoice, setLoadingInvoice] = useState<boolean>(false);

  const skeletonData = [{}, {}, {}, {}, {}];

  const getInvoice = async () => {
    setLoadingInvoice(true);
    const respGetInvoice = await financialService.getInvoiceHistory(
      tokenUser,
      idEnt
    );
    if (respGetInvoice.status === 200) {
      setLoadingInvoice(false);
      const datas = respGetInvoice.data.map((item: any) => item.date);
      datas.sort(
        (a: string, b: string) => new Date(b).getTime() - new Date(a).getTime()
      );

      respGetInvoice.data.forEach((item: any, index: any) => {
        item.date = datas[index];
      });

      setDataInvoice(respGetInvoice.data);
    }
    if ([400, 404].includes(respGetInvoice.status)) {
      setLoadingInvoice(false);
      enqueueSnackbar(respGetInvoice.statusText, {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    getInvoice();
  }, []);

  // Função para determinar a cor com base no status
  const getStatusColor = (status: string) => {
    switch (status) {
      case "PAID":
        return colors.secondary[950];
      case "PENDING":
        return colors.secondary[850];
      case "OVERDUE":
        return "red";
      default:
        return "";
    }
  };

  return (
    <>
      {loadingInvoice && (
        <Box marginTop={3} marginRight={3}>
          {skeletonData.map((value, index: number) => (
            <Box marginLeft={4} marginTop={1} key={index}>
              <Skeleton
                variant="rectangular"
                sx={{
                  width: "100%",
                  marginRight: "15px",
                  height: "35px",
                  boxSizing: "border-box",
                  borderRadius: "7px",
                }}
              />
            </Box>
          ))}
        </Box>
      )}
      {!loadingInvoice &&
        dataInvoice &&
        dataInvoice.length > 0 &&
        dataInvoice.map((value: IBodyInvoice) => (
          <Box
            sx={{
              height: "38px",
              background: "#fff",
              borderRadius: "11px",
              marginTop: "8px",
              marginRight: "10px",
              marginLeft: "15px",
            }}
          >
            <Grid container md={12} lg={12} wrap="nowrap">
              <Grid
                item
                md={4}
                display="flex"
                alignItems="center"
                paddingLeft="12px"
              >
                <img src={DollarSymbol} style={{ paddingTop: "5px" }} />
                <Box marginLeft="10px">
                  <Typography
                    color={colors.primary[800]}
                    fontFamily="Montserrat"
                    fontSize="10px"
                    fontWeight="600"
                  >
                    ID
                  </Typography>
                  <Typography
                    color={colors.primary[800]}
                    fontFamily="Montserrat"
                    fontSize="12px"
                    fontStyle="normal"
                    fontWeight="600"
                  >
                    <Tooltip title={value.id} placement="top">
                      {formatTextLimit(value.id, 9)}
                    </Tooltip>
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                md={3}
                display="flex"
                alignItems="center"
                paddingLeft="12px"
              >
                <Box marginLeft="10px">
                  <Typography
                    color={colors.primary[800]}
                    fontFamily="Montserrat"
                    fontSize="10px"
                    fontWeight="600"
                  >
                    Date
                  </Typography>
                  <Typography
                    color={colors.secondary[500]}
                    fontFamily="Montserrat"
                    fontSize="12px"
                    fontStyle="normal"
                    fontWeight="600"
                  >
                    {/* {format(new Date(value.date), "yyyy-MM")} */}
                    {dayjs(value.date).toISOString().slice(0, 7)}
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                md={3}
                display="flex"
                alignItems="center"
                paddingLeft="2px"
              >
                <Box marginLeft="10px">
                  <Typography
                    color={colors.primary[800]}
                    fontFamily="Montserrat"
                    fontSize="10px"
                    fontWeight="600"
                  >
                    Amount
                  </Typography>
                  <Typography
                    color={colors.primary[800]}
                    fontFamily="Montserrat"
                    fontSize="12px"
                    fontStyle="normal"
                    fontWeight="600"
                  >
                    R$ {value.amount}
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                md={3}
                display="flex"
                alignItems="center"
                // paddingTop="5px"
                paddingLeft="2px"
              >
                <Box marginLeft="10px">
                  <Typography
                    color={colors.primary[800]}
                    fontFamily="Montserrat"
                    fontSize="10px"
                    fontWeight="600"
                  >
                    Status
                  </Typography>
                  <Typography
                    color={getStatusColor(value.status)}
                    fontFamily="Montserrat"
                    fontSize="10px"
                    fontStyle="normal"
                    fontWeight="600"
                  >
                    {value.status}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        ))}
      {dataInvoice && dataInvoice.length < 1 && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            marginTop: "30px",
          }}
        >
          <Typography fontWeight="500">Invoice history is empty...</Typography>
        </Box>
      )}
    </>
  );
};
