import { createTheme } from '@mui/material/styles';

const theme = createTheme();

export const customTheme = () => {
	return createTheme({
		components: {
			MuiDialog: {
				styleOverrides: {
					root: {
						'& .MuiDialog-paper': {
							borderRadius: '12px',
							border: '1px solid #DEE2E6',
						}
					},
				},
			},
			MuiInputLabel: {
				styleOverrides: {
					root: {
						color: '#7B8A93',
						fontWeight: 500,
						fontSize: '16px',
						fontFamily: 'Montserrat',
						letterSpacing: '1px',
						lineHeight: '14px',
					},
				},
			},
			MuiOutlinedInput: {
				styleOverrides: {
					root: {
						backgroundColor: '#fff',
						borderRadius: '10px',
						boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
						'&:hover': {
							borderColor: '#A2CBFE',
						},
					},
				},
			},
			MuiInputBase: {
				styleOverrides: {
					root: {
						'label + &': {
							marginTop: theme.spacing(3),
							fontStyle: 'normal',
							fontWeight: '500',
							fontSize: '16px',
							color: '#000',
						},
						'& .MuiInputBase-input': {
							position: 'relative',
							width: '100%',
							fontSize: 16,
							padding: '16px 12px',
							borderRadius: '10px',
							// boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
							transition: theme.transitions.create([
								'border-color',
								'background-color',
								'box-shadow',
							]),
						},
						'& .MuiOutlinedInput-notchedOutline': {
							borderRadius: '10px',
							border: '1px solid #A2CBFE',
							top: '-6px',
							'&:hover': {
								borderColor: '#A2CBFE',
							},
						},
						'& .MuiInputBase-inputSizeSmall': {
							border: 'none',
							padding: '10px 10px 10px 0px',
							fontSize: 16,
							transition: theme.transitions.create([
								'border-color',
								'background-color',
								'box-shadow',
							]),
							'&:focus': {
								borderColor: theme.palette.primary.main,
							},
						},
					},
				},
			},
			MuiCard: {
				styleOverrides: {
					root: {
						borderRadius: '12px',
						boxShadow: 'none',
						border: '5px solid #E9ECEF',
						padding: '2rem',
					},
				},
			},
		}
	})
}
