import styled from "styled-components";

export const StyledOrgManagementContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* height: 79%; */
  /* padding: 0 7%; */
  /* margin-top: 4%; */
  font-family: Montserrat;

  .org-management-heading {
    background-color: #f1f3f4;
    height: 62px;
    width: 100%;
    border-radius: 30px 30px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #cbd5e1;
  }

  .org-management-heading h2 {
    font-family: "Montserrat";
    font-size: 1.5rem;
    font-weight: 700;
    color: #666666;
  }

  .org-management-body {
    display: flex;
    flex-direction: row;
    height: 92%;
    margin-top: 1%;
  }

  .org-management-body-left {
    position: relative;
    height: 620px;
    max-width: 417px;
    background-color: #f1f3f4;
    margin-right: 22px;
    border-radius: 0 0 30px 30px;
    font-family: "Montserrat";
    border: 1px solid #cbd5e1;
  }

  .org-management-body-right-bottom h2 {
    font-size: 1.5rem;
  }

  .org-management-body-left .org-management-title {
    width: 80%;
    margin-top: 15%;
    margin-left: 8%;
    margin-bottom: 15%;
    border: 1px solid #979797;
    border-radius: 30px;
  }

  .org-management-body-right {
    display: flex;
    flex-direction: column;
    /* height: 100%; */
    width: 100%;
  }

  .org-management-body-right-top {
    display: flex;
    flex-direction: row;
    /* height: 50%; */
    width: 100%;
  }

  .org-management-body-right-nodes {
    height: 375px;
    width: 100%;
    background-color: #737a80;
    border-radius: 0 0 30px 30px;
  }

  .org-management-body-right-members {
    height: 377px;
    width: 100%;
    background-color: #f1f3f4;
    border-radius: 0 0 30px 30px;
    position: relative;
    padding-right: 0px;
    padding-bottom: 0px;
    border: 1px solid #cbd5e1;
    padding-left: 17px;
  }

  .org-management-body-right-bottom {
    height: 221px;
    display: flex;
    flex-direction: row;
    border: 1px solid #cbd5e1;
    border-radius: 30px;
  }
  .quick-financial {
    height: 100%;
    /* width: 29%; */
    background-color: #f1f3f4;
    /* margin-right: 2%; */
    border-radius: 30px;
  }

  .org-licenses {
    width: 100%;
    height: 100%;
    background-color: #f1f3f4;
    border-radius: 30px;
    position: relative;
  }

  .org-process {
    height: 100%;
    width: 100%;
    background-color: #737a80;
    border-radius: 30px;
  }

  .org-management-title h2 {
    font-family: Montserrat;
    font-size: 25px;
    font-weight: 600;
    color: #5f6368;
  }
`;
