import React, { useState } from "react";
// @ts-ignore
import OwnerInformationForm from "./FormLevels/OwnerInformationForm.tsx";
// @ts-ignore
import EnterpriseInfoForm from "./FormLevels/EnterpriseInfoForm.tsx";
// @ts-ignore
import PaymentMethod from "./FormLevels/PaymentMethod.tsx";
import OwnerWallet from "./FormLevels/OwnerWallet";
import OwnerWalletRegister from "./FormLevels/OwnerWalletRegister";
import EnterpriseWallet from "./FormLevels/EnterpriseWallet";
import EnterpriseWalletRegister from "./FormLevels/EnterpriseWalletRegister";
import Conclusion from "./FormLevels/Conclusion";

import "./enterpriseSignUp.css";

function InitialForm(props: {
  firstName: any;
  lastName: any;
  phone: any;
  email: any;
  signUpStep: number;
  nextHandleClick: any;
  previousHandleClick: any;
  handleClickCreateAccount: any;
}) {
  const [formInfo, setFormInfo] = useState({
    userFirstName: props.firstName,
    userLastName: props.lastName,
    userEmail: props.phone,
    userPhone: props.email,
    userJobTitle: "",
    userCPF: "",
    userPassword: "",
    enterpriseName: "",
    enterpriseCNPJ: "",
    enterpriseZipCode: "",
    entepriseAddress: "",
    entepriseNumber: "",
    entepriseComplement: "",
    entepriseCountry: "",
    entepriseState: "",
    entepriseCity: "",
    paymentMethod: "",
    userWalletAddress: "",
    userWalletName: "",
    userWalletPassword: "",
    enterpriseWalletAddress: "",
    enterpriseWalletName: "",
    enterpriseWalletPassword: "",
  });

  if (props.signUpStep === 0) {
    return (
      <div className="form-signup-container">
        <OwnerInformationForm
          nextHandleClick={props.nextHandleClick}
          formInfo={formInfo}
          setFormInfo={setFormInfo}
        />
      </div>
    );
  } else if (props.signUpStep === 1) {
    return (
      <div className="form-signup-container">
        <EnterpriseInfoForm
          previousHandleClick={props.previousHandleClick}
          nextHandleClick={props.nextHandleClick}
          formInfo={formInfo}
          setFormInfo={setFormInfo}
        />
      </div>
    );
  } else if (props.signUpStep === 2) {
    return (
      <div className="form-signup-container">
        <PaymentMethod
          previousHandleClick={props.previousHandleClick}
          nextHandleClick={props.nextHandleClick}
          formInfo={formInfo}
          setFormInfo={setFormInfo}
        />
      </div>
    );
  } else if (props.signUpStep === 3) {
    return (
      <div className="form-signup-container">
        <OwnerWalletRegister
          previousHandleClick={props.previousHandleClick}
          nextHandleClick={props.nextHandleClick}
          formInfo={formInfo}
          setFormInfo={setFormInfo}
        />
      </div>
    );
  } else if (props.signUpStep === 4) {
    return (
      <div className="form-signup-container">
        <OwnerWallet
          previousHandleClick={props.previousHandleClick}
          nextHandleClick={props.nextHandleClick}
          setFormInfo={setFormInfo}
          formInfo={formInfo}
        />
      </div>
    );
  } else if (props.signUpStep === 5) {
    return (
      <div className="form-signup-container">
        <EnterpriseWalletRegister
          previousHandleClick={props.previousHandleClick}
          nextHandleClick={props.nextHandleClick}
          setFormInfo={setFormInfo}
          formInfo={formInfo}
        />
      </div>
    );
  } else if (props.signUpStep === 6) {
    return (
      <div className="form-signup-container">
        <EnterpriseWallet
          previousHandleClick={props.previousHandleClick}
          nextHandleClick={props.nextHandleClick}
          formInfo={formInfo}
          setFormInfo={setFormInfo}
        />
      </div>
    );
  } else if (props.signUpStep === 7) {
    return (
      <div className="form-signup-container">
        <Conclusion
          previousHandleClick={props.previousHandleClick}
          nextHandleClick={props.nextHandleClick}
          formInfo={formInfo}
        />
      </div>
    );
  }
}

export default InitialForm;
