import React, {useState, useEffect, useRef} from 'react';
// @ts-ignore
import MemberInformationForm from './FormLevels/MemberInformationForm';
// @ts-ignore
import EnterpriseInfoForm from './FormLevels/EnterpriseInfoForm.tsx';
// @ts-ignore
import PaymentMethod from './FormLevels/PaymentMethod.tsx';
import MemberWallet from './FormLevels/MemberWallet';
import MemberWalletRegister from './FormLevels/MemberWalletRegister'
import EnterpriseWallet from './FormLevels/EnterpriseWallet';
import EnterpriseWalletRegister from './FormLevels/EnterpriseWalletRegister';
import Conclusion from './FormLevels/Conclusion';

import './enterpriseSignUp.css';

function MemberInitialForm(props: {
    firstName: any;
    lastName: any;
    phone: any;
    email: any;
    token: any;
    signUpStep: number;
    nextHandleClick: any;
    previousHandleClick: any;
    handleClickCreateAccount: any;
    }){

    const [formInfo, setFormInfo] = useState({
        "userFirstName": props.firstName,
        "userLastName": props.lastName,
        "userEmail": props.phone,
        "userPhone": props.email,
        "userToken": props.token,
        "userJobTitle": "",
        "userCPF": "",
        "userPassword": "",
        "userWalletAddress": "",
        "userWalletName": "",
        "userWalletPassword": "",
    });

    if (props.signUpStep === 0) {
        return (
            <div className='form-signup-container'>
                <MemberInformationForm
                nextHandleClick={props.nextHandleClick}
                formInfo={formInfo}
                setFormInfo={setFormInfo}
                />
            </div>
        );
    } else if (props.signUpStep === 1) {
        return (
            <div className='form-signup-container'>
                <MemberWalletRegister
                previousHandleClick={props.previousHandleClick}
                nextHandleClick={props.nextHandleClick}
                formInfo={formInfo}
                setFormInfo={setFormInfo}
                />
            </div>
        );
    } else if (props.signUpStep === 2) {
        return (
            <div className='form-signup-container'>
                <MemberWallet
                previousHandleClick={props.previousHandleClick}
                nextHandleClick={props.nextHandleClick}
                setFormInfo={setFormInfo}
                formInfo={formInfo}
                />
            </div>
        );
    }else{
        return (
        <div className='form-signup-container'>
                <Conclusion
                previousHandleClick={props.previousHandleClick}
                nextHandleClick={props.nextHandleClick}
                formInfo={formInfo}
                />
            </div>
        );
    }
}

export default MemberInitialForm;