import {
  Box,
  Divider,
  FormGroup,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { WizardContext } from "../../../../context/wizardContext";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { privateBassFields, nodeTemplates, protocolCheck } from "./ReviewConfigData";

export const ConfirmationBaasStack = () => {
  // @ts-ignore
  const { deployData } = useContext(WizardContext);

  const [cordaData, setNetworkVms] = useState<Array<any>>([]);
  const [selectedVm, setSelectedVM] = useState<number>(0);
  const [showPasswordList, setShowPasswordList] = useState<boolean[]>([]);

  useEffect(() => {
    if (cordaData[selectedVm]?.protocolSetup) {
      setShowPasswordList(
        new Array(
          Object.keys(cordaData[selectedVm]?.protocolSetup).length
        ).fill(false)
      );
    }
  }, [selectedVm, cordaData]);

  let result = "";

  // if (deployData.corda?.doorman.length > 0) {
  //   result = "corda";
  // } else if (deployData.substrate?.nodes.length > 0) {
  //   result = "substrate";
  // }

  for (const protocol of protocolCheck) {
    if (
      deployData[protocol]?.nodes?.length > 0 ||
      deployData[protocol]?.doorman?.length > 0
    ) {
      result = protocol;
      break;
    }
  }

  useEffect(() => {
    setNetworkVms([]);
    Object.keys(deployData[result]).map((key, index) => {
      if (nodeTemplates.includes(key)) {
        if (deployData[result][key].length >= 1) {
          deployData[result][key].map((item: any) => {
            setNetworkVms((oldArray) => [...oldArray, item]);
          });
        } else {
          setNetworkVms((oldArray) => [...oldArray, deployData[result][key]]);
        }
      }
    });
  }, []);

  return (
    <Box
      sx={{
        padding: "20px",
        backgroundColor: "#FAFAFA",
        border: "1px solid #A2CBFE",
        borderRadius: "10px",
        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
      }}
    >
      <Grid container md={12}>
        <Grid item md={2}>
          <Typography
            fontFamily="Montserrat"
            fontSize="13px"
            lineHeight={"35px"}
            fontWeight="600"
            color="#3E474D"
          >
            VM {selectedVm + 1}
          </Typography>
        </Grid>
        <Grid item md={10}>
          <Grid container md={12}>
            <Box>
              <IconButton
                onClick={() => {
                  setSelectedVM(selectedVm === 0 ? selectedVm : selectedVm - 1);
                }}
                disabled={selectedVm === 0}
                size="small"
                style={{ height: "100%", width: "20px" }}
                aria-label="prev"
              >
                <NavigateBeforeIcon />
              </IconButton>
              <IconButton
                onClick={() => {
                  setSelectedVM(
                    selectedVm === cordaData.length - 1
                      ? selectedVm
                      : selectedVm + 1
                  );
                }}
                disabled={selectedVm === cordaData.length - 1}
                size="small"
                style={{ height: "100%", width: "20px" }}
                aria-label="next"
              >
                <NavigateNextIcon />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid container md={12} sx={{ paddingTop: "16px", display: "flex" }}>
        <Grid item md={6}>
          {
            <FormGroup row style={{ alignItems: "end" }}>
              <Typography
                fontFamily="Montserrat"
                fontSize="12px"
                fontWeight="600"
                lineHeight="14.63px"
                color="#7B8A93"
                paddingBottom="12px"
                marginRight={"8px"}
              >
                • Name:
              </Typography>
              <Typography
                fontFamily="Montserrat"
                fontSize="12px"
                fontWeight="600"
                lineHeight="14.63px"
                color="#48535C"
                paddingBottom="12px"
                align="left"
              >
                {cordaData[selectedVm]?.machineSetup["nodeName"]}
              </Typography>
            </FormGroup>
          }
        </Grid>
        <Grid item md={6}>
          {
            <FormGroup row>
              <Typography
                fontFamily="Montserrat"
                fontSize="12px"
                fontWeight="600"
                lineHeight="14.63px"
                color="#7B8A93"
                paddingBottom="12px"
                marginRight={"8px"}
              >
                • IP:
              </Typography>
              <Typography
                fontFamily="Montserrat"
                fontSize="12px"
                fontWeight="600"
                lineHeight="14.63px"
                color="#48535C"
                paddingBottom="12px"
              >
                {cordaData[selectedVm]?.machineSetup["ip"]}
              </Typography>
            </FormGroup>
          }
        </Grid>
        <Divider
          color="#F1F3F4"
          style={{ width: "100%", marginBottom: "16px" }}
        />
        <Grid container>
          {cordaData[selectedVm]?.protocolSetup &&
            Object.keys(cordaData[selectedVm]?.protocolSetup).map(
              (key, index) => (
                <>
                  <Grid item md={5}>
                    <ul>
                      <Box
                        key={index}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          height: "20px",
                        }}
                      >
                        <Typography
                          fontFamily="Montserrat"
                          fontSize="12px"
                          fontWeight="600"
                          color="#7B8A93"
                          height={"100%"}
                          alignContent={"center"}
                          paddingBottom="12px"
                          marginRight={"8px"}
                        >
                          {key}:
                        </Typography>
                        <Tooltip
                          title={
                            !showPasswordList[index] &&
                            privateBassFields.includes(key)
                              ? ""
                              : cordaData[selectedVm]?.protocolSetup[key]
                          }
                          placement="top"
                          arrow
                        >
                          <Typography
                            fontFamily="Montserrat"
                            fontSize="12px"
                            fontWeight="600"
                            lineHeight="14.63px"
                            color="#48535C"
                            height={"100%"}
                            alignContent={"center"}
                            style={{
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                            }}
                          >
                            {!showPasswordList[index] &&
                            privateBassFields.includes(key)
                              ? "*******"
                              : cordaData[selectedVm]?.protocolSetup[key]}
                          </Typography>
                        </Tooltip>
                      </Box>
                    </ul>
                  </Grid>
                  <Grid item md={1}>
                    {privateBassFields.includes(key) && (
                      <Box
                        key={index}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          height: "20px",
                          cursor: "pointer",
                        }}
                      >
                        <IconButton
                          onClick={() => {
                            setShowPasswordList((prevArray) =>
                              prevArray.map((item, i) =>
                                i === index ? !item : item
                              )
                            );
                          }}
                          size="small"
                          style={{ height: "20px", width: "20px" }}
                          aria-label="show"
                        >
                          {showPasswordList[index] ? (
                            <VisibilityIcon />
                          ) : (
                            <VisibilityOffIcon />
                          )}
                        </IconButton>
                      </Box>
                    )}
                  </Grid>
                </>
              )
            )}
        </Grid>
      </Grid>
    </Box>
  );
};
