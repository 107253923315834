import React, { FC } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { fonts, colors } from '../../style/themes';

export const HeaderDeployment: FC = () => {
    return (
        <Box sx={{ paddingLeft: '75px', display: 'flex', flexWrap: 'nowrap', marginBottom: '12px' }}>
            <Typography
                sx={{
                    flexBasis: '225px',
                    fontSize: fonts.size[80],
                    fontFamily: fonts.family.secondary,
                    fontWeight: fonts.weight[600],
                    color: colors.secondary[600],
                    textAlign: 'left',
                }}
            >
                Resources
            </Typography>
            <Typography
                sx={{
                    flexBasis: '172px',
                    fontSize: fonts.size[80],
                    fontFamily: fonts.family.secondary,
                    fontWeight: fonts.weight[600],
                    color: colors.secondary[600],
                    textAlign: 'left',
                }}
            >
                ID
            </Typography>
            <Typography
                sx={{
                    flexBasis: '172px',
                    fontSize: fonts.size[80],
                    fontFamily: fonts.family.secondary,
                    fontWeight: fonts.weight[600],
                    color: colors.secondary[600],
                    textAlign: 'left',
                }}
            >
                Organization
            </Typography>
            <Typography
                sx={{
                    flexBasis: '172px',
                    fontSize: fonts.size[80],
                    fontFamily: fonts.family.secondary,
                    fontWeight: fonts.weight[600],
                    color: colors.secondary[600],
                    textAlign: 'left',
                }}
            >
                IP
            </Typography>
            <Typography
                sx={{
                    flexBasis: '98px',
                    fontSize: fonts.size[80],
                    fontFamily: fonts.family.secondary,
                    fontWeight: fonts.weight[600],
                    color: colors.secondary[600],
                    textAlign: 'left',
                }}
            >
                Uptime
            </Typography>
            <Typography
                sx={{
                    flexBasis: '98px',
                    fontSize: fonts.size[80],
                    fontFamily: fonts.family.secondary,
                    fontWeight: fonts.weight[600],
                    color: colors.secondary[600],
                    textAlign: 'left',
                }}
            >
                Alerts
            </Typography>
        </Box>
    )
}
