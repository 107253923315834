import styled from "styled-components";
import ConclusionPNG from "~/assets/conclusion_image.png";


export const StyledConclusion = styled.div`
  padding-bottom: 18%;
  padding-left: 3%;

  .container-owner-wallet{
    background: ${props => `url(${ConclusionPNG}) bottom no-repeat, #1781E2`};
    height: 880px;
    width: 100%;
    padding: 52px 35px 45px 40px;
  }

  .header {
    width: 450px;
    height: 80px;
    margin-left: 110px;
    margin-top: 100px;
  }

  .header-title{
    width: 285px;
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 0px;
    text-align: left;
    padding-top: 0px;
  }

  .header-subtitle{
    break-line: true;
    margin-top: 45px;
    width: 410px;
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 400;
    line-height: 33px;
    letter-spacing: 0px;
    text-align: left;
  }

.footer{
    display: flex;
    margin-top: 553px;
    height: 45px;
    width: 100%;
    // border: 1px solid black;
    justify-content: right;
    justify-itens: right;
    justify-self: right;
    align-content: right;

    button {
        display: flex;
        width: 280px;
        margin: 0px;
        padding: 0px;
        justify-content: center;
        align-self: right;
        line-height: 45px;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0px;
        text-align: center;
        color: #48535C;
        text-transform: capitalize;

        img {
            height: 35px;
            width: 40px;
            margin: 0px;
            padding: 0px;
            margin-left: 6px;
            filter: grayscale(100%);
            align-self: center;
        }
    }
}

`;
