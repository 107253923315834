import { Box, Typography } from "@mui/material";

import deployWizard from "~/assets/imgs/deployWizard.png";
import { Add } from "~/assets/icons/add";

export type TDeployStep0 = {
  handleNextStep: () => void;
};

export const DeployStep0 = ({ handleNextStep }: TDeployStep0) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: "63px",
        paddingTop: "201px",
      }}
    >
      <Typography
        fontFamily="Montserrat"
        textAlign={"center"}
        fontWeight="700"
        color="#7B8A93"
        fontSize={"20px"}
        sx={{
          paddingBottom: "14.06px",
        }}
      >
        Deploy your Tokenizer network here
      </Typography>
      <Typography
        fontFamily="Montserrat"
        textAlign={"center"}
        fontWeight="600"
        color="#7B8A93"
        fontSize={"14px"}
        sx={{
          paddingBottom: "31px",
        }}
      >
        Deployment wizard
      </Typography>
      <Box
        sx={{
          alignSelf: "center",
          ":hover": {
            cursor: "pointer",
          },
        }}
        onClick={() => {
          handleNextStep();
        }}
      >
        <Add />
      </Box>
      <Box
        sx={{
          paddingTop: "70.36px",
          opacity: "0.1",
          paddingLeft: "60px",
        }}
      >
        <img src={deployWizard} alt="" />
      </Box>
    </Box>
  );
};
