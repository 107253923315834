import React, { FC } from 'react';

import Skeleton from '@mui/material/Skeleton';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import { fonts, colors } from '../../../style/themes';

interface IMonitoring {
  cpuValue: string;
  memoryValue: string
  diskValue: string
};
export interface IData2Props {
  name: string;
  monitorinValue: IMonitoring;
  loading: boolean;
}

export const MonitoringCard: FC<IData2Props> = ({ name, monitorinValue, loading }) => {
  if (loading) {
    return (
      <Paper
        sx={{
          boxSizing: 'border-box',
          padding: '14px 25px',
          boxShadow: 'none',
          background: '#F1F3F4',
          border: '1px solid #CBD5E1',
          borderRadius: '30px',
          maxWidth: '212px',
          height: '116.47px'
        }}
      >
        <Typography
          sx={{
            fontSize: fonts.size[300],
            fontFamily: fonts.family.secondary,
            fontWeight: fonts.weight.secondary,
            color: colors.secondary[500],
            lineHeight: '20px',
            textAlign: 'left',
          }}
        >
          {name}
        </Typography>
        <Typography
          sx={{
            textAlign: 'right',
            fontSize: fonts.size[1000],
            fontFamily: fonts.family.secondary,
            fontWeight: fonts.weight[600],
            color: colors.primary[800],
            lineHeight: '70px'
          }}
        >
          {name === 'ALERTS' &&
            <>
              {/* {monitorinValue.cpuValue || ''} */}
            -
            </>
          }
          {name === 'CPU (%)' &&
            <>
              {monitorinValue.cpuValue || '-'}
            </>
          }
          {name === 'MEMORY (%)' &&
            <>
              {monitorinValue.memoryValue || '-'}
            </>
          }
          {name === 'DISK (%)' &&
            <>
              {monitorinValue.diskValue || '-'}
            </>
          }
        </Typography>
      </Paper>
    );
  } else {
    return (
      <Skeleton
        variant="rectangular"
        sx={{
          width: '100%',
          height: '116.47px',
          boxSizing: 'border-box',
          border: '1px solid #CBD5E1',
          borderRadius: '30px',
        }} />
    )
  }
};
