export const CloseModal: React.FC = () => (
  <svg
    width="15"
    height="17"
    viewBox="0 0 15 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.58728 8.50014L14.7947 2.28264C15.0491 1.98507 15.032 1.54149 14.7555 1.26447C14.4789 0.98744 14.036 0.970306 13.7389 1.22514L7.5315 7.44264L1.32406 1.21764C1.03044 0.923547 0.554396 0.923547 0.260781 1.21764C-0.032835 1.51173 -0.032835 1.98855 0.260781 2.28264L6.47571 8.50014L0.260781 14.7176C0.0470949 14.9009 -0.0459835 15.1887 0.0198137 15.4627C0.0856109 15.7367 0.299179 15.9506 0.572711 16.0165C0.846243 16.0824 1.13357 15.9892 1.31657 15.7751L7.5315 9.55764L13.7389 15.7751C14.036 16.03 14.4789 16.0128 14.7555 15.7358C15.032 15.4588 15.0491 15.0152 14.7947 14.7176L8.58728 8.50014Z"
      fill="#CBD5E1"
    />
  </svg>
);
