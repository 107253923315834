import styled from "styled-components";

export const StyledPaymentMethod = styled.div`
  padding-bottom: 18%;
  padding-left: 3%;

  .Container {
    margin-top: 120px;
  }

  .paymentmethod-header {
    padding: 0;
    font-size: 24px;
    line-height: 29px;
    }

  .footer {
    display: flex;
    width: 480px;
    align-itens: center;
    text-align: center;
  }

  .previous-step {
    width: 118px;
  }

  .next-step {
    margin-left: 150px;
    width: 206px;
  }

  .next-step-button {
    font-size: 12px;
    background-color: #7B8A93;
    border: 0px;
    cursor: not-allowed;
  }

  .next-step-button-selected {
    font-size: 12px;

  }

  .button-container-paymentmethod {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-left: 5%;
    margin-right: 20%;
    }

    .previous-button {
        width: 90%;
    }
    .previous-step-button{
        display: flex;
        background-color: transparent;
        color: white;
        border: 0px;
    }
    
    .button-container-paymentmethod .previous-button {
        color: #D8D8D8;
        background-color: #3E474D;
        border-style: hidden;
    }

    .paymentmethod-toggle-container {
        display: flex;
        margin-top: 12%;
    }

    .paymentmethod-card {
        height: 87px;
        width: 115px;
        margin-right: 87px;
        align-content: center;
    }

    .paymentmethod-divisor {
        height: 100px;
        border-right: 1px solid #F5F5F5;
        align-content: center;
    }
    
    

    .content {
        margin-top: 70px;
        height: 292px;
        width: 390px;
    }

    a{
        display: block;
        font-size: 16px;
        height:20px;
        color: white
    }

    .paymentmethod-contract {
        height: 87px;
        width: 115px;
    }

    img {
        width: 100%;
        margin: 0;
    }

    .horizontal-div {
        display: flex;
        display: 1;
        height: 100px;
        width: 420px;
        margin-top:37px;
    }

    .payment-card {
        width: 220px;
        text-align: center;
    }

    .payment-button {
        background-color: transparent;
        height: 120px;
        border: 0px;
    }

    .payment-button:disabled {
        height: 120px;
        border: 0px;
        opacity: 0.5;
        cursor: not-allowed;
    }

    .selected-container{
        display: flex;
        p {
            padding-top: 12px;
            margin-top: -12px;
            background: rgba(46, 87, 163, 0.95);
            width: 225px;
            height: 65px;
            font-family: Montserrat;
            font-size: 12px;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0px;
            text-align: center;
            border-radius: 21.6854px;
            margin-left: 10px;
        }
    }

    .payment-contract {
        margin-left:86px;
        width: 115px;
        text-align: center;
        background-color: transparent;
        height: 120px;
        border: 0px;
        
        button {
            cursor: pointer;
            background-color: transparent;
            height: 120px;
            border: 0px;
        }
    }

    .payment-contract-selected {
        margin-left:86px;
        width: 125px;
        text-align: center;
        background-color: transparent;
        height: 130px;
        border: 1px solid #1781E2;
        button {
            cursor: pointer;
            background-color: transparent;
            height: 120px;
            border: 0px;
            margin: 0px;
        }

    }


    .create-account-button {
        width: 70%;
        height: 90%;
    }

    .paymentmethod-name-container {
        display: flex;
        margin-left: 18%;
        margin-bottom: 10%;
        font-family: 'Montserrat', sans-serif;
        font-weight: 300;
    }

    .paymentmethod-contract-name {
        margin-left : 29%;
`;
