import * as React from "react";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Check from "@mui/icons-material/Check";
import SettingsIcon from "@mui/icons-material/Settings";
import StepConnector from "@mui/material/StepConnector";
import { StepIconProps } from "@mui/material/StepIcon";
import { StepContent, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";

const steps = [
  {
    label: "Setting up enviroment",
    description: "Configure foundational settings in the cloud provider.",
  },
  {
    label: "Installing prerequisites",
    description: "Install essential components for seamless operation.",
  },
  {
    label: "Bootstrapping",
    description: "Initialize core system components and deploy configurations.",
  },
  {
    label: "Making sure all is running smooth",
    description:
      "Conclude with thorough testing and monitoring for optimal performance.",
  },
];

const useStyles = makeStyles({
  root: {
    marginLeft: "20px",
  },
  active: {
    marginLeft: "20px",
  },
});

function CustomStepConnector() {
  const classes = useStyles();

  return (
    <StepConnector classes={{ root: classes.root, active: classes.active }} />
  );
}

export default function CustomizedSteppers({
  error,
  errorTicket,
  errorIds,
  percent,
}: any) {
  const ColorlibStepIconRoot = styled("div")<{
    ownerState: { completed?: boolean; active?: boolean; failed?: boolean };
    error: boolean;
    errorIds: boolean;
  }>(({ theme, ownerState, error, errorIds }) => ({
    backgroundColor: "#FFFFFF",
    border: "1px solid #8C8C8C",
    zIndex: 1,
    color: "#fff",
    width: 40,
    height: 40,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    ...(ownerState.active && {
      backgroundColor: "#FFFFFF",
      border: "1px solid #FC9E19",
      animation: "spin 1s linear infinite",
      icon: {
        color: "#FC9E19",
      },
    }),
    ...(ownerState.completed && {
      backgroundColor: "#FFFFFF",
      boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
      border: "1px solid #1890FF",
      icon: {
        color: "#1890FF",
      },
    }),
    ...(error && {
      backgroundColor: "#FFFFFF",
      border: "1px solid #EB2323",
      animation: "none",
      icon: {
        color: "#EB2323",
      },
    }),
    ...(errorIds && {
      backgroundColor: "#FFFFFF",
      border: "1px solid #EB2323",
      animation: "none",
      icon: {
        color: "#EB2323",
      },
    }),
    "@keyframes spin": {
      from: {
        transform: "rotate(0deg)",
      },
      to: {
        transform: "rotate(360deg)",
      },
    },
  }));

  function ColorlibStepIcon(props: StepIconProps) {
    const { active, completed, className } = props;
    const icons: { [index: string]: React.ReactElement } = {
      1: (
        <SettingsIcon
          style={{
            color:
              error && percent == 25 ? "red" : active ? "#FFA500" : "#8C8C8C", // change color to orange when active
          }}
        />
      ),
      2: (
        <SettingsIcon
          style={{
            color:
              error && percent == 50 ? "red" : active ? "#FFA500" : "#8C8C8C", // change color to orange when active
          }}
        />
      ),
      3: (
        <SettingsIcon
          style={{
            color:
              error && percent == 75 ? "red" : active ? "#FFA500" : "#8C8C8C", // change color to orange when active
          }}
        />
      ),
      4: (
        <SettingsIcon
          style={{
            color:
              error && percent == 100 ? "red" : active ? "#FFA500" : "#8C8C8C", // change color to orange when active
          }}
        />
      ),
    };

    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
        error={error}
        errorIds={errorIds}
      >
        {completed ? (
          <Check style={{ color: "#1890FF" }} />
        ) : (
          icons[String(props.icon)]
        )}
      </ColorlibStepIconRoot>
    );
  }

  return (
    <Stack sx={{ width: "100%" }} spacing={4}>
      <Stepper
        activeStep={Math.floor(percent / 25)}
        orientation="vertical"
        connector={<CustomStepConnector />}
      >
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel StepIconComponent={ColorlibStepIcon} error={error}>
              {step.label}
            </StepLabel>
            <StepContent sx={{ marginLeft: "20px" }}>
              <Typography
                color={error === true ? "#EB2323" : "#B7B7B7"}
                sx={{ marginLeft: "10px" }}
              >
                {error === false
                  ? step.description
                  : errorTicket
                  ? `An error occurred, contact support and provide this ID: ${errorTicket}`
                  : "An error occurred, contact support"}
                {errorIds === true && ""}
              </Typography>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
}
