import { Box, Card } from "@mui/material";
import { css, keyframes } from "@emotion/react";
import { WizardContext } from "../../context/wizardContext";
import { useContext } from "react";
import logoM from "../../assets/icons/logoM.gif";

interface LoadProps {
  children: React.ReactNode;
  // Outras propriedades podem ser adicionadas aqui, se necessário
}

export const LoadDeploy = ({ children }: LoadProps) => {
  //@ts-ignore
  const { deployData, setDeployData } = useContext(WizardContext);
  const pulse = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
`;
  return (
    <Box sx={{position: 'relative', height: '100%', width: '100%' }}>
    <Card
      sx={{
        width: '100%',
        height: '100%',
        background: 'rgb(251, 251, 251)',
        opacity: '.1',
        position: 'absolute',
        zIndex: 9,
      }}
    >
      {children}
    </Card>
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 10, height: '100%' }}>
      <img src={logoM} style={{ height: '300px', width: '300px' }} />
    </Box>
  </Box>
  
  );
};
