import styled from "styled-components";

export const StyledEnterpriseWallet = styled.div`
  padding-bottom: 18%;
  padding-left: 3%;

  .container-owner-wallet{
    background-color: #1781E2;
    height: 880px;
    width: 100%;
    padding: 52px 35px 45px 40px;
  }

  .header-title{
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 0px;
    text-align: left;
    padding-top: 0px;
  }
  
  .header-subtitle{
    margin-top: 45px;
    width: 550px;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 400;
    line-height: 33px;
    letter-spacing: 0px;
    text-align: left;
  }

  .wallet-info{
    display: flex;
    margin-top: 50px;
    height: 80px;
  }

  .wallet-info-icon{
    width: 100px;
    height: 80px;
    img {
        padding: 0px;
        margin: 0px;
        width: 77px;
        height: 77px;
    }
  }

  .wallet-more-info-name{
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0px;
    text-align: left;
    
  }

  .wallet-more-info-address{
    display: block;
    width: 550px;
    font-size: 17.5px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0px;
    text-align: left;
  }

  .wallet-address-button{
    display: flex;
  }

  .wallet-address-copy-button{
    background-color: transparent;
    border: 0px;
    width: 25px;
    height: 25px;
    border-radius: 0px;
    margin:0px;
    padding:0px;
    cursor: pointer;

    img{
        margin:0px;
        padding:0px;
        scale: 0.5;
    }
  }

  .mnemonic-info{
      width: 548px;
      height: 318px;
      background-color: #fff;
      border-radius: 20px;
      justify-self: center;
      margin: auto;
      margin-top: 34px;
  }

  .mnemonic-title{
    height: 61px;
    background-color: #636C7A;
    border-radius: 16px 16px 0 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 61px;
    text-align: center;
}

.mnemonic-words{
    width: 420px;
    height: 158px;
    // border: 1px solid black;
    margin: auto;
    margin-top: 21px;
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;

}

li {
    float: left;
    width: 25%;
    height: 60px;
}

li a {
    display: block;
    color: #1C1D1F;
    text-align: center;
    padding: 16px;
    text-decoration: none;
    // font-family: Sen;
    font-size: 18px;
    font-weight: 600;
    // line-height: 20px;
    letter-spacing: 0px;
    text-align: center;
    padding: 0px;
    margin: 0px;
}

.mnemonic-copy-button{
    display: block;
    width: 250px;
    height: 40px;
    margin: auto;
    margin-top: 25px;
    margin-left: ;
    border: 1px solid black;
    border-radius: 16px;
    // font-family: Sen;
    font-size: 20px;
    font-weight: 400;
    line-height: 0px;
    letter-spacing: 0px;
    text-align: center;
    color: black;
    cursor: pointer;
    text-transform: none;

}

.warning-info{
    display:flex;
    width: 548px;
    height: 50px;
    justify-self: center;
    margin: auto;
    margin-top: 34px;
}

.warning-icon{
    width: 50px;
    margin-left: 70px;
    img{
        margin:0px;
        padding:0px;
    }
}

.warning-text{
    width: 397px;
    margin-left: 15px;
    line-height: 50px;
    // font-family: Sen;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0px;
    text-align: left;
}

.footer{
    display: flex;
    margin-top: 20px;
    height: 45px;
    // border: 1px solid black;
    justify-content: space-between;
}

.footer-checkbox-mnemonic{
    width: 345px;
    // border: 1px solid black;
    font-size: 14px;
    font-weight: 700;
}

.checkbox-input {
    font-size: 14px;
    height: 25px;
    width: 25px;
}
input[type=checkbox] {
    margin-right: 16px;
    height: 24px;
    width: 24px;
    margin-top: 6px;
}
input[type=checkbox]:checked {
    color: red;
    label{
        color: red;
    }
}

input[type="checkbox"]:checked {
    accent-color: green;
}

input[type="checkbox"]:checked:hover {
    accent-color: green;
}

.footer-next-step{
    width: 281px;
    // border: 1px solid black;
}

.footer-next-step-button {
    background-color: #fff;
    width: 100%;
    margin: 0px;
    padding: 0px;
    color: #48535C;
    border: 0px;
    cursor: pointer;
    text-transform: capitalize;
    position: relative;
}

.footer-next-step-button.loading {
    pointer-events: none;
}

.footer-next-step-button.loading::after {
    content: '';
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
    border: 2px solid #48535C;
    border-radius: 50%;
    border-top-color: transparent;
    animation: spin 0.6s linear infinite;
}

@keyframes spin {
    from {
        transform: translateY(-50%) rotate(0deg);
    }
    to {
        transform: translateY(-50%) rotate(360deg);
    }
}


.footer-next-step-button-disabled{
    background-color: #7B8A93;
    width: 100%;
    margin:0px;
    padding:0px;
    color: #fff;
    border: 0px;
    cursor: block;
    text-transform: capitalize;
    cursor: not-allowed;
    pointer-events: all !important;
}




`;
