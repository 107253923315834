import { Box } from "@mui/material";
import { useState, useEffect, useContext } from "react";
import { WizardContext } from "../../context/wizardContext";
import { LicenseItem } from "../LicenseItem/LicenseItem";
import { LicenseService } from "../../services/license.service";
import { keycloak } from "../../utils/Keycloak";
import { removeLocalStorage } from "../../generalLocalStorage";

interface LicensesListProps {
  tokenUser: string;
}

const licenseService = new LicenseService();

export const LicensesList = ({ tokenUser }: LicensesListProps) => {
  // @ts-ignore
  const { deployData, setDeployData } = useContext(WizardContext);

  const [licenses, setLicenses] = useState<any>([]);

  const [selected, setSelected] = useState<string | null>(null);
  const enterpriseId = deployData.enterpriseId;

  useEffect(() => {
    const fetchLicenses = async () => {
      try {
        if (!tokenUser) return;
        const resLicenses = await licenseService.getEnterpriseLicense(
          enterpriseId,
          tokenUser
        );
        if (resLicenses.status === 401){
          const logoutUser = async () => {
            removeLocalStorage();
            await keycloak.logout();
          };
          logoutUser();
        }

        setLicenses(resLicenses.data);
      } catch (error) {
        console.log(error)
      }
    };

    fetchLicenses();
    setDeployData({ ...deployData, buttonColor: "#7b8a93" });
  }, []);

  const handleSelect = (id: string) => {
    setSelected(id);
    setDeployData({ ...deployData, buttonColor: "#1781E2", licenseId: id });
  };

  return (
    <Box sx={{ paddingTop: "30px" }}>
      {licenses.map((license: any) => (
        <LicenseItem
          id={license.id}
          key={license.id}
          name={license.name}
          expires={license.expires}
          createdAt={license.createdAt}
          period={license.period}
          selected={license.id === selected}
          handleSelect={() => handleSelect(license.id)}
        />
      ))}
    </Box>
  );
};
