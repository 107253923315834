import Api from "./api/axios-config";

export class LicenseService {
  async getOrganizationLicense(tokenUser: string, idOrg: string) {
    return Api(tokenUser).get(`/organization/${idOrg}/licenses`);
  }

  async getEnterpriseLicense(idEnterprise: string, tokenValidate: string) {
    return Api(tokenValidate).get(`/enterprise/${idEnterprise}/licenses`);
  }
}
