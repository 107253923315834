import { Box, OutlinedInput, InputAdornment } from "@mui/material";
import React from "react";
import AddIcon from "@mui/icons-material/Add";
import { OrganizationList } from "../../OrganizationList/organization-list";
import { FilterRange, FindFilter } from "~/assets";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

export type TOrgsList = {
  subId: any;
  open: boolean;
  setFilterOrgs: any;
  setOpen: (value: boolean) => void;
  userName: string;
  loading: boolean;
  // implement inter
  orgList: [
    {
      id: string;
      name: string;
    }
  ];
};

function OrgsList({
  subId,
  setOpen,
  orgList,
  setFilterOrgs,
  userName,
  loading,
}: TOrgsList) {
  const filterOrganization = (event: any) => {
    setFilterOrgs(event.target.value);
  };
  return (
    <div className="small-container">
      <h2>{`${
        userName?.charAt(0).toUpperCase() + userName?.slice(1)
      }'s Network`}</h2>
      <p className="org-counter">
        <b>{orgList.length}</b> ORGANIZATIONS
      </p>
      <OutlinedInput
        placeholder="Input title"
        type="string"
        onChange={(event) => filterOrganization(event)}
        startAdornment={
          <InputAdornment position="start">
            <FilterRange />
          </InputAdornment>
        }
        endAdornment={
          <InputAdornment position="end">
            <FindFilter />
          </InputAdornment>
        }
        sx={{
          borderRadius: "30px",
          height: "37px",
          maxWidth: "327px",
          display: "flex",
          backgroundColor: "#DEE1E6",
          margin: "0px auto",
        }}
      />
      <div className="add-item-container">
        <Box
          sx={{
            cursor: "pointer",
            border: "1.5px solid #7B8A93",
            width: "51px",
            height: "51px",
            borderRadius: "50%",
            backgroundColor: "#fafafa",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={() => {
            setOpen(true);
          }}
        >
          <AddIcon color="disabled" />
        </Box>
      </div>
      {orgList.length > 4 && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            position: "absolute",
            right: "35px",
            marginTop: "25px",
          }}
        >
          <KeyboardArrowUpIcon />
        </Box>
      )}

      <OrganizationList orgList={orgList} loading={loading} />

      {orgList.length > 4 && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            position: "absolute",
            right: "35px",
            marginTop: "10px",
          }}
        >
          <KeyboardArrowDownIcon />
        </Box>
      )}
    </div>
  );
}

export default OrgsList;
