import LinearProgress from "@material-ui/core/LinearProgress";
import { Box, Skeleton, Typography } from "@mui/material";
import { Resource } from "~/assets";
export type TNodes = {
  sizeResource: number;
  totalUphosts: number;
  totalHosts: number;
};

function Nodes({ sizeResource, totalUphosts, totalHosts }: TNodes) {
  return (
    <div className="info-card first-row-card">
      <div className="members-heading">
        <h3 className="home-active-licenses">RESOURCES</h3>
        <div className="node-icon-image">
          <Resource />
        </div>
      </div>
      <h4 className="home-licenses-counter">
        {sizeResource ? (
          sizeResource
        ) : (
          <Skeleton
            variant="rectangular"
            sx={{
              width: "100px",
              height: "10px",
              boxSizing: "border-box",
              borderRadius: "3px",
            }}
          />
        )}
      </h4>

      <div className="linear-progress-bar">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 9,
          }}
        >
          <Typography
            fontFamily="Montserrat"
            fontSize={11.5}
            fontWeight="500"
            color="#48535C"
            marginLeft={"6px"}
          >
            0%
          </Typography>
          <Typography
            fontFamily="Montserrat"
            fontSize="9.17px"
            fontWeight="500"
            color="#7B8A93"
          >
            Online
          </Typography>
          <Typography
            fontFamily="Montserrat"
            fontSize={11.5}
            fontWeight="500"
            color="#48535C"
            marginRight={"6px"}
          >
            100%
          </Typography>
        </div>
        <Box sx={{ width: "100%", mr: 1 }}>
          <LinearProgress
            variant="determinate"
            value={totalHosts ? (totalUphosts / totalHosts) * 100 : 0}
          />
        </Box>
      </div>
    </div>
  );
}

export default Nodes;
