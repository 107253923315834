import { Box, Skeleton } from "@mui/material";
import { Member } from "~/assets";

export type TMembersHome = {
  totalNumbers: number;
};

function MembersHome({ totalNumbers }: TMembersHome) {
  return (
    <div className="info-card first-row-card margin-card">
      <div className="members-heading">
        <h3 className="home-active-licenses">MEMBERS</h3>
        <div className="person-icon-image">
          <Member />
        </div>
      </div>
      <h4 className="home-licenses-counter">
        {totalNumbers ? (
          totalNumbers
        ) : (
          <Skeleton
            variant="rectangular"
            sx={{
              width: "100px",
              height: "10px",
              boxSizing: "border-box",
              borderRadius: "3px",
            }}
          />
        )}
      </h4>
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
        }}
      >
        <div className="member-info-home">
          <p className="counter-active-home">{totalNumbers}</p>
          <p className="active-text-home">Active Since last 7 days</p>
        </div>
      </Box>
    </div>
  );
}

export default MembersHome;
