import React, { FC } from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartData
} from 'chart.js';

interface BarChartProps {
  data: number[];
  labels: string[];
  selectMonth?: (month: string) => void;
}

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

const BarChart: FC<BarChartProps> = ({ data, labels, selectMonth }) => {
  const chartData: ChartData<"bar", (number | [number, number] | null)[], unknown> = {
    labels,
    datasets: [
      {
        label: 'Bar Chart',
        data,
        backgroundColor: '#0096FF',
      },
    ],
  };
  const getMonth = (index: string): string => {
    return +index < 10 ? `0${index + 1}` : (+index + 1).toString()
  }
  const handleBarClick = (event: MouseEvent, elements: any) => {
    if (elements && elements.length > 0) {
      const clickedElementIndex = elements[0].index;
      if (selectMonth) { selectMonth(getMonth(clickedElementIndex)) }
    }
  };

  const options: any = {
    responsive: true,
    maintainAspectRatio: false,
    onClick: handleBarClick,

    plugins: {
      title: {
        display: false,
        text: 'Chart.js Bar Chart',
      },
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          color: '#F1F3F4'
        },
        border: {
          width: 2
        },
      },
      y: {
        maxheight: 200,
        beginAtZero: true,
        precision: 0,
        ticks: {
          display: false,
        },
        border: {
          display: false,
          // color: 'red'
        },
        grid: {
          color: '#F1F3F4'
        },
      },
    },
  };

  return <Bar data={chartData} options={options} />;
};

export default BarChart;
