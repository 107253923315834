import { Box, Card } from "@mui/material";
import { DeployBaasConfirmation } from "./DeployBaasConfirmation/DeployBaasConfirmation";
import { DeployBaasNameDesc } from "../../components/Platform/DeployBaas/DeployBaasWizard/WizardBaasSteps/DeployBaasNameDesc";
import { DeployBaasStack } from "../../components/Platform/DeployBaas/DeployBaasWizard/WizardBaasSteps/DeployBaasStack";
import { DeployBaasInitial } from "../../components/Platform/DeployBaas/DeployBaasWizard/WizardBaasSteps/DeployBaasInitial";
import { DeployBaasMode } from "../../components/Platform/DeployBaas/DeployBaasWizard/WizardBaasSteps/DeployBaasMode";
import { DeployBaasVMSetup } from "../../components/Platform/DeployBaas/DeployBaasWizard/WizardBaasSteps/DeployBaasVMSetup";
import { DeployBaasProcess } from "./DeployBaasProcess/DeployBaasProcess";
import { useContext } from "react";
import { WizardContext } from "../../context/wizardContext";
import { DeployBaasVMCordaSetup } from "../../components/Platform/DeployBaas/DeployBaasWizard/WizardBaasSteps/DeployBaasVMCordaSetup";
import { LoadDeploy } from "../../components/loadDeploy";

export type DeployStepProps = {
  step: number;
  handleNextStep: () => void;
  setSelectedOrg: (org: string) => void;
  setNetworkName: (name: string) => void;
  selectedOrg: string;
  networkName: string;
  tokenUser: string;
};

export const DeployBaasSteps = ({
  step,
  handleNextStep,
  setSelectedOrg,
  setNetworkName,
  selectedOrg,
  networkName,
  tokenUser,
}: DeployStepProps) => {
  // @ts-ignore
  const { deployData, setDeployData } = useContext(WizardContext);
  let stepComponent;
  let cardHeight = "600px";
  switch (step) {
    case 0:
      stepComponent = <DeployBaasInitial handleNextStep={handleNextStep} />;
      cardHeight = "761px";
      break;
    case 1:
      stepComponent = <DeployBaasMode />;
      break;
    case 2:
      stepComponent = stepComponent = (
        <DeployBaasNameDesc
          setSelectedOrg={setSelectedOrg}
          setNetworkName={setNetworkName}
          selectedOrg={selectedOrg}
          networkName={networkName}
          tokenUser={tokenUser}
        />
      );
      break;
    case 3:
      stepComponent = <DeployBaasStack />;
      break;
    case 4:
      if (deployData.protocolType === "R3 Corda") {
        stepComponent = <DeployBaasVMCordaSetup />;
      } else {
        stepComponent = <DeployBaasVMSetup tokenUser={tokenUser} />;
      }
      break;
    case 5:
      stepComponent = <DeployBaasConfirmation />;
      break;
    case 6:
      stepComponent = <DeployBaasProcess tokenUser={tokenUser} />;
      break;
  }

  return (
    <Card
      sx={{
        position: "relative",
        backgroundColor: "#FFFFFF",
        boxShadow: "none",
        borderRadius: "20px",
        height: "100%",
        paddingLeft: "50px",
        paddingRight: "43px",
        paddingTop: "20px",
        "@media (max-width: 1500px)": {
          paddingLeft: "25px",
          paddingRight: "23px",
        },
      }}
    >
      {deployData.loadVmCheck ? (
        <LoadDeploy>{stepComponent}</LoadDeploy>
      ) : (
        <>{stepComponent}</>
      )}
    </Card>
  );
};
