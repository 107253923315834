import React, { FC, useContext } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { fonts, colors } from '../../../style/themes';
import { TextTooltip } from '../../textTootiop';
import { WizardContext } from "../../../context/wizardContext";
import { CircularProgress, SvgIcon } from '@mui/material';


const SECONDS_IN_A_DAY = 86400;
interface IProps {
  dataKey: string;
  uptimeData: any;
  currentUpTime: number;
  data: any;
  selectedSubitem: {
    dataKey: string;
    index: number;
  } | null;
  handleSelectSubitem: (dataKey: string, index: number, idVm: string) => void;
}

const Divider = () => {
  return (
    <svg width="3" height="17" viewBox="0 0 3 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.5">
        <path d="M1.20384 0.650391C1.04575 0.650391 0.8892 0.683898 0.74314 0.749001C0.597079 0.814104 0.464366 0.909526 0.352576 1.02982C0.240787 1.15011 0.15211 1.29292 0.0916097 1.4501C0.0311093 1.60727 -2.9848e-05 1.77572 -2.98549e-05 1.94585C-2.98619e-05 2.11597 0.0311093 2.28442 0.0916096 2.44159C0.15211 2.59877 0.240786 2.74158 0.352576 2.86187C0.464366 2.98216 0.597079 3.07759 0.74314 3.14269C0.8892 3.20779 1.04575 3.2413 1.20384 3.2413C1.36194 3.2413 1.51848 3.20779 1.66454 3.14269C1.8106 3.07759 1.94332 2.98216 2.05511 2.86187C2.1669 2.74158 2.25557 2.59877 2.31608 2.44159C2.37658 2.28442 2.40771 2.11597 2.40771 1.94585C2.40771 1.77572 2.37658 1.60727 2.31608 1.4501C2.25558 1.29292 2.1669 1.15011 2.05511 1.02982C1.94332 0.909526 1.8106 0.814104 1.66454 0.749001C1.51848 0.683898 1.36194 0.650391 1.20384 0.650391ZM1.20384 7.12766C0.884555 7.12766 0.578346 7.26415 0.352576 7.50709C0.126806 7.75004 -3.01041e-05 8.07954 -3.01181e-05 8.42312C-3.0132e-05 8.76669 0.126806 9.0962 0.352576 9.33914C0.578346 9.58209 0.884555 9.71857 1.20384 9.71857C1.52313 9.71857 1.82934 9.58209 2.05511 9.33914C2.28088 9.0962 2.40771 8.7667 2.40771 8.42312C2.40771 8.07954 2.28088 7.75004 2.05511 7.50709C1.82934 7.26415 1.52313 7.12766 1.20384 7.12766ZM1.20384 13.6049C0.884555 13.6049 0.578346 13.7414 0.352576 13.9844C0.126806 14.2273 -3.03672e-05 14.5568 -3.03812e-05 14.9004C-3.03951e-05 15.244 0.126806 15.5735 0.352576 15.8164C0.578346 16.0594 0.884555 16.1958 1.20384 16.1958C1.52313 16.1958 1.82934 16.0594 2.05511 15.8164C2.28088 15.5735 2.40771 15.244 2.40771 14.9004C2.40771 14.5568 2.28088 14.2273 2.05511 13.9844C1.82934 13.7414 1.52313 13.6049 1.20384 13.6049Z" fill="#48535C" fillOpacity="0.5" />
      </g>
    </svg>
  )
}

export const SubResourceDeployment: FC<IProps> = (props) => {
  // @ts-ignore
  const { alertResource, loadingVm } = useContext(WizardContext)

  const { dataKey, data, uptimeData, selectedSubitem, handleSelectSubitem, currentUpTime } = props;

  const getVMUptime = (id: string) => {
    if (uptimeData?.uptimes?.length > 0){
      const itemB = uptimeData?.uptimes?.find((item: { id: string; }) => item.id === id);

      if(itemB){
        return itemB.uptime
      }
    }
    return 0
  };

  const getTotalAlert = () => {
    return alertResource.reduce(
      (accumulator: any, currentValue: any) => accumulator + currentValue.alertAmout,
      0
    );
  }

  return (
    <>
      {
        data.vms.map((item: any, index: number) => {
          return (
            <Box
              key={`${dataKey}_${index}`}
              onClick={() => handleSelectSubitem(dataKey, index, item.id)}
              sx={{
                display: 'flex',
                justifyContent: "start",
                alignItems: "center",
                flexWrap: 'nowrap',
                marginBottom: '12px',
                padding: '0 12px',
                backgroundColor: '#FAFAFA',
                border: (dataKey === selectedSubitem?.dataKey) && (index === selectedSubitem?.index)
                  ? '1.14608px solid #1781E2'
                  : '1.14608px solid #FAFAFA',
                borderRadius: '11.4608px',
                height: '38px',
                width: '100%'
              }}
            >
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.56089 0C3.83329 0 0 4.01634 0 8.9697C0 13.9231 3.83329 17.9394 8.56089 17.9394C13.2885 17.9394 17.1218 13.9231 17.1218 8.9697C17.1218 4.01634 13.2885 0 8.56089 0ZM13.2178 11.7968L8.56089 14.6158L3.90399 11.7968V6.16266L6.006 4.88929L5.99835 4.88528H6.00217L8.56089 3.34361L11.1215 4.88528H11.1177L11.112 4.88929L13.2159 6.16266V11.7968H13.2178ZM4.66071 9.46423L6.33467 10.4633V12.3534L8.18252 13.4726V9.22597L4.66071 7.09567V9.46423ZM8.55706 6.42495L6.76463 5.34578L5.02379 6.39892L8.5628 8.54123L12.1018 6.40092L10.3552 5.34378L8.55706 6.42495ZM8.93925 13.4726L10.7986 12.3473V10.4633L12.4611 9.47224V7.09767L8.93925 9.22998V13.4726Z" fill="#1781E2" />
              </svg>
              <Typography
                sx={{
                  marginLeft: '19.86px',
                  flexBasis: '170px',
                  fontSize: fonts.size[100],
                  fontFamily: fonts.family.secondary,
                  fontWeight: fonts.weight[600],
                  color: colors.primary[800],
                  textAlign: 'left',
                }}
              >
                 {
                  <TextTooltip maxWidth="140px">
                    {item.name ? item.name : item.role}
                  </TextTooltip>
                }
              </Typography>
              <Divider />
              <Typography
                sx={{
                  paddingLeft: '16.59px',
                  flexBasis: '172px',
                  fontSize: fonts.size[80],
                  fontFamily: fonts.family.secondary,
                  fontWeight: fonts.weight[600],
                  color: colors.primary[800],
                  textAlign: 'left',
                }}
              >
                {
                  <TextTooltip maxWidth="140px">
                    {item.id}
                  </TextTooltip>
                }
              </Typography>
              <Divider />
              <Typography
                sx={{
                  paddingLeft: '16.59px',
                  flexBasis: '162px',
                  fontSize: fonts.size[80],
                  fontFamily: fonts.family.secondary,
                  fontWeight: fonts.weight[600],
                  color: colors.primary[800],
                  textAlign: 'left',
                }}
              >
                <TextTooltip maxWidth="140px">
                  {data.order.organization.name}
                </TextTooltip>
              </Typography>
              <Divider />
              <Typography
                sx={{
                  paddingLeft: '16.59px',
                  flexBasis: '192px',
                  fontSize: fonts.size[80],
                  fontFamily: fonts.family.secondary,
                  fontWeight: fonts.weight[600],
                  color: colors.primary[800],
                  textAlign: 'left',
                }}
              >
                {item.externalIP}
              </Typography>
              <Divider />
              <Box sx={{ paddingX: '18.59px' }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '62px',
                    height: '25px',
                    borderRadius: '5px',
                    background: uptimeData ? getVMUptime(item.id) === 0 ? '#E14942' : '#6FF875' : '#FAFAFA',
                    fontSize: fonts.size[80],
                    fontFamily: fonts.family.secondary,
                    fontWeight: fonts.weight[600],
                    color: colors.secondary[600],
                    textAlign: 'left',
                  }}
                >
                  {uptimeData
                  ?
                  `${(getVMUptime(item.id)/ SECONDS_IN_A_DAY).toFixed(1)} days`
                  :
                  <SvgIcon viewBox="0 0 70 50">
                  <circle cx="25" cy="25" r="15" fill="none" strokeWidth="5" stroke="#ccc" />
                  <path
                    fill="none"
                    strokeWidth="5"
                    strokeLinecap="round"
                    stroke="#1781E2"
                    d="M25 5 A 20 20 0 0 1 45 25"
                  >
                    <animateTransform
                      attributeName="transform"
                      type="rotate"
                      from="0 25 25"
                      to="360 25 25"
                      dur="1s"
                      repeatCount="indefinite"
                    />
                  </path>
                </SvgIcon>
                  }
                    

                </Box>
              </Box>
              <Divider />
              {alertResource?.length < 0 ? (
                <Box sx={{ paddingX: '18.59px' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '62px',
                      height: '25px',
                      borderRadius: '5px',
                      background: alertResource?.length > 0 ? '#E14942' : '#6FF875',
                      fontSize: fonts.size[80],
                      fontFamily: fonts.family.secondary,
                      fontWeight: fonts.weight[600],
                      color: colors.primary[100],
                      textAlign: 'left',
                    }}
                  >
                    <>
                      {getTotalAlert()}
                    </>

                  </Box>
                </Box>
              ) : (
                <Box paddingLeft="23px">...</Box>
              )
              }
              <>
                {index === selectedSubitem?.index &&
                  <>
                    {loadingVm &&
                      <Box sx={{ display: 'flex', marginLeft: '90px' }}>
                        <CircularProgress size={20} />
                      </Box>
                    }
                  </>
                }
              </>
            </Box>

          )
        })
      }
    </>
  );
};
