import { Skeleton } from "@mui/material";

export const SkeletonMembers = ({}) => {
  return (
    <div className="member-container">
        <Skeleton
          variant="circular"
          sx={{
            width: "35px",
            height: "35px",
          }}
        />
      {/* </Stack> */}
      <div className="member-info">
        <div className="member-name">
          <p>
            <Skeleton
              variant="rectangular"
              sx={{
                width: "55%",
                height: "10px",
                boxSizing: "border-box",
                borderRadius: "3px",
              }}
            />
          </p>
        </div>
        <div className="member-title">
          <p>
            <Skeleton
              variant="rectangular"
              sx={{
                width: "55%",
                height: "10px",
                boxSizing: "border-box",
                borderRadius: "3px",
              }}
            />
          </p>
        </div>
      </div>
    </div>
  );
};
