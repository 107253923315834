export const Add: React.FC = () => (
  <svg
    width="86"
    height="83"
    viewBox="0 0 86 83"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M44.0923 31.5483V50.9795"
      stroke="#7B8A93"
      stroke-width="1.58287"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M33.8675 40.5615H53.7067"
      stroke="#7B8A93"
      stroke-width="1.58287"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M43.612 66.6384C58.0283 66.6384 69.7162 55.4198 69.7162 41.5824C69.7162 27.745 58.0283 16.5264 43.612 16.5264C29.1957 16.5264 17.5078 27.745 17.5078 41.5824C17.5078 55.4198 29.1957 66.6384 43.612 66.6384Z"
      stroke="#7B8A93"
      stroke-width="1.58287"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
