import { Box, Typography } from "@mui/material";
import { DeployStepHeading } from "../../../components/Platform/Deploy/DeployWizard/WizardSteps/DeployStepHeading";
import { ConfirmationBaasList } from "./ConfirmationBaasList/ConfirmationBaasList";
import { ConfirmationBaasStack } from "./ConfirmationBaasStack/ConfirmationBaasStack";

export const DeployBaasConfirmation = () => {
  return (
    <>
      <Box>
        <DeployStepHeading
          title="Confirmation"
          description="Please check your request, as deployment is the next step"
        />
        <Box>
          <ConfirmationBaasList />
        </Box>
        <Box sx={{ paddingTop: "10px" }}>
          <Typography fontFamily="Montserrat" fontWeight="600" fontSize="13px" lineHeight="35px">Stack</Typography>
          <ConfirmationBaasStack />
        </Box>
      </Box>
    </>
  );
};
