export const ClipBoard: React.FC = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.3032 7.61396L8.13101 13.7917C7.37487 14.5485 6.34933 14.9737 5.27999 14.9737C4.21065 14.9737 3.18511 14.5485 2.42898 13.7917C1.67284 13.0349 1.24805 12.0084 1.24805 10.9381C1.24805 9.8678 1.67284 8.84134 2.42898 8.08452L8.18474 2.32358C8.68883 1.81814 9.37287 1.53383 10.0864 1.5332C10.7999 1.53257 11.4845 1.81567 11.9894 2.32021C12.4944 2.82476 12.7785 3.50942 12.7791 4.22359C12.7797 4.93775 12.4969 5.62292 11.9928 6.12835L6.22361 11.8893C5.97157 12.1416 5.62972 12.2833 5.27327 12.2833C4.91683 12.2833 4.57498 12.1416 4.32294 11.8893C4.07089 11.637 3.92929 11.2949 3.92929 10.9381C3.92929 10.5813 4.07089 10.2392 4.32294 9.98691L10.025 4.28646"
      stroke="#7B8A93"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
