import Api from './api/axios-config'
import Login from './api/axios-config/login'

import type { GenericExtrinsic } from '@polkadot/types';

export type TMemberService = {
  signedTx?: GenericExtrinsic<any>;
  firstName?: string;
  lastName?: string;
  jobTitle?: string;
  phone?: string;
  email?: string;
  password?: string;
  permission?: string;
  enterprise?: string;
  organization?: string;
  organizationId?: string;
  wallet?: string;
};

const returnError = (errorInternal: any) => {
  console.log(errorInternal)
  if (errorInternal.response){
    const { status, error } = errorInternal.response.data;
    return { status: status, statusText: error, data: [] }
  }else{
    return { status: 500, statusText: errorInternal.code, data: [] }
  }
}
export class MemberService {
  async authUser(tokenId: string) {
    try {
      const resAuth = await Login(tokenId).get('/user/auth')
      return resAuth;
    } catch (error) {
      console.log(error)
      return { data: [], status: 401, error }
    }
  }

  async setMember(member: TMemberService, tokenId: string) {
    try {
      const respMember = await Api(tokenId).post(`/member`, member);
      return respMember;
    } catch (error) {
      return returnError(error);
    }
  };

  async inviteMember(member: any, tokenId: string) {
    try {
      const respMember = await Api(tokenId).post(`/member/invite`, member);
      return respMember;
    } catch (error) {
      return returnError(error);
    }
  };

  async updateMemberById(
    idMember: string,
    orgId: string,
    tokenId: string,
    member: TMemberService) {
    try {
      const respUpdate = await Api(tokenId).put(`/member/${idMember}/permission/organization/${orgId}`, member);
      return respUpdate;
    } catch (error: any) {
      return returnError(error)
    }
  };

  async getMemberOrganization(idOrg: string, tokenId: string) {
    try {
      const restMemOrg = await Api(tokenId).get(`/organization/${idOrg}/members`);
      return restMemOrg;
    } catch (error: any) {
      const { statusCode, message } = error.response.data;
      return { data: [], status: statusCode, statusText: message }
    }
  };

  async getMemberById(idMember: string, tokenId: string) {
    return Api(tokenId).get(`/user/${idMember}`);
  }

  async getMemberByIdNew(idMember: string, idOrg: string, tokenId: string) {
    return Api(tokenId).get(`/member/${idMember}/organization/${idOrg}`);
  }

  async deleteMemberById(idMember: string, idOrg: string, tokenId: string, signedTx: any) {
    try {
      const respDelete = await  Api(tokenId).delete(`/member/remove/organization`, {data: {memberId: idMember, orgId: idOrg, signedTx}});
      return respDelete;
    } catch (error: any) {
      const { statusCode, message } = error.response.data;
      return { status: statusCode, statusText: message }
    }
  };

  async getUserEnterprise(idUser: string, tokenId: string) {
    return Api(tokenId).get(`user/${idUser}/enterprises`);
  };

  // Esta rota não esta sendo utilizada, obtemos essa inf por meio da rota de auth
  async getMemberEnterprise(idEnt: string, tokenId: string) {
    try {
      const respMemberEnt = await Api(tokenId).get(`/enterprise/${idEnt}/members`);
      return respMemberEnt;
    } catch (error) {
      return { data: [], error: error }

    }
  }

  async getMemberByEmail(orgId: string, email: string, tokenId: string) {
    try {
      const respGetMemberByEmail = await Api(tokenId).get(`member/organization/${orgId}/email/${email}`); 
      return respGetMemberByEmail;
    } catch (error) {
      return returnError(error);
      
    }
  };

  async updateMemberExist(
    userId: string,
    organizationId: string,
    {signedTx, permission}: any,
    tokenId: string,
  ) {
    return Api(tokenId).put(`/member/${userId}/organizations/${organizationId}/add`, {
      signedTx, permission
    });
  };
};
