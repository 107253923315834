import React, { FC } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

// import "@fontsource/montserrat";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import CircularProgress, { CircularProgressProps, } from '@mui/material/CircularProgress';
import BarChart from '../../../components/barChart';

import { fonts, colors } from '../../../style/themes';
import { IDataYear } from '../../../type';

import circle from '../../../assets/imgs/circle.png';
import dayjs from 'dayjs';

interface IProps {
  filteredData: IDataYear | null;
  selectMonth: (month: string) => void;
  sizeMonth: number;
}

const CircularProgressWithLabel = (props: CircularProgressProps & { value: number }) => {
  return (
    <Box sx={{
      position: 'relative',
      display: 'inline-flex',
      alignItems: 'center',
      '& span.MuiCircularProgress-determinate': {
        color: '#5F6368',
        backgroundImage: `url(${circle})`,
      },
    }}>
      <CircularProgress
        variant="determinate"
        color='info'
        size={43}
        thickness={8}
        {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      />
    </Box>
  );
}

const Data1DayChart = (props: IProps) => {
  const navigate = useNavigate();
  const { filteredData, selectMonth, sizeMonth } = props;
  const { year, month } = useParams();
  const lastDayOfMonth = dayjs(`${year}-${month}`).daysInMonth()
  const MONTH = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']


  const chartMonthData = () => {
    if (filteredData && month) {
      const data: any = filteredData[month];

      let newLabel: any = {};

      Object.keys(data).forEach((chave) => {
        let novaChave = parseInt(chave) + 1;
        newLabel[novaChave] = data[chave];
      });


      const monthData: number[] = new Array(Object.keys(newLabel).length - 1);

      for (let key in newLabel) {
        if (typeof newLabel[key] !== 'boolean') {
          let index = +key
          monthData[index - 1] = newLabel[key] as number
        }
      }

      let label = Object.keys(monthData).map(i => (+i + 1).toString());
      return { monthData, label }
    }
    return { monthData: [], label: [] }
  }

  const getValueCircle = (): number => {
    if (year && month && filteredData) {
      const now = new Date()
      const currentDay = now.getDate()
      const currentMonth = now.getMonth() + 1
      const currentYear = now.getFullYear()
      if (+year === currentYear && +month === currentMonth) {
        let value = currentDay / Object.keys(filteredData[month]).length * 100
        return value
      } else if (+year < currentYear || (+year === currentYear && +month < currentMonth)) {
        return 100
      } else return 0
    }
    return 0
  }
  const ControlMonth: FC = () => {
    const disabledButton = (name: 'prev' | 'next'): boolean => {
      if (month) {
        if (name === 'next') {
          return +month === sizeMonth - 1
        } else {
          return +month === 1
        }
      }
      return true
    }
    const getMonth = (index: number): string => {
      return index < 10 ? `0${index}` : index.toString()
    }
    const nextMonth = () => {
      if (year && month) {
        const nextMonth = +month + 1
        selectMonth(getMonth(nextMonth))
      }
    }
    const prevMonth = () => {
      if (year && month) {
        const nextMonth = +month - 1
        selectMonth(getMonth(nextMonth))
      }
    }
    return (
      <Box>
        <IconButton
          onClick={prevMonth}
          disabled={disabledButton('prev')}
          size="small"
          aria-label="prev">
          <NavigateBeforeIcon />
        </IconButton>
        <IconButton
          onClick={nextMonth}
          disabled={disabledButton('next')}
          size="small"
          aria-label="next">
          <NavigateNextIcon />
        </IconButton>
      </Box>
    )
  }

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="start"
      >
        <Grid
          item
          lg={6}
          justifyContent="center"
          alignItems="start"
        >
          <Typography
            sx={{
              fontSize: fonts.size[300],
              fontFamily: fonts.family.secondary,
              fontWeight: fonts.weight.secondary,
              color: colors.secondary[500],
            }}
          >
            USAGE OVERVIEW
          </Typography>
          <Box sx={{ display: 'flex' }}>
            <Typography
              component="span"
              sx={{
                fontSize: fonts.size[500],
                fontFamily: fonts.family.secondary,
                fontWeight: fonts.weight.secondary,
                color: colors.primary[700],
              }}
            >
              {MONTH[month ? +month - 1 : 0].toUpperCase()}
            </Typography>
            <ControlMonth />
          </Box>
          <Typography
            onClick={() => navigate('/financial')}
            sx={{
              fontSize: fonts.size[80],
              fontFamily: fonts.family.secondary,
              fontWeight: fonts.weight.primary,
              color: colors.secondary[800],
              cursor: 'pointer'
            }}
          >BACK TO YEARS
          </Typography>
        </Grid>
        <Grid
          item
          lg={6}
          justifyContent="left"
          alignItems="start"
        >
          <Box sx={{ display: 'flex', justifyContent: 'end', gap: '25px' }}>
            <CircularProgressWithLabel value={getValueCircle()} />
            <Box sx={{ paddingTop: '5px' }}>
              <Typography
                sx={{
                  fontSize: fonts.size[300],
                  fontFamily: fonts.family.secondary,
                  fontWeight: fonts.weight[600],
                  color: colors.secondary[600],
                  textAlign: 'left',
                }}
              >
                {`01/${month}/${year}`}
              </Typography>
              <Typography
                sx={{
                  fontSize: fonts.size[80],
                  fontFamily: fonts.family.secondary,
                  fontWeight: fonts.weight.primary,
                  color: colors.secondary[600],
                  textAlign: 'left',
                }}
              >
                OPEN DATE
              </Typography>
            </Box>
            <Box sx={{ paddingTop: '5px' }}>
              <Typography
                sx={{
                  fontSize: fonts.size[300],
                  fontFamily: fonts.family.secondary,
                  fontWeight: fonts.weight[600],
                  color: colors.secondary[600],
                  textAlign: 'left',
                }}
              >
                {`${lastDayOfMonth}/${month}/${year}`}
              </Typography>
              <Typography
                sx={{
                  fontSize: fonts.size[80],
                  fontFamily: fonts.family.secondary,
                  fontWeight: fonts.weight.primary,
                  color: colors.secondary[600],
                  textAlign: 'left',
                }}
              >
                CLOSE DATE
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontSize: fonts.size[500],
                  fontFamily: fonts.family.secondary,
                  fontWeight: fonts.weight.secondary,
                  color: colors.secondary[700],
                  textAlign: 'left',
                }}
              >
                OPEN
              </Typography>
              <Typography
                sx={{
                  fontSize: fonts.size[80],
                  fontFamily: fonts.family.secondary,
                  fontWeight: fonts.weight[600],
                  color: colors.secondary[600],
                  textAlign: 'left',
                }}
              >
                STATUS
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box
        sx={{
          width: '100%',
          height: '85%',
          display: 'flex',
          alignItems: 'end',
        }}
      >
        <BarChart data={chartMonthData().monthData} labels={chartMonthData().label} />
      </Box>
    </>
  )
}

export default Data1DayChart