import Api from "./api/axios-config";

export class MonitoringService {
  async getResourceEnt(tokenId: string) {
    return Api(tokenId).get(`/monitoring`);
  }

  async getVmsUptimed(tokenId: string) {
    return Api(tokenId).get('/monitoring/uptime');
  }

  async getVmBYId(tokenId: string, entId: string) {
    return Api(tokenId).get(`/monitoring/vm/${entId}`);
  }

  async deleteResoure(tokenId: string, idResource: string) {
    return Api(tokenId).delete(`/resourceGroup/${idResource}`);
  }
}
