import { Box, Card, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

export type TCreateOrganization = {
  open: boolean;
  setOpen: (value: boolean) => void;
};

export const CreateOrganization = ({ open, setOpen }: TCreateOrganization) => {
  return (
    <div style={{ position: "relative" }}>
      <Card
        sx={{
          boxShadow: "none",
        }}
      >
        <Box
          sx={{
            width: "431px",
            height: "156px",
            borderRadius: "30px",
            backgroundColor: "#F1F3F4",
            border: "1px solid #CBD5E1",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Box>
            <Typography
              fontFamily="Montserrat"
              fontWeight="700"
              fontSize="20px"
              color="#7B8A93"
            >
              Create your first organization.
            </Typography>
          </Box>
          <Box>
            <Typography
              fontFamily="Montserrat"
              fontWeight="400"
              fontSize="14px"
              fontStyle="normal"
              color="#7B8A93"
            >
              <span style={{ fontWeight: "700" }}>0</span> ORGANIZATIONS
            </Typography>
          </Box>
        </Box>
      </Card>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          // padding: '5px',
          borderRadius: "50%",
          backgroundColor: "#fafafa",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            cursor: "pointer",
            border: "1px solid #979797",
            width: "58px",
            height: "58px",
            borderRadius: "50%",
            backgroundColor: "#fff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            bottom: "-28px",
          }}
          onClick={() => setOpen(true)}
        >
          <AddIcon color="disabled" />
        </Box>
      </Box>
    </div>
  );
};
