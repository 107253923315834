import React, { FC, useState, useEffect } from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { fonts, colors } from "../../../style/themes";
import { IObjectData6Page4 } from "../../../type";

import expand from "../../../assets/imgs/expand.png";
import decrease from "../../../assets/imgs/decrease.png";
import { ChartVmStatus } from "../../../components/chartVmStatus";

export interface BasicGraph {
  selectedSubitem: { dataKey: string; index: number } | null;
  setSelectedSubitem: Function;
  isChartZooming: boolean;
  setIsChartZooming: Function;
  dataValueChart: {
    cpuGraph: Array<any>;
    memoryGraph: Array<any>;
    diskGraph: Array<any>;
  };
}
interface IDataObj {
  x: string;
  [key: string]: number | string;
}
type Selected = "Object1" | "Object2" | "Object3";

export const BasicGraph: FC<BasicGraph> = ({
  selectedSubitem,
  dataValueChart,
  setSelectedSubitem,
  isChartZooming,
  setIsChartZooming,
}) => {
  const [dataChartValue, setDataChartValue] = useState<any | null>(null);
  const [selectedOption, setSelectedOption] = useState<Selected>("Object1");
  const [filteredData, setFilteredData] = useState<IObjectData6Page4[] | null>(
    null
  );

  useEffect(() => {
    const normalizedDataChart = () => {
      if (selectedSubitem) {
        if (dataValueChart && Object.keys(dataValueChart).length === 0) return;

        const { cpuGraph, memoryGraph, diskGraph } = dataValueChart;
        interface newDataChart {
          Object1: {
            timestamp: any;
            cpu1: any;
          }[];

          Object2: {
            timestamp: any;
            memory: any;
          }[];

          Object3: {
            timestamp: any;
            disk: any;
          }[];
        }

        const newDataChart: newDataChart = {
          Object1: [],
          Object2: [],
          Object3: [],
        };

        for (let i = 0; i < cpuGraph[0].length; i++) {
          newDataChart.Object1.push({
            timestamp: new Date(cpuGraph[0][i]).toISOString(),
            cpu1: cpuGraph[1][i],
          });
        }

        for (let i = 0; i < memoryGraph[0].length; i++) {
          newDataChart.Object2.push({
            timestamp: new Date(memoryGraph[0][i]).toISOString(),
            memory: memoryGraph[1][i],
          });
        }

        for (let i = 0; i < diskGraph[0].length; i++) {
          newDataChart.Object3.push({
            timestamp: new Date(diskGraph[0][i]).toISOString(),
            disk: diskGraph[1][i],
          });
        }
        setDataChartValue(newDataChart);
      }
    };

    normalizedDataChart();
  }, [selectedSubitem, dataValueChart]);

  const convertDate = (timestamp: string): string => {
    let d: Date = new Date(timestamp);
    let hours = (d.getHours() < 10 ? "0" : "") + d.getHours();
    let minutes = (d.getMinutes() < 10 ? "0" : "") + d.getMinutes();
    return hours + ":" + minutes;
  };

  const convertArray = (array: IObjectData6Page4[]): IDataObj[] => {
    return array.map((item: IObjectData6Page4) => {
      const data: IDataObj = {
        ...item,
        x: convertDate(item["timestamp"] as string),
      };
      return data;
    });
  };

  useEffect(() => {
    if (dataChartValue) {
      setFilteredData(convertArray(dataChartValue[selectedOption]));
    }
  }, [selectedOption]);

  useEffect(() => {
    if (dataChartValue) {
      setSelectedOption("Object1");
      setFilteredData(convertArray(dataChartValue["Object1"]));
    }
  }, [dataChartValue]);

  return (
    <Paper
      sx={{
        boxSizing: "border-box",
        boxShadow: "none",
        padding: "14px 25px",
        background: "#F1F3F4",
        border: "1px solid #CBD5E1",
        borderRadius: "30px",
        width: "100%",
        height: !isChartZooming ? "254.85px" : `80vh`,
      }}
    >
      <Grid container>
        <Grid item md={6}>
          <Typography
            sx={{
              fontSize: fonts.size[300],
              fontFamily: fonts.family.secondary,
              fontWeight: fonts.weight.secondary,
              color: colors.secondary[500],
              lineHeight: "20px",
              textAlign: "left",
              marginBottom: "-10px",
            }}
          >
            BASICS
          </Typography>
        </Grid>
        <Grid item md={6} sx={{ display: "flex", justifyContent: "end" }}>
          <Box sx={{ display: "flex", flexWrap: "nowrap", gap: "14px" }}>
            <IconButton
              onClick={() => setSelectedOption("Object1")}
              sx={{
                width: "24px",
                height: "24px",
                padding: "1px",
                border:
                  selectedOption === "Object1"
                    ? "1px solid #1781E2"
                    : "1px solid #7B8A93",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M20 9.56667C20 9.86122 19.7612 10.1 19.4667 10.1H18V13.7H19.4667C19.7612 13.7 20 13.9388 20 14.2333C20 14.5279 19.7612 14.7667 19.4667 14.7667H18V16.6C18 17.3732 17.3732 18 16.6 18H15.5667V19.4667C15.5667 19.7612 15.3279 20 15.0333 20C14.7388 20 14.5 19.7612 14.5 19.4667V18H10.9V19.4667C10.9 19.7612 10.6612 20 10.3667 20C10.0721 20 9.83333 19.7612 9.83333 19.4667V18H6.23333V19.4667C6.23333 19.7612 5.99455 20 5.7 20C5.40545 20 5.16667 19.7612 5.16667 19.4667V18H3.4C2.6268 18 2 17.3732 2 16.6V14.7667H0.533333C0.238781 14.7667 0 14.5279 0 14.2333C0 13.9388 0.238781 13.7 0.533333 13.7H2V10.1H0.533333C0.238781 10.1 0 9.86122 0 9.56667C0 9.27212 0.238781 9.03333 0.533333 9.03333H2V5.43333H0.533333C0.238781 5.43333 0 5.19455 0 4.9C0 4.60545 0.238781 4.36667 0.533333 4.36667H2V3.4C2 2.6268 2.6268 2 3.4 2H5.16667V0.533333C5.16667 0.238781 5.40545 0 5.7 0C5.99455 0 6.23333 0.238781 6.23333 0.533333V2H9.83333V0.533333C9.83333 0.238781 10.0721 0 10.3667 0C10.6612 0 10.9 0.238781 10.9 0.533333V2H14.5V0.533333C14.5 0.238781 14.7388 0 15.0333 0C15.3279 0 15.5667 0.238781 15.5667 0.533333V2H16.6C17.3732 2 18 2.6268 18 3.4V4.36667H19.4667C19.7612 4.36667 20 4.60545 20 4.9C20 5.19455 19.7612 5.43333 19.4667 5.43333H18V9.03333H19.4667C19.7612 9.03333 20 9.27212 20 9.56667ZM14 14.6667C14.3896 14.6066 14.6746 14.2675 14.6667 13.8733V5.33333H13.3333V13.3333H6.66667V14.6667H14Z"
                  fill="#737373"
                />
              </svg>
            </IconButton>
            <IconButton
              onClick={() => setSelectedOption("Object2")}
              sx={{
                width: "24px",
                height: "24px",
                padding: "1px",
                border:
                  selectedOption === "Object2"
                    ? "1px solid #1781E2"
                    : "1px solid #7B8A93",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              <svg
                width="22"
                height="16"
                viewBox="0 0 22 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M21.6667 2.00008V4.66675H20.3334V7.33342H21.6667V14.0001C21.6667 14.7365 21.0698 15.3334 20.3334 15.3334H11.6667V13.0334H10.0734V15.3334H1.66671C0.930328 15.3334 0.333374 14.7365 0.333374 14.0001V7.33342H1.66671V4.66675H0.333374V2.00008C0.333374 1.2637 0.930328 0.666748 1.66671 0.666748H20.3334C21.0698 0.666748 21.6667 1.2637 21.6667 2.00008ZM4.33337 9.33341H7.00004V4.00008H4.33337V9.33341ZM12.3334 9.33341H9.66671V4.00008H12.3334V9.33341ZM15 9.33341H17.6667V4.00008H15V9.33341Z"
                  fill="#737373"
                />
              </svg>
            </IconButton>
            <IconButton
              onClick={() => setSelectedOption("Object3")}
              sx={{
                width: "24px",
                height: "24px",
                padding: "1px",
                border:
                  selectedOption === "Object3"
                    ? "1px solid #1781E2"
                    : "1px solid #7B8A93",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              <svg
                width="22"
                height="17"
                viewBox="0 0 22 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M18.2934 0.666748C18.8841 0.66588 19.405 1.05384 19.5734 1.62008L21.4734 9.33342H0.526707L2.42004 1.64675C2.57922 1.06755 3.10603 0.666298 3.70671 0.666748H18.2934ZM0.333374 10.6667V15.3334C0.333374 16.0698 0.930328 16.6667 1.66671 16.6667H20.3334C21.0698 16.6667 21.6667 16.0698 21.6667 15.3334V10.6667H0.333374ZM19 14.0001H16.3334V12.6667H19V14.0001Z"
                  fill="#737373"
                />
              </svg>
            </IconButton>
          </Box>
          <IconButton
            onClick={() => setIsChartZooming(!isChartZooming)}
            sx={{
              width: "24px",
              height: "24px",
              marginLeft: "30px",
              border: "1px solid #7B8A93",
              borderRadius: "5px",
              cursor: "pointer",
              background: isChartZooming
                ? `center url(${decrease}) no-repeat`
                : `center url(${expand}) no-repeat`,
            }}
          />
        </Grid>
      </Grid>
      <Typography
        sx={{
          width: "100%",
          fontSize: fonts.size[80],
          fontFamily: fonts.family.secondary,
          fontWeight: fonts.weight[600],
          color: colors.secondary[600],
        }}
      >
        Last 2 hours
      </Typography>

      {selectedSubitem?.dataKey ? (
        <ChartVmStatus
          setSelectedSubitem={setSelectedSubitem}
          filteredData={filteredData}
          isChartZooming={isChartZooming}
        />
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "stretch",
            width: "100%",
            height: "85%",
            flexWrap: "wrap",
          }}
        >
          <Typography
            sx={{
              margin: "auto 0",
              fontSize: fonts.size[300],
              fontFamily: fonts.family.secondary,
              fontWeight: fonts.weight.secondary,
              color: colors.primary[400],
            }}
          >
            Select a Resource
          </Typography>
        </Box>
      )}
    </Paper>
  );
};
