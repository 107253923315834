import "../../App.css";
import EnterpriseSignUpContainer from "./EnterpriseSignUpContainer";
import MemberEnterpriseSignUpContainer from "./MemberEnterpriseSignUpContainer";
import { useSearchParams } from "react-router-dom";
import { useState } from "react";

function SignUp() {
  const [signUp, setSignUp] = useState(false);
  const [signUpEnterprise, setSignUpEnterprise] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();

  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [phone, setPhone] = useState("");
  const [lastName, setLastName] = useState("");

  const emailParam = searchParams.get("email");
  const firstNameParam = searchParams.get("firstName");
  const lastNameParam = searchParams.get("lastName");
  const phoneParam = searchParams.get("phone");
  const token = searchParams.get("token");

  const handleClickCreateAccount = () => {
    setSignUp(false);
    setSignUpEnterprise(false);
  };

  return (
    <div className="auth-body">
      <div className="body">
        <div className="main-container">
          <div
            className={`container ${signUp ? "right-panel-active" : ""}`}
            id="container"
          >
            {token ? <MemberEnterpriseSignUpContainer
              email={emailParam || ""}
              setEmail={setEmail}
              firstName={firstNameParam || ""}
              setFirstName={setFirstName}
              lastName={lastNameParam || ""}
              setLastName={setLastName}
              phone={phoneParam || ""}
              token={token || ""}
              setPhone={setPhone}
              handleClickCreateAccount={handleClickCreateAccount}
            /> : 
            <EnterpriseSignUpContainer
              email={emailParam || ""}
              setEmail={setEmail}
              firstName={firstNameParam || ""}
              setFirstName={setFirstName}
              lastName={lastNameParam || ""}
              setLastName={setLastName}
              phone={phoneParam || ""}
              setPhone={setPhone}
              handleClickCreateAccount={handleClickCreateAccount}
            />
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
