import { Box, Card, Skeleton, Typography } from "@mui/material";
import { CloudLoad } from "~/assets";
import nodeIcon from "~/assets/nodeIcon.png";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

export type TLicenseList = {
	licenseEnt?: Array<any>;
	loadingLicense: boolean;
};

function LicenseList({ licenseEnt, loadingLicense }: TLicenseList) {
	return (
		<div className="info-card second-row-card licenses-home">
			<div className="members-heading" style={{ marginRight: "0" }}>
				<h3 className="home-active-licenses">ACTIVE LICENSES</h3>
				<div className="person-icon-image">
					<CloudLoad />
				</div>
			</div>
			<h4 className="home-licenses-counter">{licenseEnt?.length}</h4>
			<div className="home-licenses-container" style={{ position: "relative" }}>
				{licenseEnt && licenseEnt?.length > 5 && (
					<Box
						sx={{
							display: "flex",
							justifyContent: "flex-end",
							position: "absolute",
							right: "-4px",
							marginTop: "-27px",
						}}
					>
						<KeyboardArrowUpIcon />
					</Box>
				)}
				{loadingLicense ? (
					<Box marginTop={3} marginLeft={4}>
						<Skeleton
							variant="rectangular"
							sx={{
								width: "100%",
								height: "34px",
								boxSizing: "border-box",
								borderRadius: "3px",
							}}
						/>
					</Box>
				) : (
					<>
						<div className="licenses-list-scrollbar">
							{/* <br/> */}
							<Box
								sx={{
									marginTop: "11px",
									height: "216px",
									maxWidth: "348px",
									overflowY: "auto",
								}}
							>
								{licenseEnt && licenseEnt?.length < 1 && (
									<Typography
										textAlign={"center"}
										fontWeight="400"
										fontFamily="Montserrat"
										marginTop={"12px"}
										fontSize="15px"
										fontStyle="normal"
										letterSpacing="0.25px"
										color={"#7B8A93"}
									>
										No licenses were found
									</Typography>
								)}
								{licenseEnt &&
									licenseEnt.map((licenseEnt) => (
										<Card
											key={licenseEnt.id}
											sx={{
												backgroundColor: "#fafafa",
												height: "38px",
												borderRadius: "11px",
												margin: "auto 24px",
												"@media (max-width: 1500px)": {
													margin: "8px 10px",
													marginRight: 0,
												},
												// marginRight: '0px',
												marginBottom: "7px",

												boxShadow: 0,
											}}
										>
											<Box
												sx={{
													display: "flex",
													padding: "px",
													alignItems: "center",
													position: "relative",
												}}
											>
												<Box
													sx={{
														display: "flex",
														marginRight: "24px",
														alignItems: "center",
														paddingLeft: "15px",
														"@media (max-width: 1500px)": {
															paddingLeft: "5px",
															marginRight: "0px",
														},
													}}
												>
													<img
														src={nodeIcon}
														alt="node icon"
														height={"16.5px"}
														width={"12.5px"}
													/>
													<Typography
														marginLeft="14px"
														fontFamily={"Montserrat"}
														fontWeight="600"
														fontSize="12px"
														lineHeight="35px"
														fontStyle="normal"
														color={"#7B8A93"}
														width="128px"
														overflow="hidden"
														textOverflow={"ellipsis"}
													>
														{licenseEnt?.name}
													</Typography>
												</Box>
												<Typography
													fontWeight="600"
													fontFamily={"Montserrat"}
													fontSize="10px"
													fontStyle="normal"
													color="#1781E2"
													width={"70px"}
													overflow="hidden"
													textOverflow={"ellipsis"}
												>
													{licenseEnt?.title}
												</Typography>
												<span
													style={{
														color: "#48535C",
														fontSize: "24px",
														transform: "rotate(90deg)",
														cursor: "pointer",
														position: "absolute",
														right: "-5px",
														letterSpacing: "2px",
													}}
												>
													...
												</span>
											</Box>
										</Card>
									))}
							</Box>
						</div>
						{licenseEnt && licenseEnt?.length > 5 && (
							<Box
								sx={{
									display: "flex",
									justifyContent: "flex-end",
									position: "absolute",
									right: "-4px",
									marginTop: "3px",
								}}
							>
								<KeyboardArrowDownIcon />
							</Box>
						)}
					</>
				)}
			</div>
		</div>
	);
}

export default LicenseList;
