export const FindFilter: React.FC = () => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 22 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_882_41946)">
      <path
        d="M9.88637 7.00082C8.4831 9.43136 9.31586 12.5393 11.7464 13.9426C14.177 15.3458 17.2849 14.5131 18.6881 12.0825C20.0914 9.65199 19.2587 6.54406 16.8281 5.14079C14.3976 3.73751 11.2896 4.57028 9.88637 7.00082Z"
        stroke="#527097"
        stroke-width="0.824343"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.6262 13.2102L3.29315 18.941C2.72187 19.3461 1.9161 19.2183 1.51616 18.638C1.11101 18.0667 1.23885 17.2609 1.81917 16.861L9.72485 11.8705"
        fill="#527097"
      />
      <path
        d="M10.6262 13.2102L3.29315 18.941C2.72187 19.3461 1.9161 19.2183 1.51616 18.638C1.11101 18.0667 1.23885 17.2609 1.81917 16.861L9.72485 11.8705"
        stroke="#527097"
        stroke-width="0.824343"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.8584 7.22436C16.7676 7.94856 17.1894 9.12593 16.963 10.2707"
        stroke="#527097"
        stroke-width="0.490432"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_882_41946">
        <rect
          width="20.2955"
          height="13.1686"
          fill="white"
          transform="translate(21.7998 7.52832) rotate(120)"
        />
      </clipPath>
    </defs>
  </svg>
);
