import { Typography } from "@mui/material";

export type TDeployHeading = {
  title: string;
  description: string;
};

export const DeployBaasStepHeading = ({ title, description }: TDeployHeading) => {
  return (
    <>
      <Typography
        fontFamily="Montserrat"
        fontWeight="600"
        color="#3E474D"
        fontSize="1.37rem"
        sx={{
          paddingBottom: "14.06px",
        }}
      >
        {title}
      </Typography>
      <Typography
        fontFamily="Montserrat"
        fontWeight="500"
        color="#5F6368"
        fontSize={"12px"}
        textTransform="capitalize"
        sx={{
          paddingBottom: "31.41px",
          letterSpacing: '1px'
        }}
      >
        {description}
      </Typography>
    </>
  );
};
