
const INITIAL_STATE = '.'; 

interface IPayload {
	permission: string;
}

interface IAction {
	type: string;
	payload: IPayload;
}



// eslint-disable-next-line @typescript-eslint/default-param-last
export default function (state = INITIAL_STATE, action: IAction) {
	switch (action.type) {
		case 'PROFILE_USER_PERMISSION':
			return action.payload.permission;
		default:
			return state;
	}
}
