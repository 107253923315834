import { useContext, useEffect, useState } from "react";
import { Box, Button, Card, Divider, Grid, Typography } from "@mui/material";
import { DeployBaasStepHeading } from "./DeployBaasStepHeading";
import { ProtocolSetup } from "../../../../../components/protocolSetup";
import { NetworkSetup } from "../../../../../components/networkSetup";
import { MachineSetup } from "../../../../../components/machineSetup";
import { WizardContext } from "../../../../../context/wizardContext";

import CheckIcon from "@material-ui/icons/Check";

import gear from "../../../../../assets/icons/line.png";
import computer from "../../../../../assets/icons/solid.png";
import computerSuccess from "../../../../../assets/icons/computer-ok.png";
import doorman from "../../../../../assets/icons/doorman.png";

export const DeployBaasVMCordaSetup = () => {
  // @ts-ignore
  const { deployData, setDeployData } = useContext(WizardContext);

  const [backgroundColor, setBackgroundColor] = useState("#F1F3F4");
  const [vmMessage, setVmMessage] = useState("");
  const [machineView, setMachineView] = useState<boolean | null>(true);

  const [selectedId, setSelectedId] = useState<number>(1);
  const [validSetup, setValidSetup] = useState<boolean>(false);
  const [styleSetup, setStyleSetup] = useState<any>();

  const [isFirstGreen, setIsFirstGreen] = useState(false);
  const [isSecondEnabled, setIsSecondEnabled] = useState<boolean>(false);
  const [notarieModePage, setNotarieModePage] = useState<boolean>(false);
  const [doormanModePage, setDoormanModePage] = useState<boolean>(false);

  type TStackType = {
    id: number;
    image: string;
    name: string;
  };

  const sytleTypeSetup = {
    backgroundColor: "#F8F9FA",
    opacity: ".5",
    cursor: "not-allowed",
  };

  const StackType = [
    {
      id: 1,
      image: !deployData.nextStepProtocol ? computer : computerSuccess,
      name: "Machine Setup",
    },
    {
      id: 2,
      image: notarieModePage ? gear : !doormanModePage ? gear : doorman,
      name: notarieModePage
        ? "Protocol Setup"
        : !doormanModePage
        ? "Protocol Setup"
        : "Network Manager Setup",
    },
  ];

  const handleClick = (id: number) => {
    setSelectedId(id === selectedId ? id : id); // Toggle do estado selecionado
    id === 1 ? setMachineView(true) : setMachineView(false);
  };

  useEffect(() => {
    if (!deployData.nextStepProtocol) {
      setSelectedId(1);
      setMachineView(true);
    } else {
      setSelectedId(2);
      setMachineView(false);
    }
  }, [deployData.nextStepProtocol]);

  useEffect(() => {
    if (validSetup) {
      setStyleSetup(StackType);
    }
  }, []);

  useEffect(() => {
    if (deployData.setupNotarie) {
      setNotarieModePage(deployData.setupNotarie);
    } else {
      setNotarieModePage(false);
    }
    if (deployData.setupDoorman) {
      setDoormanModePage(deployData.setupDoorman);
    } else {
      setDoormanModePage(deployData.setupDoorman);

    }
  }, [deployData.setupNotarie, deployData.setupDoorman]);

  useEffect(() => {
    if (deployData.nextStepProtocol) {
      setIsSecondEnabled(true);
      setIsFirstGreen(true);
    } else {
      setIsSecondEnabled(false);
      setIsFirstGreen(false);
    }
  }, [deployData.nextStepProtocol]);

  const getBorderStyle = (index: number, value: TStackType) => {
    if (index === 1) {
      return isSecondEnabled
        ? selectedId === value.id
          ? "2px solid #1781E2"
          : "1px solid #7B8A93"
        : "1px solid #D3D3D3";
    }
    return selectedId === value.id
      ? "2px solid #1781E2"
      : isFirstGreen
      ? "1px solid #1781E2"
      : !deployData.nextStepProtocol
      ? "1px solid #1781E2"
      : "1px solid #7B8A93";
  };

  return (
    <Card
      sx={{
        boxShadow: "none",
      }}
    >
      {/* <Button onClick={handleButtonClick}>Habilitar Segundo Item</Button> */}
      <DeployBaasStepHeading
        title={
          notarieModePage
            ? "VM Setup - Notary"
            : !doormanModePage
            ? "VM Setup - Node"
            : "VM Setup - Doorman"
        }
        description={`Please import all ${ deployData.versionRegular + deployData.versionNotarie + 1} vM's, one by one so we can proceed to the next phase`}
      />
      <Box>
        <Grid container md={12}>
          <Grid item md={11}>
            <Typography
              fontFamily="Montserrat"
              fontWeight="600"
              color="#3E474D"
              fontSize={"13px"}
              sx={{
                paddingBottom: "10px",
              }}
            >
              Importing Stack VM{deployData.currentVmEnt}
            </Typography>
          </Grid>
          <Grid item md={1}>
            <Typography
              fontFamily="Montserrat"
              fontWeight="600"
              color="#5F6368"
              fontSize={"13px"}
              sx={{
                paddingBottom: "10px",
              }}
            >
              {deployData.currentVmEnt} /{" "}
              {deployData.versionNotarie + deployData.versionRegular + 1}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          backgroundColor: "rgba(241, 243, 244, .3)",
          border: "1px solid #F1F3F4",
          width: "100%",
          height: "22px",
          borderRadius: "5px",
        }}
      >
        <Grid container md={12}>
          <Grid item md={11}>
            <Typography
              fontFamily="Montserrat"
              fontWeight="600"
              color="#3E474D"
              fontSize={"13px"}
              sx={{
                height: "15px",
                lineHeight: "15px",
                fontSize: "12px",
                textAlign: "left",
                color: "#7B8A93",
                paddingLeft: "4px",
                paddingTop: "2px",
                letterSpacing: "1px",
              }}
            >
              VM {deployData.currentVmEnt}{" "}
              {!notarieModePage
                ? ""
                : !doormanModePage
                ? "- Notary"
                : "- Doorman"}
            </Typography>
          </Grid>
          {/* <Grid item md={1}>
          </Grid> */}
        </Grid>
      </Box>
      <Grid container marginTop="16px" md={12} columnGap={6} flexWrap="nowrap">
        {StackType.map((value: TStackType, index: number) => (
          <Grid item md={5.5}>
            <Box position="relative" overflow="hidden" style={styleSetup}>
              {index === 0 && isSecondEnabled && (
                <Box
                  position="absolute"
                  left="-20px"
                  top="-14px"
                  sx={{
                    width: "54px",
                    height: "39px",
                    transform: "rotate(-44.333deg)",
                    background: "rgba(23, 129, 226, 0.40)",
                  }}
                >
                  <CheckIcon
                    style={{
                      color: "#fff",
                      fontSize: 20,
                      fontWeight: "bold",
                      marginLeft: "15px",
                      marginTop: "17px",
                      transform: "rotate(45.333deg)",
                    }}
                  />
                </Box>
              )}
              <Box
                onClick={() => handleClick(value.id)}
                sx={{
                  paddingLeft: "2px",
                  paddingRight: "2px",
                  display: "flex",
                  // width: "191px",
                  height: "96px",
                  border: getBorderStyle(index, value),
                  justifyContent: "center",
                  alignItems: "center",
                  pointerEvents:
                    index === 1 && !isSecondEnabled ? "none" : "auto",
                  cursor:
                    index === 1 && !isSecondEnabled ? "not-allowed" : "pointer",
                  boxShadow: "0px 1px 10px 1px rgba(0, 0, 0, 0.10)",
                  // cursor: "pointer",
                  "&:hover": {
                    border: index === 1 ? "" : "2px solid #1781E2",
                  },
                }}
              >
                <Box display="flex" flexDirection="column" alignItems="center">
                  <img src={value.image} width="50px" />
                  <Typography
                    textAlign="center"
                    color={
                      index === 1 || !deployData.nextStepProtocol
                        ? "#5F6368"
                        : "rgba(23, 129, 226, 0.60)"
                    }
                    fontSize="12px"
                    fontStyle="normal"
                    fontWeight="600"
                    fontFamily="Montserrat"
                  >
                    {value.name}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
      <Box>
        <Typography
          fontFamily="Montserrat"
          fontWeight="600"
          color="#3E474D"
          fontSize={"13px"}
          textAlign="right"
          marginBottom="14px"
          sx={{ paddingTop: "10px" }}
        >
          {vmMessage}
        </Typography>
      </Box>
      <Box>
        {machineView ? (
          <MachineSetup />
        ) : doormanModePage ? (
          <NetworkSetup />
        ) : (
          <ProtocolSetup />
        )}
      </Box>
    </Card>
  );
};
