import React, { Fragment, FC, useState, useEffect, useContext } from "react";
import { WizardContext } from "../../context/wizardContext";

import { useNavigate } from "react-router-dom";

import Collapse from "@mui/material/Collapse";
import Box from "@mui/material/Box";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

import { Resource } from "./Resource";
import { SubResourceDeployment } from "./subResourceDeployment";
import { MonitoringService } from "./../../services/monitoring.service";

interface IProps {
  data: any;
  uptime: any;
  currentUpTime: number;
  tokenUser: string;
  user: any;
  setUpdateListNetwork: (value: boolean) => void;
  selectedSubitem: {
    dataKey: string;
    index: number;
  } | null;
  setSelectedSubitem: React.Dispatch<
    React.SetStateAction<{
      dataKey: string;
      index: number;
    } | null>
  >;
}

export const ResourceDeployment: FC<IProps> = ({
  data,
  uptime,
  user,
  selectedSubitem,
  setSelectedSubitem,
  setUpdateListNetwork,
  tokenUser,
  currentUpTime,
}) => {
  const monitoringService = new MonitoringService();
  const navigate = useNavigate();
  const { setIdvmt } = useContext<any>(WizardContext);
  const [expandedDataKey, setExpandedDataKey] = useState<string | null>(null);
  const [removeNetwork, setRemoveNetwork] = useState<boolean>(false);

  // const [uptime, setUptime] = useState<any>();
  // useEffect(()=>{
  //   const getVMUptime = async () => {
  //     const respRemoveNetwork = await monitoringService.getVmsUptimed(tokenUser);
  //     // setUptime(respRemoveNetwork)
  //   }
  //   if(data && !uptime && tokenUser){
  //     getVMUptime()
  //   }
  // },[])

  useEffect(() => {
    if (removeNetwork) {
      setRemoveNetwork(false);
    }
  }, [removeNetwork]);

  const handleToggle = (key: string) => {
    setSelectedSubitem(null);
    navigate("/resources/monitoring/network");
    setExpandedDataKey(expandedDataKey === key ? null : key);
  };

  const handleSelectSubitem = (
    dataKey: string,
    index: number,
    idVm: string
  ) => {
    setIdvmt(idVm);
    setSelectedSubitem({ dataKey, index });
  };

  return (
    <>
      {Object.keys(data).length !== 0 &&
        Object.keys(data.resourceGroups).map((key: any) => {
          const isExpanded = expandedDataKey === key;
          return (
            <Fragment key={key}>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexWrap: "nowrap",
                }}
              >
                <Box sx={{ paddingX: "7.5px" }}>
                  {isExpanded ? (
                    <ExpandLess onClick={() => handleToggle(key)} />
                  ) : (
                    <ExpandMore onClick={() => handleToggle(key)} />
                  )}
                </Box>
                <Resource
                  setRemoveNetwork={setRemoveNetwork}
                  user={user}
                  dataKey={key}
                  data={data.resourceGroups[key]}
                  dataStatus={data.resourceGroups}
                  uptimeData={uptime}
                  handleToggle={() => {}}
                  tokenUser={tokenUser}
                  setUpdateListNetwork={setUpdateListNetwork}
                />
              </Box>
              <Collapse
                sx={{ width: "100%", paddingLeft: "58px" }}
                in={isExpanded}
                timeout="auto"
              >
                <SubResourceDeployment
                  data={data.resourceGroups[key]}
                  currentUpTime={currentUpTime}
                  dataKey={key}
                  uptimeData={uptime}
                  handleSelectSubitem={handleSelectSubitem}
                  selectedSubitem={selectedSubitem}
                />
              </Collapse>
            </Fragment>
          );
        })}
    </>
  );
};
