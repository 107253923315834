import { Box, LinearProgress, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { DeployStepHeading } from "../../../components/Platform/Deploy/DeployWizard/WizardSteps/DeployStepHeading";
import { ProcessBaasStepper } from "./ProcessBaasStepper/ProcessBaasStepper";
import { WizardContext } from "../../../context/wizardContext";
import { OrderService } from "../../../services/order.service";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

interface Props {
  tokenUser: string;
}

const orderService = new OrderService();

export const DeployBaasProcess = ({ tokenUser }: Props) => {
  const history = useNavigate();
  const { enqueueSnackbar } = useSnackbar()
  const [progress, setProgress] = useState();
  const [processPercent, setProcessPercent] = useState(0);
  const [error, setError] = useState(false);
  const [errorIds, setErrorIds] = useState(false);
  const [errorTicket, setErrorTicket] = useState("");
  let inicialState = 0;

  // @ts-ignore
  const { deployData, setDeployData } = useContext(WizardContext);
 

  useEffect(() => {
    const order = {
      protocol: deployData.protocolType,
      version: deployData.version,
      stack: deployData.selectedStack,
      consensus: deployData.consesus,
      // resourceGroup: {
      // },
      vms: deployData.vms,
      corda: deployData.corda,
      substrate: deployData.substrate,
      
      enterpriseId: deployData.enterpriseId,
      enterpriseWallet: "0x000000000000000000000000000000000000dEaD",
      organizationId: deployData.orgId,
      signedTx: deployData.signedTx,
      deployUUID: deployData.deployUUID,
      networkName: deployData.networkName,
    };

    // Fazer as condicionais das rotas corda / vms
    const createOrder = async (order: any) => {
      const orderRes = await orderService.createOrderBaas(order, tokenUser);
      if (orderRes.status !== 201) {
        return []
      }
      return orderRes.data;
    };
    const createOrderCorda = async (order: any) => {
      const orderRes = await orderService.createOrderBaasCorda(order, tokenUser);
      if (orderRes.status !== 201) {
        return []
      }
      return orderRes.data;
    };

    const createIdsArray = async (order: any) => {
      const orderRes = await deployData.vms.length ? createOrder(order) : createOrderCorda(order);
      return orderRes;
    };

    const fetchStatus = async (idsArray: any) => {
      const orderStatusRes = await orderService.fetchOrderStatus(
        idsArray,
        tokenUser
      );
      return orderStatusRes.data;
    };

    const loopStatus = async (idsArray: any) => {
      let intervalId = setInterval(() => {
        fetchStatus(idsArray).then((resposta) => {
          const jobSize = resposta?.jobs?.length
          let countSuccessful = 0

          resposta?.jobs?.forEach((value: any) => {
            if (value === "successful") {
              countSuccessful += 1
            }
          })

          const finalProgress = (countSuccessful / jobSize) * 100;

          const resp = resposta?.jobs.every(
            (el: string) => el !== "running" && el !== "pending"
          );
          setDeployData({ ...deployData, statusButtonDone: resp });
          
          if (resposta?.jobs.every((p: any) => p === "successful")) {
            clearInterval(intervalId);
          }
          if (
            resposta?.jobs.some((p: any) => p === "failed" || p === "error" || p === "canceled")
            ) {
            setErrorTicket(resposta.supportTicket);
            setError(true);

            setDeployData({ ...deployData, statusButtonDone: true });

            resposta?.jobs.forEach((p: any, index: number) => {
              if (p === "failed" || p === "error" || p === "canceled") {
                setDeployData({ ...deployData, statusButtonDone: true });
                setProcessPercent(finalProgress);
              }
            });

            clearInterval(intervalId);
          }

          let count: number = resposta?.jobs.filter(
            (status: any) => status === "successful"
          ).length;

          const playbookProgress: number = (count / resposta?.jobs.length) * 75;

          // const finalProgress = playbookProgress + inicialState;

          setProcessPercent(finalProgress);
        });
      }, 10000);
    };

    const requests = async (order: any) => {
      const idsArray = await createIdsArray(order);
      if (idsArray.length === 0) {
        enqueueSnackbar("Error on Deploy. You will rendering in 5s and try again", {
          variant: "error",
        });
        setErrorIds(true)
        localStorage.setItem("menuClick", '3')
        return
      }
      const test = await loopStatus(idsArray);
      return test;
    };

    requests(order);
  }, []);
  return (
    <>
      <Box>
        <DeployStepHeading
          title="Deploying your new Network"
          description="This usualy takes between 8 and 16 minutes"
        />
        <Box sx={{ paddingTop: "30px", paddingBottom: "30px" }}>
          <LinearProgress
            variant="determinate"
            value={processPercent}
            color="info"
          />
        </Box>
        <ProcessBaasStepper
          percent={processPercent}
          error={error}
          errorIds={errorIds}
          errorTicket={errorTicket}
        />
        <Box sx={{ paddingTop: "70px" }}>
          <Typography
            fontFamily="Montserrat"
            fontSize="12px"
            fontWeight="500"
            lineHeight="15px"
            letterSpacing="1px"
            textAlign="left"
          >
            After completing the deployment, you can check out your network
            using the monitoring services.
          </Typography>
        </Box>
      </Box>
    </>
  );
};
