// Core
import * as yup from 'yup';

export const SchemaFormProfile = yup
  .object({
    data4: yup.string().required(),
    data5: yup.string().required(),
    data6: yup.string().required(),
    data7: yup.string().required(),
    data8: yup.string().required(),
    data9: yup.string().required(),
    data10: yup.string().required(),
  })
  .required();

export type IFormDataPage5 = yup.InferType<typeof SchemaFormProfile>;
