import React from 'react';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import './ProgessBar.css';

function MemberProgressBar(props: { signUpStep: number; }) {

    return (
        <div className="test-deve-member">
        <div className="container-progress-member">
            <div className="progress-container-member">
                <div className="progress-member" id="progress-member"> </div>
                <div className={`circle-member ${props.signUpStep > 0 ? `active`: ''}`}>{props.signUpStep > 0 ? `✓`: '1'}</div>
                <div className={`circle-member ${props.signUpStep > 1 ? `active`: ''}`}>{props.signUpStep > 1 ? `✓`: '2'}</div>
                <div className={`circle-member ${props.signUpStep > 2 ? `active`: ''}`}>{props.signUpStep > 2 ? `✓`: '3'}</div>
            </div>
        </div>
        <div className="step-labels">
            <div className="step-label">
                <h2>Member</h2>
                <p>Info and security</p>
            </div>
            <div className="step-label">
                <h2>Wallet</h2>
                <p>Label and authentication</p>
            </div>
            <div className="step-label">
                <h2>Conclusion</h2>
            </div>
        </div>
        </div>
    );
}

export default MemberProgressBar;
