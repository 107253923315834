import { ThemeProvider } from "@emotion/react";
import {
  Box,
  Grid,
  Button,
  Dialog,
  Typography,
  DialogContent,
  Divider,
  DialogTitle,
  FormHelperText
} from "@mui/material"

import { MemberService } from '../../services/member.service';

import { customTheme } from "../../style";
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import { CloseModal } from "~/assets";
import { useSnackbar } from "notistack";
import { useState } from "react";


export type TNextMember = {
  title: string;
  setOpen: (value: boolean) => void;
  setOpenMember: (value: boolean) => void;
  setMemberFound: (value: boolean) => void;
  setNewMember: any;
  newMember: any;
  openMember: boolean;
  open: boolean;
  tokenUser: string;
  idOrg: string;
}

export const NexMember = ({
  title,
  setOpen,
  open,
  setMemberFound,
  setOpenMember,
  setNewMember,
  openMember,
  tokenUser,
  idOrg,
}: TNextMember) => {
  const [emailUser, setEmailUser] = useState<string>('');

  const { enqueueSnackbar } = useSnackbar();

  const memberService = new MemberService();

  const handleClose = () => {
    setOpen(false);
  };

  const verifyMemberByEmail = async () => {
    try {
      const restMember = await memberService.getMemberByEmail(idOrg, emailUser, tokenUser);
      if (emailUser) {
        localStorage.setItem('memberEmail', emailUser);
      }
      if (restMember.status === 200) {
        if (restMember.data) {
          setNewMember([restMember.data]);
          setMemberFound(true);
          localStorage.setItem('memberIdByEmail', restMember.data.id);
          enqueueSnackbar('Member found!',
            {
              variant: 'success'
            }
          )
        } 
      } else {
        enqueueSnackbar('Insert new member!',
          {
            variant: 'info'
          }
        )
        setNewMember('');
        setMemberFound(false);
      }
      handleClose();
      setOpenMember(true);
    } catch (error: any) {
      const { statusCode, message } = error?.response?.data;
      if ([403, 400, 404].includes(statusCode)) {
        enqueueSnackbar(message, {
          variant: "error",
        })
      }
    }
  }

  const setValidateEmail = (e: any) => {
    const email = e.target.value;
    const validate = /\S+@\S+\.\S+/;
    if (!email) return;

    validate.test(email)
      ? setEmailUser(e.target.value)
      : setEmailUser('');
  }

  return (
    <Dialog
      aria-describedby="dialogo de confirmação"
      fullWidth
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: {
          backgroundColor: '#F1F3F4',
          borderRadius: '18px',
        },
      }}
    >
      <DialogTitle
        sx={{
          '& .MuiTypography-root': {
            fontFamily: 'Montserrat',
            fontWeight: '500',
            fontSize: '25px',
            color: '#5F6368',
          }
        }}>
        <Typography fontWeight={600} fontSize={25} alignSelf="center">
          {title}
        </Typography>
      </DialogTitle>
      <Box
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: '16px',
          top: '20px',
          cursor: 'pointer',
          padding: '8px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CloseModal />
      </Box>
      <Divider />
      <DialogContent>
        <ThemeProvider theme={customTheme}>
          <Grid container md={12} sx={{ marginBottom: '24px' }}>
            <Grid item md={12} sm={12}>
              <InputLabel
                shrink
                error={!emailUser}
                htmlFor="email"
              >
                Mail
              </InputLabel>
              <OutlinedInput
                autoFocus
                error={!emailUser}
                onChange={setValidateEmail}
                sx={{
                  marginTop: '10px !important',
                  width: '100%'
                }}
                placeholder="type your email to be veried"
                type="string"
              />
              {!emailUser && (
                <FormHelperText error>
                  {'type a valid email'}
                </FormHelperText>
              )}
            </Grid>
          </Grid>
          <Grid container md={12} sm={12} sx={{ justifyContent: 'center', margin: '32px auto 15px' }}>
            <Grid item md={8} sm={12} >
              <Button
                fullWidth
                onClick={verifyMemberByEmail}
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center'
                }}
                variant="contained"
              // onClick={changeState}
              >
                <Typography
                  fontWeight="600"
                  fontSize="14px"
                  fontFamily="Montserrat"
                >
                  Submit
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </ThemeProvider>
      </DialogContent>
    </Dialog>
  )
}