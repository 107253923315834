import { Box, Skeleton, Typography } from "@mui/material";

export const SkeletonOrgManagement = ({}) => {
  return (
    <div className="org-management-info-body">
      <div>
        <div className="info-container">
          <Box sx={{ marginRight: "25px", width: "150px" }}>
            <Typography
              fontFamily="Montserrat"
              fontWeight={500}
              fontSize="13px"
              lineHeight="20px"
              color="#5F6368"
            >
              SPONSOR:
            </Typography>
            <Skeleton
              variant="rectangular"
              sx={{
                width: "55%",
                height: "10px",
                boxSizing: "border-box",
                borderRadius: "3px",
              }}
            />
          </Box>
          <Box>
            <Typography
              fontFamily="Montserrat"
              fontWeight={500}
              fontSize="13px"
              lineHeight="20px"
              color="#5F6368"
            >
              COUNTRY:
            </Typography>
            <Skeleton
              variant="rectangular"
              sx={{
                width: "55%",
                height: "10px",
                boxSizing: "border-box",
                borderRadius: "3px",
              }}
            />
          </Box>
        </div>
        <div className="info-container">
          <Box
            sx={{
              marginRight: "25px",
              // wordBreak: "break-all",
              width: "150px",
            }}
          >
            <Typography
              fontFamily="Montserrat"
              fontWeight={500}
              fontSize="13px"
              lineHeight="20px"
              color="#5F6368"
            >
              E-MAIL:
            </Typography>
            <Skeleton
              variant="rectangular"
              sx={{
                width: "55%",
                height: "10px",
                boxSizing: "border-box",
                borderRadius: "3px",
              }}
            />
          </Box>
          <div>
            <Box>
              <Typography
                fontFamily="Montserrat"
                fontWeight={500}
                fontSize="13px"
                lineHeight="20px"
                color="#5F6368"
              >
                CITY:
              </Typography>
              <Skeleton
                variant="rectangular"
                sx={{
                  width: "100%",
                  height: "10px",
                  boxSizing: "border-box",
                  borderRadius: "3px",
                }}
              />
            </Box>
          </div>
        </div>
      </div>
    </div>
  );
};
