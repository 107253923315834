import { useContext, useEffect, useState } from "react";
import { WizardContext } from "../../context/wizardContext";
import { useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { MemberService } from "../../services/member.service";
import { MemberFormView } from "./memberFormView";

import { SchemaMemberDefault } from "./SchemaMember";

import { yupResolver } from "@hookform/resolvers/yup";
import { useSnackbar } from "notistack";
import { DeployAuthorization } from "../deployAuthorization";
import uiKeyring from "@polkadot/ui-keyring";
import { ApiPromise, WsProvider } from "@polkadot/api";

import { waitReady } from "@polkadot/wasm-crypto";
import { InviteMembers } from "./invite-member";
import { textError } from "../../utils/textError";

export type TMemberFormContainer = {
  title: string;
  open: boolean;
  setOpen: (value: boolean) => void;
  memberFound: boolean;
  setSuccessMember?: ((value: boolean) => void) | any;
  successMember?: boolean | any;
  idOrg?: string | any;
  idMember?: string | any;
  idMemberVerifed?: string;
  tokenUser: string;
};

interface IMemberFormContainer {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  mobile: string;
  jobTitle: string;
  phone: string;
  password: string;
  confirmPassword: string;
  permission: string;
  organizationId: string;
}

export const MemberFormContainer = ({
  title,
  open,
  setOpen,
  idOrg,
  idMember,
  memberFound,
  setSuccessMember,
  idMemberVerifed,
  tokenUser,
}: TMemberFormContainer) => {
  const { user } = useContext<any>(WizardContext);
  const { enqueueSnackbar } = useSnackbar();
  const memberIdByEmail = localStorage.getItem("memberIdByEmail") || "";
  const [memberInformation, setMemberInformation] = useState<
    Array<IMemberFormContainer>
  >([]);
  const [noMatchPass, setNoMatchPass] = useState<boolean>(false);
  const [loadingMember, setLoadingMember] = useState<boolean>(false);
  const [openInviteMember, setOpenInviteMember] = useState<boolean>(false);
  const [loadingAuthorization, setLoadingAuthorization] =
    useState<boolean>(false);
  const [openAuthorization, setOpenAuthorization] = useState<boolean>(false);
  const [passwordAuthorization, setPasswordAuthorization] = useState<string>('');

  const [fezLoad, setFezLoad] = useState<boolean>(false);

  const defaultValue = {
    firstName: "",
    lastName: "",
    jobTitle: "",
    phone: "",
    email: "",
    password: "",
    confirmPassword: "",
    permission: "stakeholder",
  };

  const history = useNavigate();

  // cadastrar o tipo
  const form = useForm<any>({
    resolver: yupResolver(SchemaMemberDefault),
  });

  useEffect(() => {
    if (idMemberVerifed === '') {
      setOpenInviteMember(true)
    }
  }, [idMemberVerifed])

  const memberService = new MemberService();
  const handleClose = () => {
    form.reset(defaultValue);
    setOpen(false);
  };

  const handleCloseRemove = () => {
    setSuccessMember(true);
    setOpen(false);
  };

  useEffect(() => {
    const getMemberById = async () => {
      try {
        if (idMember) {
          const resultMember = await memberService.getMemberByIdNew(
            idMember,
            idOrg,
            tokenUser
          );
          if (resultMember.status === 200) {
            setMemberInformation(resultMember.data);
          }
          if (resultMember.status === 404) {
            enqueueSnackbar(resultMember.statusText, {
              variant: 'error',
            });
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    if (open && idMember) getMemberById();
  }, [open]);

  const onSubmit = () => {
    setOpenAuthorization(true);
  };

  const handleCloseAuthoraztion = () => {
    setOpenAuthorization(false);
  };

  const onSubmitAuthorization = async () => {
    setLoadingAuthorization(true);
    try {
      const {
        firstName,
        lastName,
        jobTitle,
        phone,
        email,
        password,
        confirmPassword,
        permission,
      } = form.getValues();

      if (password !== confirmPassword) {
        setNoMatchPass(true);
        return;
      } else {
        setNoMatchPass(false);
      }

      if (idMemberVerifed) {
        const provider = new WsProvider(process.env.REACT_APP_CHAIN_ADDRESS);
        const api = await ApiPromise.create({
          types: {
            UserRole: {
              _enum: ['Admin', 'StakeHolder']
            },
          },
          provider
        });

        // PEGAR TODAS WALLETS

        try {
          await waitReady();
          if (!fezLoad) {
            uiKeyring.loadAll({ ss58Format: 42, type: "sr25519" });
            setFezLoad(true);
          }
        } catch (error) {
          console.log(error)
        }

        const callerUUID = localStorage.getItem("userId") as string;

        let userPair: any;

        try {
          userPair = uiKeyring.getPair(user.walletAddress);
        } catch (error) {
          enqueueSnackbar(textError, {
            variant: 'error',
            autoHideDuration: 5000,
          });
          setTimeout(() => {
            history('/profile/wallet/recovery')
          }, 5000);
          setLoadingMember(false);
          setLoadingAuthorization(false);
          return
        }

        try {
          userPair.unlock(passwordAuthorization);

        } catch (error) {
          setLoadingAuthorization(false);
          enqueueSnackbar('Invalid password', {
            variant: "error",
          });
          return
        }

        const orgUUID = idOrg;

        const memberUUID = memberIdByEmail;

        const genesisHash = api.genesisHash;
        const runtimeVersion = api.runtimeVersion;

        let nonce = await api.rpc.system.accountNextIndex(user.walletAddress);


        let urole = api.createType('UserRole', 'StakeHolder')

        if (permission === "admin") {
          urole = api.createType('UserRole', 'Admin');
        }

        const txMemberAdd = api.tx.multiledgersIdentity
          .addMember(callerUUID, orgUUID, memberUUID, urole).sign(userPair, { genesisHash, blockHash: genesisHash, nonce, runtimeVersion });

        await api.disconnect();

        // passar o memberId
        const respMemberExist = await memberService.updateMemberExist(
          memberIdByEmail,
          idOrg,
          { signedTx: txMemberAdd, permission },
          tokenUser
        );

        handleClose();
        handleCloseAuthoraztion();
        if (respMemberExist) {
          enqueueSnackbar("Member updated", {
            variant: "success",
          });
          setLoadingAuthorization(false);
          setSuccessMember(true);
        }
        return;
      }
      setLoadingMember(true);

      const provider = new WsProvider(process.env.REACT_APP_CHAIN_ADDRESS);
      const api = await ApiPromise.create({
        types: {
          UserRole: {
            _enum: ['Admin', 'StakeHolder']
          },
        },
        provider
      });

      // PEGAR TODAS WALLETS
      try {
        await waitReady();
        if (!fezLoad) {
          uiKeyring.loadAll({ ss58Format: 42, type: "sr25519" });
          setFezLoad(true);
        }
      } catch (error) {
        console.log(error)
      }

      const callerUUID = localStorage.getItem("userId") as string;
      let userPair: any

      try {
        userPair = uiKeyring.getPair(user.walletAddress);
      } catch (error) {
        enqueueSnackbar(textError, {
          variant: 'error',
          autoHideDuration: 5000,
        });
        setTimeout(() => {
          history('/profile/wallet/recovery')
        }, 5000);
        setLoadingMember(false);
        setLoadingAuthorization(false);
        return
      }

      try {
        userPair.unlock(passwordAuthorization);

      } catch (error) {
        setLoadingAuthorization(false);
        enqueueSnackbar('Invalid password', {
          variant: "error",
        });
        return
      }

      const orgUUID = idOrg;

      const memberUUID = idMember;

      const genesisHash = api.genesisHash;
      const runtimeVersion = api.runtimeVersion;

      let nonce = await api.rpc.system.accountNextIndex(user.walletAddress);

      let urole = api.createType('UserRole', 'StakeHolder')

      if (permission === "admin") {
        urole = api.createType('UserRole', 'Admin');
      }
      const txMember = api.tx.multiledgersIdentity
        .editMemberRole(callerUUID, orgUUID, memberUUID, urole).sign(userPair, { genesisHash, blockHash: genesisHash, nonce, runtimeVersion });

      const invitedUUID = window.crypto.randomUUID();

      const txInvite = api.tx.multiledgersIdentity
        .addMember(callerUUID, orgUUID, invitedUUID, urole).sign(userPair, { genesisHash, blockHash: genesisHash, nonce, runtimeVersion });

      await api.disconnect();

      const respMember = !idMember
        ? await memberService.inviteMember(
          {
            signedTx: txInvite,
            id: invitedUUID,
            jobTitle,
            email,
            name: firstName + lastName,
            permission,
            organizationId: idOrg,
          },
          tokenUser
        )
        : await memberService.updateMemberById(idMember, idOrg, tokenUser, {
          signedTx: txMember,
          jobTitle,
          phone,
          wallet: "",
          permission,
        });
      setLoadingAuthorization(false);
      if ([200, 201].includes(respMember.status)) {
        if (idMember) {
          enqueueSnackbar('member successful updated', {
            variant: "success",
          });
          setLoadingMember(false);
          setSuccessMember(true);
          setLoadingAuthorization(false);
          handleCloseAuthoraztion();
          handleClose();
          return;
        }
        enqueueSnackbar("Success adding Member", {
          variant: "success",
        });
        setLoadingMember(false);
        setLoadingAuthorization(false);
        setSuccessMember(true);
        handleCloseAuthoraztion();
        handleClose();
      } else {
        if ([401, 403, 404, 500].includes(respMember.status)) {
          enqueueSnackbar(respMember.statusText, {
            variant: "error",
          });
          setLoadingMember(false);
          setLoadingAuthorization(false);
        }
        setSuccessMember(false);
      }
    } catch (error) {
      console.error(error);
      setLoadingMember(false);
      setLoadingAuthorization(false);
    }
    setLoadingMember(false);
    setLoadingAuthorization(false);
  };

  useEffect(() => {
    form.register("firstName");
    form.register("lastName");
    form.register("jobTitle");
    form.register("email");
    form.register("password");
  }, [form.register]);
  return (
    <>
      <DeployAuthorization
        open={openAuthorization}
        loadingAuthorization={loadingAuthorization}
        onSubmitAuthorization={onSubmitAuthorization}
        setPasswordAuthorization={setPasswordAuthorization}
        handleCloseAuthorization={handleCloseAuthoraztion}
        title={!idMemberVerifed ? "EDIT USER" : "ADD A NEW MEMBER"}
        subText={
          !idMemberVerifed
            ? "This action will add the user into organzation or edit the user permission"
            : "This action will authorize the addition of a new member"
        }
        confirm="Sign"
        cancel="Cancel"
      />
      {!memberFound &&
        <>
          <InviteMembers
            tokenUser={tokenUser}
            idOrg={idOrg}
            open={openInviteMember}
            setOpen={setOpenInviteMember}
          />
        </>
      }

      {memberFound &&
        <MemberFormView
          title={title}
          form={form}
          onSubmit={onSubmit}
          handleClose={handleClose}
          handleCloseRemove={handleCloseRemove}
          setOpen={setOpen}
          open={open}
          idOrg={idOrg}
          idMember={idMember}
          memberFound={memberFound}
          idMemberVerifed={idMemberVerifed}
          memberInformation={memberInformation}
          noMatchPass={noMatchPass}
          tokenUser={tokenUser}
          loadingMember={loadingMember}
        />
      }
    </>
  );
};
