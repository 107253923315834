export const LogoHeader: React.FC = () => (
  <svg
    width="61"
    height="35"
    viewBox="0 0 61 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M18.3108 9.7698C18.3108 4.78992 14.2118 0.75293 9.15538 0.75293C4.099 0.75293 0 4.78992 0 9.7698V25.2307C0 30.2106 4.099 34.2476 9.15538 34.2476C14.2118 34.2476 18.3108 30.2106 18.3108 25.2307V9.7698Z" fill="white" />
    <path d="M39.6555 9.7698C39.6555 4.78992 35.5565 0.75293 30.5001 0.75293C25.4437 0.75293 21.3447 4.78992 21.3447 9.7698V25.2307C21.3447 30.2106 25.4437 34.2476 30.5001 34.2476C35.5565 34.2476 39.6555 30.2106 39.6555 25.2307V9.7698Z" fill="white" />
    <path d="M61.0002 9.7698C61.0002 4.78992 56.9012 0.75293 51.8448 0.75293C46.7885 0.75293 42.6895 4.78992 42.6895 9.7698V25.2307C42.6895 30.2106 46.7885 34.2476 51.8448 34.2476C56.9012 34.2476 61.0002 30.2106 61.0002 25.2307V9.7698Z" fill="white" />
  </svg>
);

