import { Box, Grid, Typography } from "@mui/material";
import { useContext } from "react";
import { WizardContext } from "../../../../context/wizardContext";

export const ConfirmationList = () => {
  // @ts-ignore
  const { deployData } = useContext(WizardContext);

  return (
    <>
      <Box
        sx={{
          paddingBottom: "20px",
          paddingTop: "20px",
          borderTop: "1px dashed #000000",
          borderBottom: "1px dashed #000000",
          width: "100%",
        }}
      >
        <Box>
          <Box>
            <Grid container md={12}>
              <Grid item md={4} sx={{ paddingBottom: "9px" }}>
                <Typography
                  fontFamily="Montserrat"
                  fontSize="13px"
                  fontWeight="600"
                  lineHeight="35px"
                  color="#3E474D"
                >
                  Enterprise
                </Typography>
                <Typography
                  fontFamily="Montserrat"
                  fontSize="12px"
                  fontWeight="500"
                  lineHeight="14.63px"
                  color="#5F6368"
                >
                  {deployData.selectedEnterprise}
                </Typography>
              </Grid>
              <Grid item md={4}>
                <Typography
                  fontFamily="Montserrat"
                  fontSize="13px"
                  fontWeight="600"
                  lineHeight="35px"
                  color="#3E474D"
                >
                  Organization
                </Typography>
                <Typography
                  fontFamily="Montserrat"
                  fontSize="12px"
                  fontWeight="500"
                  lineHeight="14.63px"
                  color="#5F6368"
                >
                  {deployData.selectedOrg}
                </Typography>
              </Grid>
              <Grid item md={4}>
                <Typography
                  fontFamily="Montserrat"
                  fontSize="13px"
                  fontWeight="600"
                  lineHeight="35px"
                  color="#3E474D"
                >
                  Network Name
                </Typography>
                <Typography
                  fontFamily="Montserrat"
                  fontSize="12px"
                  fontWeight="500"
                  lineHeight="14.63px"
                  color="#5F6368"
                >
                  {deployData.networkName}
                </Typography>
              </Grid>
              <Grid item md={4}>
                <Typography
                  fontFamily="Montserrat"
                  fontSize="13px"
                  fontWeight="600"
                  lineHeight="35px"
                  color="#3E474D"
                >
                  Protocol
                </Typography>
                <Typography
                  fontFamily="Montserrat"
                  fontSize="12px"
                  fontWeight="500"
                  lineHeight="14.63px"
                  color="#5F6368"
                >
                  Substrate
                </Typography>
              </Grid>
              <Grid item md={4}>
                <Typography
                  fontFamily="Montserrat"
                  fontSize="13px"
                  fontWeight="600"
                  lineHeight="35px"
                  color="#3E474D"
                >
                  Version
                </Typography>
                <Typography
                  fontFamily="Montserrat"
                  fontSize="12px"
                  fontWeight="500"
                  lineHeight="14.63px"
                  color="#5F6368"
                >
                  1.0
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
};
