import { useContext, useEffect, useState } from "react";
import {
  Box,
  Card,
  InputAdornment,
  MenuItem,
  Select,
  styled,
  InputBase,
  Typography,
  type SelectChangeEvent,
} from "@mui/material";
import { WizardContext } from "../../context/wizardContext";

interface IConsensusBeso {
  id: string;
  name: string;
}

export const SelectVMSetupMode = () => {
  // @ts-ignore
  const { deployData, setDeployData } = useContext(WizardContext);
  const [regularNode, setRegularNode] = useState<number>(
    deployData.versionRegular
  );
  const [notarieNode, setNotarieNode] = useState<number>(
    deployData.versionNotarie
  );

  const BootstrapInput = styled(InputBase)(() => ({
    "& .MuiInputBase-input": {
      display: "flex",
      padding: "8px 15px",
      alignItems: "center",
      textAlign: "start",
      borderRadius: "10px",
      backgroundColor: "#FAFAFA",
      border: "1px solid #A2CBFE",
      fontWeight: "500",
      fontFamily: "Montserrat",
      fontSize: "12px",
      color: "#7B8A93",
      letterSpacing: "1px",
      "&:focus": {
        borderRadius: "10px",
      },
    },
    ".MuiSvgIcon-root": {
      right: "10px",
    },
  }));
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 600, // Define a altura máxima do menu
      },
    },
  };

  const SelectRegularNode = () => {
    const handleRegularNode = (e: SelectChangeEvent) => {
      setRegularNode(Number(e.target.value));
      setDeployData({ ...deployData, versionRegular: e.target.value });
    };

    return (
      <Card sx={{ boxShadow: "none", paddingTop: "0px", marginBottom: "5px" }}>
        <Box marginRight={2}>
          <Select
          MenuProps={MenuProps}
            fullWidth
            input={<BootstrapInput />}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            variant="standard"
            type="number"
            // defaultValue={1}
            value={regularNode}
            onChange={(e: any) => handleRegularNode(e)}
            endAdornment={
              <InputAdornment
                position="end"
                sx={{
                  position: "absolute",
                  right: "40px",
                }}
              >
                <span
                  style={{
                    transform: "rotate(180deg)",
                    display: "block",
                    borderRight: "1px solid #A2CBFE",
                    height: "40px",
                  }}
                ></span>
              </InputAdornment>
            }
            sx={{
              minWidth: "168px",
              ":before": {
                borderRight: "1px solid #A2CBFE",
              },
            }}
          >
            {[...Array(100)].map((_, index) => (
              <MenuItem
                key={index + 1}
                value={index + 1}
                sx={{ height: "50px" }}
              >
                {index + 1}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Card>
    );
  };

  const SelectNotariesNode = () => {
    const handleNotariesNode = (e: SelectChangeEvent) => {
      setNotarieNode(Number(e.target.value));
      setDeployData({ ...deployData, versionNotarie: e.target.value });
    };
    return (
      <Card sx={{ boxShadow: "none", paddingTop: "0px", marginBottom: "5px" }}>
        <Box marginRight={2}>
          <Select
            MenuProps={MenuProps}
            fullWidth
            input={<BootstrapInput />}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            variant="standard"
            type="number"
            // defaultValue={1}
            value={notarieNode}
            onChange={(e: any) => handleNotariesNode(e)}
            sx={{
              minWidth: "168px",
              ":before": {
                borderRight: "1px solid #A2CBFE",
              },
            }}
            endAdornment={
              <InputAdornment
                position="end"
                sx={{
                  position: "absolute",
                  right: "40px",
                }}
              >
                <span
                  style={{
                    transform: "rotate(180deg)",
                    display: "block",
                    borderRight: "1px solid #A2CBFE",
                    height: "40px",
                  }}
                ></span>
              </InputAdornment>
            }
          >
            {[...Array(100)].map((_, index) => (
              <MenuItem
                key={index + 1}
                value={index + 1}
              >
                {index + 1}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Card>
    );
  };

  const SelectDoormanNode = () => {
    return (
      <Card sx={{ boxShadow: "none", paddingTop: "0px", marginBottom: "5px" }}>
        <Box marginRight={2}>
          <Select
            disabled
            MenuProps={MenuProps}
            fullWidth
            input={<BootstrapInput />}
            style={{ opacity: '.6' }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            variant="standard"
            type="number"

            defaultValue='1'
            endAdornment={
              <InputAdornment
                position="end"
                sx={{
                  position: "absolute",
                  right: "40px",
                }}
              >
                <span
                  style={{
                    transform: "rotate(180deg)",
                    display: "block",
                    borderRight: "1px solid #A2CBFE",
                    height: "40px",
                  }}
                ></span>
              </InputAdornment>
            }
            sx={{
              minWidth: "168px",
              ":before": {
                borderRight: "1px solid #A2CBFE",
              },
            }}
          >
              <MenuItem value="1">{'1'} </MenuItem>
          </Select>
        </Box>
      </Card>
    );
  };

  return (
    <Box width="100%" display="flex" flexDirection="column">
      <Box alignSelf="flex-end">
        <Box display="flex" justifyContent="flex-end" padding={0} margin={0}>
          <Typography
            paddingRight="10px"
            paddingTop="10px"
            fontFamily="Montserrat"
            fontSize="13px"
            fontWeight="600"
            fontStyle="normal"
          >
            Select the number of Regular Nodes
          </Typography>
          <SelectRegularNode />
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          padding={0}
          margin={0}
        >
          <Typography
            justifyItems="flex-start"
            justifySelf="flex-start"
            paddingRight="10px"
            paddingTop="10px"
            fontFamily="Montserrat"
            fontSize="13px"
            fontWeight="600"
            fontStyle="normal"
          >
            Select the number of Notaries
          </Typography>
          <SelectNotariesNode />
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          padding={0}
          margin={0}
        >
          <Typography
            justifyItems="flex-start"
            justifySelf="flex-start"
            paddingRight="10px"
            paddingTop="10px"
            fontFamily="Montserrat"
            fontSize="13px"
            fontWeight="600"
            fontStyle="normal"
          >
            Select the number of Doorman
          </Typography>
          <SelectDoormanNode />
        </Box>
      </Box>
    </Box>
  );
};
