export const AddSStandAlone: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.5 12.5H12.5V18.5H10.5V12.5H4.5V10.5H10.5V4.5H12.5V10.5H18.5V12.5Z"
      fill="black"
      fill-opacity="0.45"
    />
  </svg>
);
