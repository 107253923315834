import { useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import { Box, DialogTitle, Divider, Typography } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import { CloseModal } from '~/assets';
import { AddOrganization } from './add-organization';
import { UseFormReturn } from 'react-hook-form';

interface IOrginformation {
  name: string;
  email: Array<string>;
  sponsorName: Array<string>;
  country: string;
  state: string;
  city: string;
}

export type TModal = {
  title?: string;
  form: UseFormReturn<any>; // mudar o type
  onSubmit: () => void;
  orgInformation?: IOrginformation;
  setAddInformationOrg?: any;
  setOpen: (value: boolean) => void;
  openEdit?: boolean;
  removeOrganization?: boolean;
  open: boolean;
  handleClose: any;
  idOrg?: string;
  countries: Array<any>;
  states: Array<any>;
  setCountyOrg: (value: string) => void;
  countyOrg: string;
  setStateOrgSelect: (value: string) => void;
  stateOrgSelect: string;
  dataCities: Array<any>;
  setCityOrgSelect: (value: string) => void;
  cityOrgSelect: string;
  tokenUser: string;
  loadingOrg: boolean;
};

export const OrgFormView = ({
  title,
  form,
  onSubmit,
  open,
  handleClose,
  openEdit,
  idOrg,
  orgInformation,
  countries,
  setCountyOrg,
  setStateOrgSelect,
  stateOrgSelect,
  states,
  countyOrg,
  dataCities,
  setCityOrgSelect,
  cityOrgSelect,
  tokenUser,
  loadingOrg,
}: TModal) => {

  const defaultValue = {
    name: '',
    email: [''],
    sponsor: [''],
  }


  useEffect(() => {
    if (open && !idOrg) {
      form.reset(defaultValue);
      setCountyOrg('Brazil');
      setStateOrgSelect('');
      setCityOrgSelect('');
    }
    if (orgInformation && openEdit) {
      // Improve this part!
      //Remove the first 4 letters of org name "org-" from "org-XXXX"
      orgInformation.name = orgInformation.name;
      form.setValue('name', orgInformation?.name);
      form.setValue('email', orgInformation?.email || '');
      form.setValue('sponsor', orgInformation?.sponsorName || '');
      setCountyOrg(orgInformation?.country || 'Brazil')
      setStateOrgSelect(orgInformation?.state || '')
      setCityOrgSelect(orgInformation.city || '')
    }

  }, [orgInformation])

  return (
    <Dialog
      aria-describedby="dialogo de confirmação"
      fullWidth
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: {
          backgroundColor: '#F1F3F4',
          borderRadius: '18px',
        },
      }}
    >
      <DialogTitle
        sx={{
          '& .MuiTypography-root': {
            fontFamily: 'Montserrat',
            fontWeight: '500',
            fontSize: '25px',
            color: '#5F6368',
          }
        }}>
        <Typography fontWeight={600} fontSize={25} alignSelf="center">
          {title}
        </Typography>
      </DialogTitle>
      <Box
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: '16px',
          top: '20px',
          cursor: 'pointer',
          padding: '8px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CloseModal />
      </Box>
      <Divider />
      <DialogContent>
        <AddOrganization
          form={form}
          onSubmit={onSubmit}
          openEdit={openEdit}
          idOrg={idOrg}
          countries={countries}
          states={states}
          setCountyOrg={setCountyOrg}
          countyOrg={countyOrg}
          setStateOrgSelect={setStateOrgSelect}
          stateOrgSelect={stateOrgSelect}
          dataCities={dataCities}
          setCityOrgSelect={setCityOrgSelect}
          cityOrgSelect={cityOrgSelect}
          tokenUser={tokenUser}
          loadingOrg={loadingOrg}
        />
      </DialogContent>
    </Dialog>
  );
};
