export const FilterRange: React.FC = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.025 5.81496H2.24244C2.10407 5.81496 1.97136 5.75999 1.87352 5.66214C1.77567 5.5643 1.7207 5.43159 1.7207 5.29322C1.7207 5.15485 1.77567 5.02214 1.87352 4.9243C1.97136 4.82645 2.10407 4.77148 2.24244 4.77148H9.025C9.16338 4.77148 9.29608 4.82645 9.39393 4.9243C9.49177 5.02214 9.54674 5.15485 9.54674 5.29322C9.54674 5.43159 9.49177 5.5643 9.39393 5.66214C9.29608 5.75999 9.16338 5.81496 9.025 5.81496ZM13.7206 5.81496H11.6337C11.4953 5.81496 11.3626 5.75999 11.2648 5.66214C11.1669 5.5643 11.1119 5.43159 11.1119 5.29322C11.1119 5.15485 11.1669 5.02214 11.2648 4.9243C11.3626 4.82645 11.4953 4.77148 11.6337 4.77148H13.7206C13.859 4.77148 13.9917 4.82645 14.0896 4.9243C14.1874 5.02214 14.2424 5.15485 14.2424 5.29322C14.2424 5.43159 14.1874 5.5643 14.0896 5.66214C13.9917 5.75999 13.859 5.81496 13.7206 5.81496Z"
      fill="#7B8A93"
    />
    <path
      d="M10.3293 7.11797C9.96818 7.11797 9.61513 7.01087 9.31483 6.81022C9.01453 6.60957 8.78048 6.32438 8.64227 5.99071C8.50406 5.65703 8.4679 5.28987 8.53836 4.93565C8.60882 4.58142 8.78273 4.25605 9.03811 4.00067C9.2935 3.74529 9.61887 3.57137 9.97309 3.50091C10.3273 3.43045 10.6945 3.46661 11.0282 3.60482C11.3618 3.74303 11.647 3.97709 11.8477 4.27738C12.0483 4.57768 12.1554 4.93073 12.1554 5.2919C12.1554 5.7762 11.963 6.24067 11.6206 6.58313C11.2781 6.92558 10.8137 7.11797 10.3293 7.11797ZM10.3293 4.50929C10.1746 4.50929 10.0233 4.55519 9.89455 4.64119C9.76585 4.72718 9.66555 4.84941 9.60631 4.99241C9.54708 5.13541 9.53158 5.29277 9.56178 5.44458C9.59198 5.59639 9.66651 5.73583 9.77596 5.84528C9.88541 5.95473 10.0249 6.02927 10.1767 6.05946C10.3285 6.08966 10.4858 6.07416 10.6288 6.01493C10.7718 5.9557 10.8941 5.85539 10.9801 5.72669C11.066 5.59799 11.1119 5.44668 11.1119 5.2919C11.1119 5.08434 11.0295 4.88528 10.8827 4.73851C10.736 4.59175 10.5369 4.50929 10.3293 4.50929ZM13.7206 11.031H6.93806C6.79969 11.031 6.66698 10.976 6.56914 10.8782C6.47129 10.7803 6.41633 10.6476 6.41633 10.5093C6.41633 10.3709 6.47129 10.2382 6.56914 10.1403C6.66698 10.0425 6.79969 9.98752 6.93806 9.98752H13.7206C13.859 9.98752 13.9917 10.0425 14.0896 10.1403C14.1874 10.2382 14.2424 10.3709 14.2424 10.5093C14.2424 10.6476 14.1874 10.7803 14.0896 10.8782C13.9917 10.976 13.859 11.031 13.7206 11.031ZM4.32938 11.031H2.24244C2.10407 11.031 1.97136 10.976 1.87352 10.8782C1.77567 10.7803 1.7207 10.6476 1.7207 10.5093C1.7207 10.3709 1.77567 10.2382 1.87352 10.1403C1.97136 10.0425 2.10407 9.98752 2.24244 9.98752H4.32938C4.46776 9.98752 4.60046 10.0425 4.69831 10.1403C4.79615 10.2382 4.85112 10.3709 4.85112 10.5093C4.85112 10.6476 4.79615 10.7803 4.69831 10.8782C4.60046 10.976 4.46776 11.031 4.32938 11.031Z"
      fill="#7B8A93"
    />
    <path
      d="M5.63321 12.3357C5.27204 12.3357 4.91899 12.2286 4.61869 12.028C4.3184 11.8273 4.08434 11.5422 3.94613 11.2085C3.80792 10.8748 3.77176 10.5076 3.84222 10.1534C3.91268 9.7992 4.08659 9.47382 4.34197 9.21844C4.59736 8.96306 4.92273 8.78914 5.27696 8.71868C5.63118 8.64822 5.99834 8.68439 6.33201 8.8226C6.66569 8.96081 6.95088 9.19486 7.15153 9.49516C7.35218 9.79545 7.45928 10.1485 7.45928 10.5097C7.45928 10.994 7.26689 11.4584 6.92444 11.8009C6.58198 12.1434 6.11751 12.3357 5.63321 12.3357ZM5.63321 9.72707C5.47842 9.72707 5.32711 9.77297 5.19841 9.85896C5.06972 9.94495 4.96941 10.0672 4.91017 10.2102C4.85094 10.3532 4.83544 10.5105 4.86564 10.6623C4.89584 10.8142 4.97037 10.9536 5.07982 11.0631C5.18927 11.1725 5.32872 11.247 5.48053 11.2772C5.63234 11.3074 5.78969 11.2919 5.93269 11.2327C6.0757 11.1735 6.19792 11.0732 6.28392 10.9445C6.36991 10.8158 6.41581 10.6645 6.41581 10.5097C6.41581 10.3021 6.33336 10.1031 6.18659 9.95629C6.03982 9.80952 5.84076 9.72707 5.63321 9.72707Z"
      fill="#7B8A93"
    />
  </svg>
);
