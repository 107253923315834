import { GenericExtrinsic } from '@polkadot/types';
import { AnyTuple } from '@polkadot/types-codec/types';
import axios from 'axios';
import Api from './api/axios-config'

export type TOrganizationService = {
  signedTx:  GenericExtrinsic<AnyTuple>,
  id: string,
  enterpriseId: string,
  name: string,
  organizationId?: string,
  sponsor: string,
  email: string,
  country: string,
  state: string,
  city: string,
};

const returnError = (error: any) => {
  if (error.response){
    const { statusCode, message } = error.response.data;
    return { status: statusCode, statusText: message, data: [] }
  }else{
    return { status: 500, statusText: error.code, data: [] }
  }
}

export class OrganizationService {
  async setOrganization(organization: TOrganizationService, tokenId: string) {
    try {
      const respOrg = await Api(tokenId).post(`/organization`, organization);
      return respOrg;
    } catch (error: any) {
      return returnError(error);
    }
  };

  async getOrganizationNetworks(orgId: string, token: string) {
    return Api(token).get(`/resourceGroup/organization/${orgId}`);
  }

  async getOrganizationInfo(idOrg: string, tokenId: string) {
    try {
      const result = await Api(tokenId).get(`/organization/${idOrg}`);

      return result;
    } catch (error) {
      return { data: [], error }

    }
  };

  async updateOrganizationById(
    idOrg: string,
    organization: TOrganizationService,
    tokenId: string
  ) {
    try {
      const respUpdateORg = await Api(tokenId).put(`/organization/${idOrg}`, organization);
      return respUpdateORg;
    } catch (error: any) {
      return returnError(error);
    }
  };

  async deleteOrganizationById(id: string, signedTx: GenericExtrinsic<AnyTuple>, tokenId: string) {
    try {
      const respDeletOrg = await Api(tokenId).delete(`/organization`, {data: {signedTx, id} });
      return respDeletOrg;
    } catch (error) {
      return returnError(error);

    }
  };

  async getCountries() {
    return axios.get(`https://countriesnow.space/api/v0.1/countries`);
  }

  async getSates(country: string) {
    return axios.post(`https://countriesnow.space/api/v0.1/countries/states`, {
      country
    });
  }

  async getCities(country: string, state: string) {
    return axios.post(`https://countriesnow.space/api/v0.1/countries/state/cities`, {
      country,
      state,
    });
  }
};
