export const Delete: React.FC = () => (
  <svg
    width="11"
    height="11"
    viewBox="0 0 11 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.2514 5.49944L10.06 0.959598C10.1238 0.884152 10.0702 0.769531 9.97149 0.769531H8.81368C8.74548 0.769531 8.68019 0.8 8.63522 0.852232L5.49403 4.59699L2.35285 0.852232C2.30932 0.8 2.24403 0.769531 2.17439 0.769531H1.01658C0.917918 0.769531 0.864235 0.884152 0.928074 0.959598L4.73667 5.49944L0.928074 10.0393C0.913773 10.0561 0.904599 10.0767 0.90164 10.0985C0.89868 10.1204 0.90206 10.1427 0.911379 10.1627C0.920697 10.1827 0.935563 10.1996 0.95421 10.2114C0.972858 10.2233 0.994504 10.2295 1.01658 10.2294H2.17439C2.24258 10.2294 2.30787 10.1989 2.35285 10.1467L5.49403 6.4019L8.63522 10.1467C8.67874 10.1989 8.74403 10.2294 8.81368 10.2294H9.97149C10.0702 10.2294 10.1238 10.1147 10.06 10.0393L6.2514 5.49944Z"
      fill="#FF4D4F"
    />
  </svg>
);
