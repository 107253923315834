import {
  Box,
  Card,
  FormHelperText,
  Grid,
  InputAdornment,
  InputBase,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  // Tooltip,
  Typography,
  styled,
} from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { country } from "../../data/country";
import { useSnackbar } from "notistack";

import { ports } from "../../ports";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

import { WizardContext } from "../../context/wizardContext";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import Checkbox from "@material-ui/core/Checkbox";

const BootstrapInput = styled(InputBase)(() => ({
  "& .MuiInputBase-input": {
    display: "flex",
    padding: "3px 15px",
    alignItems: "center",
    textAlign: "start",
    borderRadius: "10px",
    backgroundColor: "#FAFAFA",
    border: "1px solid #A2CBFE",
    fontWeight: "500",
    fontFamily: "Montserrat",
    fontSize: "12px",
    color: "#7B8A93",
    letterSpacing: "1px",
    "&:focus": {
      borderRadius: "10px",
    },
  },
  ".MuiSvgIcon-root": {
    right: "10px",
  },
}));

export const ProtocolSetup = () => {
  const { enqueueSnackbar } = useSnackbar();
  // @ts-ignore
  const { deployData, setDeployData } = useContext(WizardContext);

  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [touched, setTouched] = useState(false);
  const [sameValues, setSameValues] = useState(false);
  const [errorSameValue, setErrorSameValue] = useState(false);
  const [errorPort, setErrorPort] = useState({
    portAdmin: false,
    portRpc: false,
    portSsdh: false,
    p2pPort: false,
  });

  const countryList = country[0];

  const CustomTooltip = styled(({ className, ...props }: any) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#2A2B2E", // Alterar para a cor de fundo desejada
      color: "#fff", // Alterar para a cor do texto desejada
      maxWidth: 220,
      borderRadius: "13px",
      backdropFilter: "blur(4.33707857131958px)",
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }));

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 500, // Define a altura máxima do menu
      },
    },
  };

  const tooltipText = (
    <Box
      sx={{
        fontFamily: "Montserrat",
        color: "#F5F5F5",
        fontSize: "10px",
        lineHeight: "12px",
      }}
    >
      <p
        style={{
          fontWeight: "bold",
          margin: "0px",
          padding: "0px",
        }}
      >
        Notary validating true:
      </p>
      <span>
        Greater security, lower performance.
        <br />
        Used in high-trust environments, complex contracts, or with regulatory
        requirements.
      </span>
      <p
        style={{
          fontWeight: "bold",
          margin: "0px",
          padding: "0px",
        }}
      >
        Notary validating false:
      </p>
      <span>
        Greater performance, lower security.
        <br />
        Used in environments that prioritize velocity with simple contracts or
        where validation is performed by other means.
      </span>
    </Box>
  );

  const clearAllvm = () => {
    <Box
      sx={{
        fontFamily: "Montserrat",
        color: "#F5F5F5",
        fontSize: "10px",
        lineHeight: "12px",
      }}
    >
      <span>
        Greater security, lower performance.
        <br />
        Used in high-trust environments, complex contracts, or with regulatory
        requirements.
      </span>
      <p
        style={{
          fontWeight: "bold",
          margin: "0px",
          padding: "0px",
        }}
      >
        Notary validating false:
      </p>
      <span>
        Greater performance, lower security.
        <br />
        Used in environments that prioritize velocity with simple contracts or
        where validation is performed by other means.
      </span>
    </Box>;
  };

  const handleCountry = (event: SelectChangeEvent) => {
    setDeployData({
      ...deployData,
      nodeCountry: event.target.value,
    });
  };

  useEffect(() => {
    const {
      portRpcAdmin,
      portRpc,
      dbPassword,
      rpcPassword,
      portP2P,
      dbUsername,
      rpcUsername,
      nodeCity,
      nodeCountry,
      trustStorePassword,
      keyStorePassword,
      sshdPort,
    } = deployData;
    if (
      portRpcAdmin &&
      portRpc &&
      dbPassword &&
      rpcPassword &&
      portP2P &&
      dbUsername &&
      rpcUsername &&
      nodeCity &&
      nodeCountry &&
      trustStorePassword &&
      keyStorePassword &&
      sshdPort
    ) {
      if (
        portP2P === portRpc ||
        portP2P === portRpcAdmin ||
        portRpc === portRpcAdmin
      ) {
        setDeployData({
          ...deployData,
          buttonColor: "#7b8a93",
          protocolSetup: false,
          sameValues: true,
        });
        setErrorSameValue(true);
        return;
      }
      setErrorSameValue(false);
      setDeployData({
        ...deployData,
        protocolSetup: true,
        machineSetup: false,
        buttonColor: "#1976d2",
        sameValues: false,
      });
    } else {
      setDeployData({
        ...deployData,
        protocolSetup: false,
        machineSetup: false,
        sameValues: false,
      });
      return;
    }
    if (
      errorPort.p2pPort ||
      errorPort.portAdmin ||
      errorPort.portRpc ||
      errorPort.portSsdh
    ) {
      setDeployData({
        ...deployData,
        buttonColor: "#7b8a93",
        protocolSetup: false,
        sameValues: true,
      });
      return;
    } else {
      setDeployData({
        ...deployData,
        protocolSetup: true,
        machineSetup: false,
        buttonColor: "#1976d2",
        sameValues: false,
      });
    }

  }, [
    deployData.portRpcAdmin,
    deployData.portRpc,
    deployData.dbPassword,
    deployData.rpcPassword,
    deployData.portP2P,
    deployData.dbUsername,
    deployData.rpcUsername,
    deployData.nodeCountry,
    deployData.nodeCity,
    deployData.trustStorePassword,
    deployData.validateTrustStore,
    deployData.keyStorePassword,
    deployData.sshdPort,
  ]);

  useEffect(() => {
    setIsChecked(deployData.validateNotary);
  }, [deployData.validateNotary]);

  const notaryValidating = (event: ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
    setDeployData({ ...deployData, validateNotary: event.target.checked });
  };

  const clearInputs = () => {
    if (deployData.clearAll) {
      setDeployData({
        ...deployData,
        clearAll: false,
      });
    } else {
      setDeployData({
        ...deployData,
        clearAll: true,
      });
    }
  };

  const validatePortP2p = (portP2P: string) => {
    const isPortP2PIncluded = ports.includes(portP2P);

    setErrorPort({
      portAdmin: errorPort.portAdmin,
      portRpc: errorPort.portRpc,
      portSsdh: errorPort.portSsdh,
      p2pPort: isPortP2PIncluded,
    });
    if (portP2P) {
      portP2P = portP2P.replace(/\D/g, "");
      return portP2P;
    }
  };

  const validateNodeCity = (nodeCity: string) => {
    if (nodeCity) {
      nodeCity = nodeCity.replace(/[^a-zA-Z\s]/g, "");
      return nodeCity;
    }
  };

  const validateRpcPassword = (rpcPassword: string) => {
    if (rpcPassword) {
      rpcPassword = rpcPassword.replace(/\s/g, "");
      return rpcPassword;
    }
  };

  const validateDbPassword = (dbPassword: string) => {
    if (dbPassword) {
      dbPassword = dbPassword.replace(/\s/g, "");
      return dbPassword;
    }
  };

  const validatePortRpc = (portRPC: string) => {
    const isPortRpcIncluded = ports.includes(portRPC);

    setErrorPort({
      portAdmin: errorPort.portAdmin,
      portRpc: isPortRpcIncluded,
      portSsdh: errorPort.portSsdh,
      p2pPort: errorPort.p2pPort,
    });
    if (portRPC) {
      portRPC = portRPC.replace(/\D/g, "");
      return portRPC;
    }
  };

  const validatePortRpcAdmin = (portRpcAdmin: string) => {
    const isPortRpcAdminIncluded = ports.includes(portRpcAdmin);

    setErrorPort({
      portAdmin: isPortRpcAdminIncluded,
      portRpc: errorPort.portRpc,
      portSsdh: errorPort.portSsdh,
      p2pPort: errorPort.p2pPort,
    });
    if (portRpcAdmin) {
      portRpcAdmin = portRpcAdmin.replace(/\D/g, "");
      return portRpcAdmin;
    }
  };

  const validateSshdPort = (sshdPort: string) => {
    const isPortsshdPortIncluded = ports.includes(sshdPort);

    setErrorPort({
      portAdmin: errorPort.portAdmin,
      portRpc: errorPort.portRpc,
      portSsdh: isPortsshdPortIncluded,
      p2pPort: errorPort.p2pPort,
    });
    if (sshdPort) {
      sshdPort = sshdPort.replace(/\D/g, "");
      return sshdPort;
    }
  };

  const validateFields = (rpcUsername?: string, dbUsername?: string) => {
    if (rpcUsername) {
      rpcUsername = rpcUsername
        .replace(" ", "")
        .replace(/[^\w\s]/gi, "")
        .replace("_", "");
      return rpcUsername;
    }
    if (dbUsername) {
      dbUsername = dbUsername
        .replace(" ", "")
        .replace(/[^\w\s]/gi, "")
        .replace("_", "");
      return dbUsername;
    }
  };

  const validateKeyStore = (keyStorePassword: string) => {
    if (keyStorePassword) {
      keyStorePassword = keyStorePassword.replace(/\s/g, "");
      return keyStorePassword;
    }
  };

  const validateTrustStore = (trustStorePassword: string) => {
    if (trustStorePassword) {
      trustStorePassword = trustStorePassword.replace(/\s/g, "");
      return trustStorePassword;
    }
  };

  return (
    <Card
      sx={{
        height: "300px",
        boxShadow: "none",
        marginBottom: "9px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Grid container md={12} columnGap={6} flexWrap="nowrap">
        <Grid item md={5.5}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              fontFamily="Montserrat"
              fontWeight="600"
              color="#3E474D"
              marginRight="33px"
              fontSize={"10px"}
              sx={{
                textAlign: "left",
                wordWrap: "break-word",
              }}
            >
              Node Country
            </Typography>
            <Select
              MenuProps={MenuProps}
              fullWidth
              input={<BootstrapInput />}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              variant="standard"
              type="number"
              value={deployData.nodeCountry}
              onChange={(e: any) => handleCountry(e)}
              endAdornment={
                <InputAdornment
                  position="end"
                  sx={{
                    position: "absolute",
                    right: "30px",
                  }}
                >
                  <span
                    style={{
                      transform: "rotate(180deg)",
                      display: "block",
                      borderRight: "1px solid #A2CBFE",
                      height: "30px",
                    }}
                  ></span>
                </InputAdornment>
              }
              sx={{
                width: "124px",
                ":before": {
                  borderRight: "1px solid #A2CBFE",
                  height: "30px",
                },
              }}
            >
              {Object.entries(countryList).map(([code, name]) => (
                <MenuItem key={code} value={code}>
                  {code}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box
            display="flex"
            marginTop="15px"
            alignItems="center"
            justifyContent="center"
          >
            <Typography
              fontFamily="Montserrat"
              fontWeight="600"
              color="#3E474D"
              marginRight="25px"
              fontSize={"10px"}
              sx={{
                textAlign: "left",
                wordWrap: "break-word",
              }}
            >
              RPC Username
            </Typography>
            <Box>
              <OutlinedInput
                placeholder="rpc username"
                type="string"
                value={deployData.rpcUsername}
                onChange={(e) =>
                  setDeployData({
                    ...deployData,
                    rpcUsername: validateFields(e.target.value),
                  })
                }
                sx={{
                  width: "114px",
                  height: "30px",
                  border: "none",
                  borderRadius: "10px",
                  padding: "0",
                  boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
                }}
              />
              {/* {!deployData.rpcUsername && (
                <FormHelperText error>{"typing username"}</FormHelperText>
              )} */}
            </Box>
          </Box>

          <Box
            display="flex"
            marginTop="15px"
            alignItems="center"
            justifyContent="center"
          >
            <Typography
              fontFamily="Montserrat"
              fontWeight="600"
              color="#3E474D"
              fontSize={"10px"}
              marginRight="25px"
              sx={{
                textAlign: "left",
                wordWrap: "break-word",
              }}
            >
              DB Username
            </Typography>
            <Box>
              <OutlinedInput
                // onBlur={() => setTouched(true)}
                placeholder="db name"
                type="string"
                value={deployData.dbUsername}
                onChange={(e) =>
                  setDeployData({
                    ...deployData,
                    dbUsername: validateFields(e.target.value),
                  })
                }
                sx={{
                  width: "114px",
                  height: "30px",
                  border: "none",
                  borderRadius: "10px",
                  padding: "0",
                  boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
                }}
              />
              {/* {!deployData.dbUsername && (
                <FormHelperText error>{"typing dbUsername"}</FormHelperText>
              )} */}
            </Box>
          </Box>
          <Box
            display="flex"
            marginTop="15px"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              fontFamily="Montserrat"
              fontWeight="600"
              color="#3E474D"
              fontSize={"10px"}
              // marginRight="20px"
              sx={{
                textAlign: "left",
                wordWrap: "break-word",
              }}
            >
              {"P2P Port"}
            </Typography>
            <Box>
              <OutlinedInput
                // onBlur={() => setTouched(true)}
                placeholder="port p2p"
                type="string"
                value={deployData.portP2P}
                onChange={(e) =>
                  setDeployData({
                    ...deployData,
                    portP2P: validatePortP2p(e.target.value),
                  })
                }
                sx={{
                  width: "114px",
                  height: "30px",
                  border: "none",
                  borderRadius: "10px",
                  padding: "0",
                  boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
                }}
              />
              {errorSameValue && (
                <FormHelperText error>{"type different ports"}</FormHelperText>
              )}
              {errorPort.p2pPort && (
                <FormHelperText error>{"port not permitted"}</FormHelperText>
              )}
            </Box>
          </Box>

          <Box
            display="flex"
            alignItems="flex-start"
            justifyContent="space-between"
            marginTop="15px"
          >
            <Typography
              fontFamily="Montserrat"
              fontWeight="600"
              color="#3E474D"
              marginRight="25px"
              fontSize={"10px"}
              sx={{
                textAlign: "left",
                wordWrap: "break-word",
              }}
            >
              Trust Store Password
            </Typography>
            <Box>
              <OutlinedInput
                placeholder="trust password"
                type="string"
                value={deployData.trustStorePassword}
                onChange={(e) =>
                  setDeployData({
                    ...deployData,
                    trustStorePassword: validateTrustStore(e.target.value),
                  })
                }
                sx={{
                  width: "114px",
                  height: "30px",
                  border: "none",
                  borderRadius: "10px",
                  padding: "0",
                  boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
                }}
              />
              {/* {!deployData.trustStorePassword && (
                <FormHelperText error>{"typing trustStorePassword"}</FormHelperText>
              )} */}
            </Box>
          </Box>

          <Box
            display="flex"
            alignItems="flex-start"
            justifyContent="space-between"
            marginTop="15px"
          >
            <Typography
              fontFamily="Montserrat"
              fontWeight="600"
              color="#3E474D"
              marginRight="25px"
              fontSize={"10px"}
              sx={{
                textAlign: "left",
                wordWrap: "break-word",
              }}
            >
              Key Store Password
            </Typography>
            <Box>
              <OutlinedInput
                placeholder="key password"
                type="string"
                value={deployData.keyStorePassword}
                onChange={(e) =>
                  setDeployData({
                    ...deployData,
                    keyStorePassword: validateKeyStore(e.target.value),
                  })
                }
                sx={{
                  width: "114px",
                  height: "30px",
                  border: "none",
                  borderRadius: "10px",
                  padding: "0",
                  boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
                }}
              />
            </Box>
          </Box>
        </Grid>

        <Grid item md={5.5}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              fontFamily="Montserrat"
              fontWeight="600"
              color="#3E474D"
              marginRight="33px"
              fontSize={"10px"}
              sx={{
                textAlign: "left",
                wordWrap: "break-word",
              }}
            >
              Node City
            </Typography>
            <Box>
              <OutlinedInput
                // onBlur={() => setTouched(true)}
                placeholder="city"
                type="string"
                value={deployData.nodeCity}
                onChange={(e) =>
                  setDeployData({
                    ...deployData,
                    nodeCity: validateNodeCity(e.target.value),
                  })
                }
                sx={{
                  width: "114px",
                  height: "30px",
                  border: "none",
                  borderRadius: "10px",
                  padding: "0",
                  boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
                }}
              />
              {/* {!deployData.nodeCity && (
                <FormHelperText error>{"typing nodeCity"}</FormHelperText>
              )} */}
            </Box>
          </Box>
          <Box
            display="flex"
            marginTop="15px"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              fontFamily="Montserrat"
              fontWeight="600"
              color="#3E474D"
              marginRight="25px"
              fontSize={"10px"}
              sx={{
                textAlign: "left",
                wordWrap: "break-word",
              }}
            >
              {"RPC"}
              <br />
              {"Password"}
            </Typography>
            <Box>
              <OutlinedInput
                // onBlur={() => setTouched(true)}
                placeholder="rpc password"
                type="string"
                value={deployData.rpcPassword}
                onChange={(e) =>
                  setDeployData({
                    ...deployData,
                    rpcPassword: validateRpcPassword(e.target.value),
                  })
                }
                sx={{
                  width: "114px",
                  height: "30px",
                  border: "none",
                  borderRadius: "10px",
                  padding: "0",
                  boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
                }}
              />
              {/* {!deployData.rpcPassword && (
                <FormHelperText error>{"typing rpcPassword"}</FormHelperText>
              )} */}
            </Box>
          </Box>
          <Box
            display="flex"
            marginTop="15px"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              fontFamily="Montserrat"
              fontWeight="600"
              color="#3E474D"
              fontSize={"10px"}
              marginRight="25px"
              sx={{
                textAlign: "left",
                wordWrap: "break-word",
              }}
            >
              {"DB"}
              <br />
              {"Password"}
            </Typography>
            <Box>
              <OutlinedInput
                // onBlur={() => setTouched(true)}
                placeholder="db password"
                type="string"
                value={deployData.dbPassword}
                onChange={(e) =>
                  setDeployData({
                    ...deployData,
                    dbPassword: validateDbPassword(e.target.value),
                  })
                }
                sx={{
                  width: "114px",
                  height: "30px",
                  border: "none",
                  borderRadius: "10px",
                  padding: "0",
                  boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
                }}
              />
              {/* {!deployData.dbPassword && (
                <FormHelperText error>{"typing dbPassword"}</FormHelperText>
              )} */}
            </Box>
          </Box>
          <Box
            display="flex"
            marginTop="15px"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              fontFamily="Montserrat"
              fontWeight="600"
              color="#3E474D"
              fontSize={"10px"}
              // marginRight="20px"
              sx={{
                textAlign: "left",
                wordWrap: "break-word",
              }}
            >
              {"RPC Port"}
            </Typography>
            <Box>
              <OutlinedInput
                // onBlur={() => setTouched(true)}
                placeholder="port rpc"
                type="string"
                value={deployData.portRpc}
                onChange={(e) =>
                  setDeployData({
                    ...deployData,
                    portRpc: validatePortRpc(e.target.value),
                  })
                }
                sx={{
                  width: "114px",
                  height: "30px",
                  border: "none",
                  borderRadius: "10px",
                  padding: "0",
                  boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
                }}
              />
              {errorSameValue && (
                <FormHelperText error>{"type different ports"}</FormHelperText>
              )}
              {errorPort.portRpc && (
                <FormHelperText error>{"port not permitted"}</FormHelperText>
              )}
            </Box>
          </Box>

          <Box
            display="flex"
            marginTop="15px"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              fontFamily="Montserrat"
              fontWeight="600"
              color="#3E474D"
              fontSize={"10px"}
              // marginRight="20px"
              sx={{
                // width: '52px',
                textAlign: "left",
                wordWrap: "break-word",
              }}
            >
              {"RPC-Adm"}
              <br />
              {"Port"}
            </Typography>
            <Box>
              <OutlinedInput
                // onBlur={() => setTouched(true)}
                placeholder="port rpc-admin"
                type="string"
                value={deployData.portRpcAdmin}
                onChange={(e) =>
                  setDeployData({
                    ...deployData,
                    portRpcAdmin: validatePortRpcAdmin(e.target.value),
                  })
                }
                sx={{
                  width: "114px",
                  height: "30px",
                  border: "none",
                  borderRadius: "10px",
                  padding: "0",
                  boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
                }}
              />
              {errorSameValue && (
                <FormHelperText error>{"type different ports"}</FormHelperText>
              )}
              {errorPort.portAdmin && (
                <FormHelperText error>{"port not permitted"}</FormHelperText>
              )}
            </Box>
          </Box>

          <Box
            display="flex"
            marginTop="15px"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              fontFamily="Montserrat"
              fontWeight="600"
              color="#3E474D"
              fontSize={"10px"}
              // marginRight="20px"
              sx={{
                // width: '52px',
                textAlign: "left",
                wordWrap: "break-word",
              }}
            >
              {"SSHD"}
              <br />
              {"Port"}
            </Typography>
            <Box>
              <OutlinedInput
                // onBlur={() => setTouched(true)}
                placeholder="2222"
                type="string"
                value={deployData.sshdPort}
                onChange={(e) =>
                  setDeployData({
                    ...deployData,
                    sshdPort: validateSshdPort(e.target.value),
                  })
                }
                sx={{
                  width: "114px",
                  height: "30px",
                  border: "none",
                  borderRadius: "10px",
                  padding: "0",
                  boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
                }}
              />
              {errorSameValue && (
                <FormHelperText error>{"type different ports"}</FormHelperText>
              )}
              {errorPort.portSsdh && (
                <FormHelperText error>{"port not permitted"}</FormHelperText>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid container gap={5.6} flexWrap="nowrap">
        <Grid item md={5.4}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              onClick={clearInputs}
              fontFamily="Montserrat"
              fontWeight="600"
              color="#E14942"
              fontSize={"13px"}
              sx={{
                cursor: "pointer",
                // height: "30px",
                lineHeight: "40px",
                textAlign: "left",
              }}
            >
              Clear Vm Settings
            </Typography>
            <CustomTooltip
              title={"Type here to clear all vm settings"}
              placement="top"
              arrow
            >
              <InfoOutlinedIcon
                style={{
                  fontSize: 20,
                  color: "#E14942",
                  paddingTop: "3px",
                  paddingLeft: "5px",
                }}
              />
            </CustomTooltip>
          </Box>
        </Grid>
        <Grid item md={5.4}>
          {deployData.setupNotarie && (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box display="flsex" marginTop="15px" alignItems="center">
                <input
                  type="checkbox"
                  checked={isChecked}
                  onChange={notaryValidating}
                  style={{
                    width: "14px",
                    height: "14px",
                    accentColor: "#1890FF",
                  }}
                />
                <Box>
                  <Typography
                    style={{
                      paddingLeft: "17px",
                      fontFamily: "Montserrat",
                      fontStyle: "normal",
                      fontWeight: "600",
                      color: "#3E474D",
                      fontSize: "10px",
                    }}
                  >
                    notary validating
                  </Typography>
                </Box>
              </Box>
              <Box marginTop="15px">
                <CustomTooltip title={tooltipText} placement="top" arrow>
                  <InfoOutlinedIcon
                    style={{
                      fontSize: 20,
                      color: "#0B79E0",
                      paddingTop: "3px",
                      paddingLeft: "5px",
                    }}
                  />
                </CustomTooltip>
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>
    </Card>
  );
};
