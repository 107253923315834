import Api from "./api/axios-config";

const returnError = (error: any) => {
  if (error.response){
    const { statusCode, message } = error.response.data;
    return { status: statusCode, statusText: message, data: [] }
  }else{
    return { status: 500, statusText: error.code, data: [] }
  }
}

interface IDataUser {
  firstName: string;
  lastName: string;
  jobTitle: string;
  phone: string
}

export class UserService {
  async getUserOrgList(USER_ID: string, tokenId: string) {
    return Api(tokenId).get(`/user/${USER_ID}/organization`);
  }

  async getUserEnterpriseList(USER_ID: string, tokenId: string) {
    return Api(tokenId).get(`/user/${USER_ID}/enterprises`);
  }

  async userAreThere(organizationId: string, name: string, tokenId: string) {
    return Api(tokenId).get(`/resourceGroup/${organizationId}/available/${name}`);
  }

  async setUserWallet(wallet: any, tokenId: string) {
    try {
      const respMember = await Api(tokenId).patch(`/user/wallet`, wallet);
      return respMember;
    } catch (error) {
      return returnError(error);
    }
  };
  async updateUser(idUser: string, dataUser: IDataUser, tokenId: string,) {
    try {
      const respMember = await Api(tokenId).patch(`/user/${idUser}`, dataUser);
      return respMember;
    } catch (error) {
      return returnError(error);
    }
  };
}
