import React, { useState } from "react";
import "./enterpriseSignUp.css";
// @ts-ignore
import InitialForm from "./InitialForm.tsx";
// @ts-ignore
import ProgressBar from "./ProgressBar.tsx";
// @ts-ignore
import logo from "~/assets/logo_white.png";
// @ts-ignore
import NewProgressBar from "./NewProgressBar.tsx";
import { AiOutlineArrowLeft } from "react-icons/ai";

function EnterpriseSignUpContainer(props: {
  handleClickCreateAccount: any;
  email: any;
  setEmail: any;
  firstName: any;
  setFirstName: any;
  lastName: any;
  setLastName: any;
  phone: any;
  setPhone: any;
}) {
  const [signUpStep, setSignUpStep] = useState(0);
  
  

  const previousHandleClick = () => setSignUpStep(signUpStep - 1);
  const nextHandleClick = () => setSignUpStep(signUpStep + 1);

  return (
    <div className="multiledgers-sign-up-container">
      <div className="internal-container">
        <ProgressBar signUpStep={signUpStep} />
        <InitialForm
          previousHandleClick={previousHandleClick}
          nextHandleClick={nextHandleClick}
          handleClickCreateAccount={props.handleClickCreateAccount}
          signUpStep={signUpStep}
        />
        {signUpStep > 2 && signUpStep <= 6 && (
          <div className="previous-step-div22">
            <AiOutlineArrowLeft/>
            <button onClick={previousHandleClick}>Previous Step</button>
          </div>
    )}
      </div>
    </div>
  );
}

export default EnterpriseSignUpContainer;
