import { Box, Typography } from "@mui/material";
import { LicensesList } from "../../../components/LicenseList/LicensesList";
import { DeployStepHeading } from "../../../components/Platform/Deploy/DeployWizard/WizardSteps/DeployStepHeading";
import { LicenseSelector } from "./LicenseSelector/LicenseSelector";

interface DeployLicenseProps {
  tokenUser: string;
}

export const DeployLicense = ({ tokenUser }: DeployLicenseProps) => {
  return (
    <Box>
      <DeployStepHeading
        title="Licenses"
        description="Select the business template license to start"
      />
      <Box>
        <LicenseSelector />
      </Box>
      <Box>
        <LicensesList tokenUser={tokenUser} />
      </Box>
      <Box sx={{ paddingTop: "80px" }}>
        <Typography
          fontFamily="Montserrat"
          fontSize="12px"
          fontWeight="500"
          lineHeight="15px"
          letterSpacing="1px"
          textAlign="left"
        >
          Still dont have a license? Please click here to get started.
        </Typography>
      </Box>
    </Box>
  );
};
