import { useContext, useEffect, useState } from "react";

import { Box, Grid, Typography } from "@mui/material";
import { DeployBaasStepHeading } from "./DeployBaasStepHeading";
import { WizardContext } from "../../../../../context/wizardContext";
import { SelectCard } from "./SelectCad";
import multiBlack from "../../../../../assets/icons/multiBlack.png";
import multiBlue from "../../../../../assets/icons/multiBlue.png";
import multiWhite from "../../../../../assets/icons/multiWhite.png";
import { ConsensusBeso } from "../../../../ConsensusBeso";
import { SelectVMSetupMode } from "../../../../SelectVMSetupMode";

export const DeployBaasStack = () => {
  //@ts-ignore
  const { deployData, setDeployData } = useContext(WizardContext);

  const [dataSteck, setDataSetck] = useState([
    {
      id: 1,
      title: deployData.protocolType === "besu" ? "Development" : "MVP",
      subTitle:
        deployData.protocolType === "besu"
          ? "For early adopters, validating your ide or for tests"
          : "Small and medium business, secure OP",
      node:
        deployData.protocolType === "besu" ? "1 VM (4 validators)" : "3 nodes",
      setup: "4vCPU - 8GB RAM 50GB DISK",
      price: "", //R$ X,00 /h
      imgSlogan: multiWhite,
      stack: deployData.protocolType === "besu" ? "development" : "mvp",
    },
    {
      id: 2,
      title: deployData.protocolType === "besu" ? "MVP" : "Production",
      subTitle: "Small and medium business, secure OP, BFT",
      node:
        deployData.protocolType === "besu" ? "2 VMs (8 validators)" : "7 nodes",
      setup: "8vCPU - 16GB RAM 300GB DISK",
      price: "",
      imgSlogan: multiBlue,
      stack: deployData.protocolType === "besu" ? "mvp" : "poroduction",
    },
    {
      id: 3,
      title: "Enterprise",
      subTitle: "Large business high security & interoperability",
      node: "Unlimited",
      setup: "Tailor made Stack  Custom",
      price: "",
      imgSlogan: multiBlack,
      stack: "enterprise",
    },
  ]);

  useEffect(() => {
    if (
      deployData.selectedStack === "mvp" ||
      deployData.selectedStack === "enterprise" ||
      (deployData.selectedStack === "development" &&
        deployData.consesus === "QBFT")
    )
      setDeployData({ ...deployData, buttonColor: "#1976d2" });
  }, [deployData.consesus, deployData.selectedStack]);

  return (
    <Box>
      <DeployBaasStepHeading
        title="Stack Selection"
        description="Select an appropriate stack size for your operation"
      />
      <Box>
        <Typography
          fontFamily="Montserrat"
          fontSize="13px"
          fontWeight="600"
          color="#3E474D"
        >
          Group name
        </Typography>
      </Box>
      <Grid container md={12} marginTop="25px" flexWrap="nowrap">
        {dataSteck.map((value) => (
          <SelectCard
            value={value}
            setDeployData={setDeployData}
            deployData={deployData}
          />
        ))}
      </Grid>
      <Grid md={12} marginTop="25px">
        {deployData.protocolType === "besu" ? (
          <ConsensusBeso />
        ) : deployData.selectedStack === "enterprise" && (
          deployData.protocolType === "R3 Corda" && <SelectVMSetupMode />
        )}
      </Grid>
    </Box>
  );
};
