import React, { useEffect, useState } from "react";
import { StyledEnterpriseWalletRegister } from "../../Styles/EnterpriseWalletRegister.styled";
import MultiLogo from "~/assets/logo.png";


function EnterpriseWalletRegister(props: {
  setFormInfo: (arg0: any) => void;
  previousHandleClick: () => void;
  nextHandleClick: () => void;
  formInfo: {
    userFirstName: string;
    userLastName: string;
    userEmail: string;
    userPhone: string;
    userJobTitle: string;
    userCPF: string;
    userPassword: string;
    enterpriseName: string;
    enterpriseCNPJ: string;
    enterpriseZipCode: string;
    entepriseAddress: string;
    entepriseNumber: string;
    entepriseComplement: string;
    entepriseCountry: string;
    entepriseState: string;
    entepriseCity: string;
    paymentMethod: string;
    userWalletAddress: string;
    userWalletName: string;
    userWalletPassword: string;
    enterpriseWalletAddress: string;
    enterpriseWalletName: string;
    enterpriseWalletPassword: string;
};

}) {
  
  const [walletName, setWalletName] = useState(props.formInfo.enterpriseWalletName);
  const [walletPassword, setWalletPassword] = useState("");
  const [confirmWalletPassword, setConfirmWalletPassword] = useState("");
  const [isAllDataFilled, setIsAllDataFilled] = useState(false);

  useEffect(() => {
    if (walletName === ''|| walletPassword === ''|| confirmWalletPassword === ''){
      setIsAllDataFilled(false);
    }
    else {
      setIsAllDataFilled(true);
    }
  },[walletName, walletPassword, confirmWalletPassword])

  const handleSubmit = async (event: { preventDefault: () => void; }) => {

    if (walletPassword !== confirmWalletPassword) {
      alert("Passwords dont match");
      setWalletPassword("");
      setConfirmWalletPassword("");
      return;
    }

    const data = {
      ...props.formInfo,
      "enterpriseWalletName": walletName,
      "enterpriseWalletPassword": walletPassword
    }
    props.setFormInfo(data);
    event.preventDefault(); // 👈️ prevent page refresh
    props.nextHandleClick();
  };

  return (
    <StyledEnterpriseWalletRegister>
      <div className="container-owner-wallet">
        <div className="header">
          <h1 className="header-title">
            Enterprise Wallet Generation
          </h1>
          <h5 className="header-subtitle">
          Please enter a few more details.
          </h5>
        </div>
        <div className="content">
          <h1 className="header-title">YOUR NEW WALLET</h1>
          <input placeholder="Type your wallet name.." type="text" className="content-input"
          onChange={(event) => setWalletName(event.target.value)}
          value={walletName}/>
          <h5 className="header-subtitle">A descriptive name for your wallet.</h5>

          <h1 className="header-title">PASSWORD</h1>
          <input placeholder="Type your password.."  type="password" className="content-input"
          onChange={(event) => setWalletPassword(event.target.value)}
          value={walletPassword}/>
          <h5 className="header-subtitle">A new password for this account.</h5>
          <input placeholder="Repeat your password.." type="password" className="content-input"
          onChange={(event) => setConfirmWalletPassword(event.target.value)}
          value={confirmWalletPassword}/>
          <h5 className="header-subtitle">Repeat password for verification.</h5>

        </div>
        <div className="footer">
        {isAllDataFilled
          ?
          <button onClick={handleSubmit} style={{cursor: "pointer"}}>
          Next Step<img src={MultiLogo} alt="multiLogo" />
          </button>
          :
          <button disabled
          style={{backgroundColor: '#7B8A93', border: '0px', cursor: "not-allowed" }}>
          Next Step<img src={MultiLogo} alt="multiLogo"/>
          </button>
        }
        </div>
      </div>
    </StyledEnterpriseWalletRegister>
  );
}

export default EnterpriseWalletRegister;
