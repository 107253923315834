import React from "react";

import { StyledConclusion } from "../../Styles/Conclusion.styled";

import MultiLogo from "~/assets/logo.png";

function Conclusion(props: {
  previousHandleClick: () => void;
  nextHandleClick: () => void;
  formInfo: {
    userFirstName: string;
    userLastName: string;
    userEmail: string;
    userPhone: string;
    userJobTitle: string;
    userCPF: string;
    userPassword: string;
    userWalletAddress: string;
    userWalletName: string;
    userWalletPassword: string;
};
}) {
  
  const handleSubmit = (event: { preventDefault: () => void; }) => {

    event.preventDefault(); // 👈️ prevent page refresh
    window.location.href = `${process.env.REACT_APP_FRONT_URL_ADDRESS}`;
  };

  return (
    <StyledConclusion>
      <div className="container-owner-wallet">
        <div className="header">
          <h1 className="header-title">
            Success! Your account
            has been created.
          </h1>
          <h5 className="header-subtitle">
          Your details are being validated by our OpTeam, soon your account will ready to go!
          </h5>
        </div>
        <div className="content"></div>
        <div className="footer">
          <button onClick={handleSubmit} style={{cursor: "pointer"}}>
          Access Platform<img src={MultiLogo} alt="multiLogo" />
          </button>
        </div>
      </div>
    </StyledConclusion>
  );
}

export default Conclusion;
