// Core
import { Container, ContainerProps } from '@mui/material';
import React, { FC } from 'react';

// Types
interface IWrapper extends ContainerProps {}

export const Wrapper: FC<IWrapper> = ({ children, ...props }) => {
    return (
        <Container
            maxWidth = { false }
            sx = {{ padding: { xs: '20px', xl: '30px 70px' }}}
            { ...props }>
            {children}
        </Container>
    );
};
