import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useSnackbar } from 'notistack';

import { Box, Card, Typography } from '@mui/material';

import LoadingButton from '@mui/lab/LoadingButton';
import { keyring } from "@polkadot/ui-keyring";
import { cryptoWaitReady, mnemonicValidate } from "@polkadot/util-crypto";
import { UserService } from "../../../services/user.services";

import Wallet from '../../../assets/imgs/walletBackground.png';

export type TMnemonicSelected = {
  tokenUser: string;
  words: Array<any>;
  nameWallet: string;
  walletAddress: string;
  passwordInput: string;
  confirmPasswordInput: string;
  setValidatePassword?: any;
};

export const Mnemonic = ({
  tokenUser,
  words,
  walletAddress,
  nameWallet,
  passwordInput,
  confirmPasswordInput,
  setValidatePassword
}: TMnemonicSelected) => {
  const userService = new UserService();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [openAuthorization, setOpenAuthorization] = useState<boolean>(false);
  const [loadingAuthorization, setLoadingAuthorization] =
    useState<boolean>(false);
  const history = useNavigate();

  const handleCloseAuthoraztion = (value: boolean) => {
    setOpenAuthorization(value);
  };

  const onSubmitAuthorization = async (walletAddr?: string) => {
    if (passwordInput !== confirmPasswordInput) {
      setValidatePassword(true);
      return;
    } else {
      setValidatePassword(false);
    }
  };

  const onSubmit = () => {
    setLoading(true);
    onSubmitAuthorization('');

    const createWallet = async () => {
      if (passwordInput !== confirmPasswordInput) {
        setValidatePassword(true);
        setLoading(false);
        enqueueSnackbar("Passwords do not match", {
          variant: "error",
        });
        return;
      } else {
        setValidatePassword(false);
      }
      const isMnemonicValid = mnemonicValidate(words.join(' '));

      if(!isMnemonicValid){
        setLoading(false);
        enqueueSnackbar("Mnemonic is not validate", {
          variant: "error",
        });
        return
      }
      if (passwordInput !== confirmPasswordInput) {
        setValidatePassword(true);
        setLoading(false);
        enqueueSnackbar("Passwords do not match", {
          variant: "error",
        });
        return;
      } else {
        setValidatePassword(false);
      }

			try {
				await cryptoWaitReady();
				keyring.loadAll({ ss58Format: 42, type: "sr25519" });
			} catch (error){
        console.log(error)
      } finally {
				const key = keyring.addUri(words.join(' '), passwordInput, { name: nameWallet });
				onSubmitAuthorization();
        setLoading(false);
        const wallet = {
          walletAddress: key.pair.address,
          walletName: nameWallet
        }
        const resInfoMember = await userService.setUserWallet(wallet, tokenUser);

        if (resInfoMember.status == 200){
          history('/organizations');
          enqueueSnackbar("Wallet has been recovered", {
            variant: "success",
          });
        }else{
          enqueueSnackbar("Wallet could not been recovered, did you tried to import a diffent Mnemonic?", {
            variant: "error",
          });
        }

			}
		}
		createWallet();
  };

  return (
    <>
      <Card
        sx={{
          backgroundColor: 'transparent',
          boxShadow: 'none',
        }}
      >
        <Box display="flex" alignItems="center" marginBottom="37px">
          <Box marginRight="23px">
            <img src={Wallet} alt="wallet" />
          </Box>
          <Box>
            <Typography
              fontFamily="Montserrat"
              marginBottom="12px"
              fontWeight={700}
              fontSize={'22px'}
              color={'#CAD5E8'}
            >
              {nameWallet}
            </Typography>
            <Typography fontSize="1.25rem" fontFamily="Montserrat" color="#CAD5E8" lineHeight="32px">
              {walletAddress}
            </Typography>
          </Box>
        </Box>

        <Box>
          <Box
            sx={{
              display: 'flex',
              overflow: 'hidden',
              flexWrap: 'wrap',
              alignItems: `${!words.length ? 'center' : 'flex-start'}`,
              justifyContent: `${!words.length ? 'center' : 'auto'}`,
              padding: '28px 50px 22px 50px',
              border: '1px solid #CAD5E8',
              borderRadius: '16px',
              width: '613px',
              height: '254px'
            }}
          >
            {!words.length && (
              <Box display="flex">
                <Typography fontSize="36px" fontWeight="700" color="#a5aab1">
                  Mnemonic
                </Typography>
              </Box>
            )}
            {words.map((value: any) => (
              <Typography
                fontSize="1.75rem"
                paddingRight="32px"
                height="0px"
                fontWeight="700"
                lineHeight="40px"
                color="#CAD5E8"
              >
                {value}
              </Typography>
            ))}
          </Box>
        </Box>
        <Box
          display="flex"
          justifyContent="flex-end"
          marginRight="60px"
          marginTop="96px"
        >
          <LoadingButton
            variant="contained"
            onClick={onSubmit}
            loading={loading}
            disabled={!passwordInput || !confirmPasswordInput || !nameWallet || !words.join(' ')}
            sx={{
              minWidth: '273px',
              padding: '8px 32px',
              borderRadius: '25px',
              boxShadow: 'none',
              background: `${nameWallet}` ? '#00CA95' : '#CAD5E8',
              '&:hover': {
                backgroundColor: `${nameWallet}` ? '#00CA95' : '#CAD5E8'
              }
            }}
          >
            <Typography
              lineHeight="32px"
              fontSize="1.5rem"
              fontWeight="700"
              color="#fff"
              sx={{
                textTransform: 'initial'
              }}
            >
              Recover wallet
            </Typography>
          </LoadingButton>
        </Box>
      </Card>
    </>
  );
};
