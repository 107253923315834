import { Box, Grid, Typography } from "@mui/material";
import { useContext } from "react";
import { SmallBoxSelector } from "../../../../components/selectors/SmallBoxSelector";
import { WizardContext } from "../../../../context/wizardContext";

export const LicenseSelector = () => {
  //@ts-ignore
  const { deployData, setDeployData } = useContext(WizardContext);

  const selectedLicense = deployData.selectedLicense;

  const handleSelectLicense = (license: string) => {
    setDeployData({ ...deployData, buttonColor: "#3E474D" });
    setDeployData({ ...deployData, selectedLicense: license });
  };

  return (
    <>
      <Box>
        <Typography
          fontFamily="Montserrat"
          fontWeight="600"
          color="#3E474D"
          fontSize={"13px"}
          sx={{
            paddingBottom: "12px",
          }}
        >
          Business Template
        </Typography>
      </Box>
      <Grid container md={12}>
        <Grid item md={4}>
          <SmallBoxSelector
            heading="REC"
            handleSelect={() => handleSelectLicense("License 1")}
            selected={selectedLicense === "License 1"}
            enabled={true}
          />
        </Grid>
        <Grid item md={4}>
          <SmallBoxSelector
            heading="Gas REC"
            handleSelect={() => {}}
            selected={selectedLicense === "License 2"}
            enabled={false}
          />
        </Grid>
        <Grid item md={4}>
          <SmallBoxSelector
            heading="CPR Verde"
            handleSelect={() => {}}
            selected={selectedLicense === "License 3"}
            enabled={false}
          />
        </Grid>
      </Grid>
    </>
  );
};
