import React, { FC, useState, useEffect } from "react";
import { keycloak } from "../../../utils/Keycloak";
import { useSnackbar } from "notistack";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { fonts, colors } from "../../../style/themes";
import CollapseData from "../../../components/collapseData";
import { customThinScroll } from "../../../style/scrollBarStyle";
import { FinancialService } from "../../../services/financial.service";
import { removeLocalStorage } from "../../../generalLocalStorage";
import { Skeleton } from "@mui/material";
interface IResources {
  tokenUser: string;
}
const Resources: FC<IResources> = ({ tokenUser }) => {
  const financialService = new FinancialService();
  const { enqueueSnackbar } = useSnackbar();

  const idEnt = localStorage.getItem("idEnterprise") || "";
  const [resourcesValue, setResourcesValue] = useState<any | null>(null);
  const [selectedData, setSelectedData] = useState<any | null>(null);
  const [selectedKit, setSelectedKit] = useState("baas");
  const [loadingData, setLoadingData] = useState<boolean>(false);

  useEffect(() => {
    const getResourceVmBaas = async () => {
      try {
        if (idEnt) {
          if (!tokenUser) return;
          setLoadingData(true);
          const respResourceVmBaas = await financialService.getResourceVm(
            tokenUser,
            idEnt
          );
          if (respResourceVmBaas.status == 200) {
            setLoadingData(false);
            const { data } = respResourceVmBaas;
            const newData: Array<{ [key: string]: any }> = [];

            for (let i = 0; i < data.length; i++) {
              const obj: { [key: string]: any } = {};
              obj["baas"] = data[i];
              newData.push(obj);
            }

            setResourcesValue(newData);
            setSelectedData(newData ? newData : {});
          }
        }
      } catch (error: any) {
        const { statusCode, message } = error?.response?.data;
        setLoadingData(false);
        if ([403, 400, 404].includes(statusCode)) {
          enqueueSnackbar(message, {
            variant: "error",
          });
        }
        if (statusCode === 401) {
          removeLocalStorage();
          keycloak.logout();
        }
        console.error(error);
      }
    };
    getResourceVmBaas();
  }, []);

  useEffect(() => {
    if (resourcesValue) {
      setSelectedData(resourcesValue[selectedKit]);
    }
  }, [selectedKit]);

  return (
    <Paper
      sx={{
        padding: "14px 12px",
        background: "#F1F3F4",
        boxShadow: "none",
        border: "1px solid #CBD5E1",
        borderRadius: "30px",
        height: "345px",
      }}
    >
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ paddingBottom: "30px" }}
      >
        <Grid item lg={6}>
          <Typography
            sx={{
              paddingLeft: "13px",
              fontSize: fonts.size[300],
              fontFamily: fonts.family.secondary,
              fontWeight: fonts.weight.secondary,
              color: colors.secondary[500],
            }}
          >
            RESOUCES
          </Typography>
        </Grid>
        <Grid
          item
          lg={6}
          sx={{
            paddingRight: "70px",
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            gap: "16px",
          }}
        >
          <Typography
            onClick={() => setSelectedKit("baas")}
            sx={{
              fontSize: fonts.size[300],
              fontFamily: fonts.family.secondary,
              fontWeight: fonts.weight.secondary,
              color: colors.secondary[400],
              textAlign: "left",
              cursor: "pointer",
              borderBottom:
                selectedKit === "baas" ? "2px solid #1781E2" : "none",
            }}
          >
            Baas
          </Typography>

          {/* <Typography
            // onClick={() => setSelectedKit('ItemFathe2')}
            sx={{
              fontSize: fonts.size[300],
              fontFamily: fonts.family.secondary,
              fontWeight: fonts.weight.secondary,
              color: "#c4c7ca",
              textAlign: "left",
              cursor: "pointer",
              borderBottom:
                selectedKit === "ItemFathe2" ? "2px solid #1781E2" : "none",
            }}
          >
            Tokens
          </Typography> */}
        </Grid>
      </Grid>
      <Box
        sx={{
          paddingRight: "36px",
          height: "83%",
          overflowY: "auto",
          ...customThinScroll,
        }}
      >
        {loadingData && (
          <>
          <Box marginLeft={4} marginTop={4}>
            <Skeleton
              variant="rectangular"
              sx={{
                width: "100%",
                height: "40px",
                boxSizing: "border-box",
                borderRadius: "3px",
              }}
            />
          </Box>
          </>
        )}
        {!loadingData && selectedData && (
          <CollapseData dataValue={selectedData} selectedKit={selectedKit} />
        )}
      </Box>
    </Paper>
  );
};

export default Resources;
