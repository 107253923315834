export const fonts = {
    family: {
        primary: 'Sen',
        secondary: 'Montserrat',
    },
    size: {
        60: '0.4375rem', // 7px
        70: '0.5rem', // 8px
        80: '0.625rem', // 10px
        100: '0.75rem', // 12px
        200: '0.875rem', // 14px
        300: '1rem', // 16px
        400: '1.125rem', // 18px
        500: '1.25rem', // 20px
        600: '1.5rem', // 24px
        700: '1.75rem', // 28px
        800: '2rem', // 32px
        900: '3rem', // 48px
        1000: '4rem', // 64px
    },
    weight: {
        primary: 400,
        500: 500,
        secondary: 700,
        600: 600
    },
};
