import Dialog from "@mui/material/Dialog";
import { Box, DialogTitle, Divider, Grid, Typography } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { CloseModal } from "~/assets";

export type TModalRemoveNetwork = {
  title: string;
  content?: string;
  onSubmit: () => void;
  setOpen: (value: boolean) => void;
  open: boolean;
  idMember?: string;
  idOrg?: string;
  handleCloseRemove: () => void;
  tokenUser: string;
};

export const ModalRemoveNetwork = ({
  title,
  content,
  setOpen,
  onSubmit,
  open,
  idMember,
  idOrg,
  handleCloseRemove,
  tokenUser,
}: TModalRemoveNetwork) => {


  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        aria-describedby="dialogo de confirmação"
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            backgroundColor: "#F1F3F4",
            borderRadius: "18px",
          },
        }}
      >
        <DialogTitle
          sx={{
            "& .MuiTypography-root": {
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "25px",
              color: "#5F6368",
            },
          }}
        >
          <Typography fontWeight={600} fontSize={25} alignSelf="center">
            {title}
          </Typography>
        </DialogTitle>
        <Box
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: "16px",
            top: "10px",
            cursor: "pointer",
            padding: "8px",
            paddingTop: "18px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CloseModal />
        </Box>
        <Divider />
        <DialogContent>
          <Typography
            fontFamily="Montserrat"
            textAlign="center"
            fontWeight="600"
            fontSize="16px"
          >
            {content}
          </Typography>
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
          <Grid container md={12} justifyContent="space-between" padding="23px">
            <Grid item md={4} sm={12}>
              <Button
                sx={{
                  width: "100%",
                  display: "flex",
                }}
                onClick={handleClose}
                variant="contained"
              >
                <Typography
                  fontFamily="Montserrat"
                  fontWeight="300"
                  fontSize="14px"
                >
                  No
                </Typography>
              </Button>
            </Grid>
            <Grid item md={4} sm={12}>
              <Button
                variant="contained"
                onClick={onSubmit}
                sx={{ width: "100%" }}
                color="error"
              >
                <Typography
                  fontFamily="Montserrat"
                  fontWeight="300"
                  fontSize="14px"
                >
                  Yes
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};
