import CustStepper from "../../../../components/Steppers/CustStepper";

interface ProcessStepperProps {
  percent: number;
  error: boolean;
  errorIds: boolean;
  errorTicket: string;
}

export const ProcessStepper = ({
  percent,
  error,
  errorIds,
  errorTicket,
}: ProcessStepperProps) => {
  return (
    <CustStepper
      error={error}
      errorIds={errorIds}
      percent={percent}
      errorTicket={errorTicket}
    />
  );
};
