import { Box, Grid, Typography } from "@mui/material";

interface LicenseItemProps {
  id: string;
  period: string;
  selected: boolean;
  name: string;
  expires: string;
  createdAt: string;
  handleSelect: () => void;
}

export const LicenseItem = ({
  id,
  period,
  selected,
  handleSelect,
  name,
  expires,
  createdAt,
}: LicenseItemProps) => {
  const styles = {
    wrapper: {
      paddingTop: "10px",
    },
    circle: {
      borderRadius: "50px",
      height: "16px",
      width: "16px",
    },
    selectedCircle: {
      backgroundColor: "#d9e8f5",
      border: "1px solid #1781E2",
    },
    unselectedCircle: {
      backgroundColor: "#F5F5F5",
      border: "1px solid #7B8A93",
    },
  };

  return (
    <Grid container md={12} sx={styles.wrapper}>
      <Grid item md={1}>
        <Box
          sx={{
            ...styles.circle,
            ...(selected ? styles.selectedCircle : styles.unselectedCircle),
          }}
          onClick={handleSelect}
        ></Box>
      </Grid>
      <Grid item md={11}>
        <Grid container md={12}>
          <Grid item md={5}>
            <Typography
              fontFamily="Montserrat"
              fontStyle="normal"
              fontWeight="600"
              fontSize="12px"
            >
              ID: {name}
            </Typography>
          </Grid>
          <Grid item md={7}>
            <Typography
              fontFamily="Montserrat"
              fontStyle="normal"
              fontWeight="600"
              fontSize="12px"
            >
              Period: {`${createdAt.slice(0, 10)} - ${expires.slice(0, 10)}`}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
