import { useContext, useEffect, useState } from "react";
import { WizardContext } from "../../context/wizardContext";
import { useForm } from "react-hook-form";
import { OrganizationService } from "../../services/organization.service";
import { OrgFormView } from "./orgFormView";
import { SchemaOrganizationDefault } from "./SchemaOrganization";
import { useNavigate } from 'react-router-dom';

import { yupResolver } from "@hookform/resolvers/yup";
import { useSnackbar } from "notistack";
import { DeployAuthorization } from "../deployAuthorization";

import { ApiPromise, WsProvider } from "@polkadot/api";
import uiKeyring from "@polkadot/ui-keyring";
import { waitReady } from "@polkadot/wasm-crypto";
import { textError } from "../../utils/textError";

export type TOrgFormContainer = {
  title: string;
  open: boolean;
  setOpen: (value: boolean) => void;
  openEdit?: boolean;
  idOrg?: string | any;
  setSuccessOrg?: ((value: boolean) => void) | any;
  successOrg?: boolean;
  enterpriseId: string;
  ownerId: string;
  tokenUser: string;
  user: any;
};

interface IOrgFormContainer {
  id: string;
  name: string;
  serviceAuthId: string;
  sponsorName: Array<string>;
  email: Array<string>;
  country: string;
  state: string;
  city: string;
}

export const OrgFormContainer = ({
  // user,
  title,
  open,
  setOpen,
  idOrg,
  setSuccessOrg,
  successOrg,
  openEdit,
  ownerId,
  tokenUser,
}: // enterpriseId,
  TOrgFormContainer) => {
  const { user } = useContext<any>(WizardContext);
  const [orgInformation, setOrgInformation] = useState<IOrgFormContainer>();
  const [countries, setCountries] = useState<Array<any>>([]);
  const [stateOrg, setStateOrg] = useState<Array<any>>([]);

  const [countyOrg, setCountyOrg] = useState<string>("");
  const [stateOrgSelect, setStateOrgSelect] = useState<string>("");
  const [dataCities, setDataCities] = useState<Array<any>>([]);
  const [cityOrgSelect, setCityOrgSelect] = useState<string>("");
  const [loadingOrg, setLoadingOrg] = useState<boolean>(false);
  const [loadingAuthorization, setLoadingAuthorization] =
    useState<boolean>(false);
  const [openAuthorization, setOpenAuthorization] = useState<boolean>(false);
  const [passwordAuthorization, setPasswordAuthorization] = useState<string>('');

  const [fezLoad, setFezLoad] = useState<boolean>(false);
  const history = useNavigate();
  
  const enterpriseId = localStorage.getItem("entepriseServiceAuthId") || "";

  const { enqueueSnackbar } = useSnackbar();

  const form = useForm<any>({
    resolver: yupResolver(SchemaOrganizationDefault),
  });

  const organizationService = new OrganizationService();

  const getCountriesOrganization = async () => {
    try {
      const respCountries = await organizationService.getCountries();
      if (respCountries.status === 200) {
        const { data } = respCountries.data;
        setCountries(data);
      }
    } catch (error) { }
  };

  const getStatesOrganization = async () => {
    try {
      const respStates = await organizationService.getSates(countyOrg);
      if (respStates.status === 200) {
        const { data } = respStates.data;
        setStateOrg(data.states);
      }
    } catch (error) { }
  };

  const getCities = async () => {
    try {
      const respStates = await organizationService.getCities(
        countyOrg,
        stateOrgSelect
      );
      if (respStates.status === 200) {
        const { data } = respStates.data;
        setDataCities(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (countyOrg) {
      getStatesOrganization();
    }
    setDataCities([]);

  }, [countyOrg]);

  useEffect(() => {
    if (stateOrgSelect) {
      getCities();
    }
  }, [stateOrgSelect]);

  useEffect(() => {
    const getInfOrgById = async () => {
      try {
        const respOrgInformation =
          await organizationService.getOrganizationInfo(idOrg ? idOrg : "", tokenUser);

        const {
          id,
          name,
          serviceAuthId,
          sponsorName,
          email,
          country,
          state,
          city,
        } = respOrgInformation.data;

        if (respOrgInformation) {
          setOrgInformation({
            id,
            name,
            serviceAuthId,
            sponsorName,
            email,
            country,
            state,
            city,
          });
        }
      } catch (error) {
        console.error(error);
      }
    };
    if (open) {
      idOrg && getInfOrgById();
      getCountriesOrganization();
    }
  }, [open]);

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = () => {
    setOpenAuthorization(true);
  };

  const onSubmitAuthorization = async () => {
    setLoadingAuthorization(true);
    const { name, sponsor, email } = form.getValues();

    try {
      setLoadingOrg(true);
      setLoadingAuthorization(true)

      const provider = new WsProvider(process.env.REACT_APP_CHAIN_ADDRESS);
      const api = await ApiPromise.create({
        provider
      });

      // PEGAR TODAS WALLETS
      try {
        await waitReady();
        if (!fezLoad) {
          uiKeyring.loadAll({ ss58Format: 42, type: "sr25519" });
          setFezLoad(true);
        }
      } catch (error) {
        //console.log(error)
      }

      const userUUID = localStorage.getItem("userId") as string;
      const entUUID = localStorage.getItem("enterpriseId") as string;

      let userPair: any;

      try {
        userPair = uiKeyring.getPair(user.walletAddress);
      } catch (error) {
        enqueueSnackbar(textError, {
          variant: 'error',
          autoHideDuration: 5000,
        });
        setTimeout(() => {
          history('/profile/wallet/recovery')
        }, 5000);
        setLoadingOrg(false);
        setLoadingAuthorization(false);
        return
      }

      try {
        userPair.unlock(passwordAuthorization);

      } catch (error) {
        setLoadingAuthorization(false);
        enqueueSnackbar('Invalid password', {
          variant: "error",
        });
        return
      }

      const orgUUID = window.crypto.randomUUID();
      const genesisHash = api.genesisHash;
      const runtimeVersion = api.runtimeVersion;

      let nonce = await api.rpc.system.accountNextIndex(user.walletAddress);
      let txOrg;
      if (!idOrg) {
        txOrg = api.tx.multiledgersIdentity
          .createOrganization(userUUID, entUUID, orgUUID).sign(userPair, { genesisHash, blockHash: genesisHash, nonce, runtimeVersion });
      } else {
        txOrg = api.tx.multiledgersIdentity
          .editOrganizationOffchain(userUUID, idOrg).sign(userPair, { genesisHash, blockHash: genesisHash, nonce, runtimeVersion });
      }
      await api.disconnect();

      const respOrganization = !idOrg
        ? await organizationService.setOrganization(
          // Aqui vai signedTx e id
          {
            id: orgUUID,
            signedTx: txOrg,
            enterpriseId: entUUID,
            name,
            sponsor,
            email,
            country: countyOrg,
            state: stateOrgSelect,
            city: cityOrgSelect,
          },
          tokenUser
        )
        : await organizationService.updateOrganizationById(
          idOrg,
          {
            id: orgUUID,
            signedTx: txOrg,
            enterpriseId: entUUID,
            organizationId: idOrg,
            name,
            sponsor,
            email,
            country: countyOrg,
            state: stateOrgSelect,
            city: cityOrgSelect,
          },
          tokenUser
        );
      if ([400, 401, 403, 500].includes(respOrganization.status)) {
        setLoadingOrg(false);
        setLoadingAuthorization(false);
        enqueueSnackbar(respOrganization.statusText,
          {
            variant: 'error'
          }
        )
      }
      if ([200, 201].includes(respOrganization.status)) {
        // const thereIsOrganization = true
        // localStorage.setItem('thereIsOrganization',  thereIsOrganization.toString())
        enqueueSnackbar("Organization added with success!", {
          variant: "success",
        });
        setLoadingOrg(false);
        setLoadingAuthorization(false);
        setSuccessOrg(true);
        handleCloseAuthoraztion();
        handleClose();
        // window.location.reload()
      }
    } catch (error) {
      setLoadingOrg(false);
      setLoadingAuthorization(false);
      console.error(error);
    }
  };

  const handleCloseAuthoraztion = () => {
    setOpenAuthorization(false);
  };

  useEffect(() => {
    form.register("name");
    form.register("email");
    form.register("sponsor");
  }, [form.register]);

  return (
    <>
      <DeployAuthorization
        open={openAuthorization}
        loadingAuthorization={loadingAuthorization}
        onSubmitAuthorization={onSubmitAuthorization}
        handleCloseAuthorization={handleCloseAuthoraztion}
        setPasswordAuthorization={setPasswordAuthorization}
        title={!openEdit ? "CREATE AN ORGANIZATION" : "EDIT AN ORGANIZATION"}
        subText={
          !openEdit
            ? "This action will authorize the creation of a new organization"
            : "This action will authorize the edition of this organization"
        }
        confirm="Sign"
        cancel="Cancel"
      />
      <OrgFormView
        orgInformation={orgInformation}
        title={title}
        form={form}
        onSubmit={onSubmit}
        handleClose={handleClose}
        setOpen={setOpen}
        openEdit={openEdit}
        open={open}
        idOrg={idOrg}
        countries={countries}
        states={stateOrg}
        setCountyOrg={setCountyOrg}
        countyOrg={countyOrg}
        setStateOrgSelect={setStateOrgSelect}
        stateOrgSelect={stateOrgSelect}
        dataCities={dataCities}
        setCityOrgSelect={setCityOrgSelect}
        cityOrgSelect={cityOrgSelect}
        tokenUser={tokenUser}
        loadingOrg={loadingOrg}
      />
    </>
  );
};
