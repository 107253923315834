import React, { FC } from 'react';

import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { fonts, colors } from '../../../style/themes';

export interface IHost {
  upHosts?: number;
  totalHosts?: number;
  loading: boolean;
}

export const Host: FC<IHost> = ({ upHosts, totalHosts, loading }) => {
  if (loading) {
    return (
      <Paper
        sx={{
          boxSizing: 'border-box',
          boxShadow: 'none',
          padding: '14px 25px',
          background: '#F1F3F4',
          border: '1px solid #CBD5E1',
          borderRadius: '30px',
          height: '254.85px'
        }}
      >
        <Typography
          sx={{
            fontSize: fonts.size[300],
            fontFamily: fonts.family.secondary,
            fontWeight: fonts.weight.secondary,
            color: colors.secondary[500],
            textAlign: 'left',
          }}
        >
          HOSTS
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '80%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              fontSize: fonts.size[1000],
              fontFamily: fonts.family.secondary,
              fontWeight: fonts.weight[600],
              color: colors.primary[800],
            }}
          >
            {`${upHosts || '- '}/${totalHosts || ' -'}`}
          </Typography>
        </Box>
      </Paper>
    );
  } else {
    return (
      <Skeleton
        variant="rectangular"
        sx={{
          width: '100%',
          height: '254.85px',
          boxSizing: 'border-box',
          border: '1px solid #CBD5E1',
          borderRadius: '30px',
        }} />
    )
  }

};