import { useState } from "react";
import { Box, Typography, Card } from "@mui/material";
import { useEffect } from "react";

interface ISelectCard {
  value: any;
  setDeployData: (value: any) => void;
  deployData: any;
}

export const SelectCard = ({
  value,
  setDeployData,
  deployData,
}: ISelectCard) => {
  const [styleStack, setStyleStack] = useState<Object>();
  const [selectedItem, setSelectedItem] = useState<any>();

  const sytleTypeStack = {
    color: "#7f8a94",
    backgroundColor: "#F8F9FA",
    opacity: ".5",
    cursor: "not-allowed",
  };

  const handleItemClick = (idMenu: number, stackType: string, node: string) => {
    if (deployData.protocolType === "besu") {
      if (stackType !== "development") return;
    } else if (deployData.protocolType === "substrate") {
      if (stackType !== "mvp") return;
    } else {
      if (stackType !== "enterprise") return;
    }

    setSelectedItem(idMenu);
    setDeployData({ ...deployData, selectedStack: stackType, valueNode: node });
  };

  useEffect(() => {
    if (deployData.buttonColor === "#1976d2") {
      setDeployData({ ...deployData, buttonColor: "#7b8a93" });
    }
  }, []);

  useEffect(() => {
    if (deployData.selectedStack === "enterprise") setSelectedItem(3);
    if (
      deployData.selectedStack === "mvp" ||
      deployData.selectedStack === "development"
    ) {
      setSelectedItem(1);
    }
    if (deployData.protocolType === "besu") {
      if (value.stack !== "development") {
        setStyleStack(sytleTypeStack);
      }
    } else if (deployData.protocolType === "R3 Corda") {
      if (value.stack !== "enterprise") {
        setStyleStack(sytleTypeStack);
      }


    }  else  {
      if (value.stack !== "mvp") {
        setStyleStack(sytleTypeStack);
      }
    }
  }, []);

  return (
    <>
      <Card
        onClick={() => handleItemClick(value.id, value.stack, value.node)}
        style={styleStack}
        sx={{
          boxShadow: "none",
          cursor: "pointer",
          width: "137px",
          height: "297px",
          border: `1px solid ${
            selectedItem === value.id ? "#1781E2" : "#7B8A93"
          } `,
          backgroundColor: selectedItem === value.id ? "#d9e8f5" : "#F1F3F4",
          marginRight: "16px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          color: "#48535C",
          padding: "40px 10px 30px 10px",
        }}
      >
        <Box marginBottom="18px">
          <Typography
            fontSize="16px"
            fontWeight="600"
            fontFamily="Montserrat"
            fontStyle="normal"
            lineHeight="45px"
          >
            {value.title}
          </Typography>
          <Typography
            fontFamily="Montserrat"
            fontStyle="normal"
            fontSize="10px"
            fontWeight="600"
            lineHeight="12px"
          >
            {value.subTitle}
          </Typography>
        </Box>
        <Box>
          <Typography
            fontFamily="Montserrat"
            fontStyle="normal"
            fontSize="10px"
            fontWeight="600"
            lineHeight="12px"
            paddingBottom="20px"
          >
            {value.node}
          </Typography>
          <Typography
            fontFamily="Montserrat"
            fontStyle="normal"
            fontSize="10px"
            fontWeight="600"
            lineHeight="12px"
            paddingBottom="28px"
          >
            {value.setup}
          </Typography>
        </Box>
        <Box>
          <Typography
            fontFamily="Montserrat"
            fontStyle="normal"
            fontSize="16px"
            fontWeight="600"
            lineHeight="12px"
          >
            {value.price}
          </Typography>
        </Box>
        <Box paddingTop="24px">
          <img src={value.imgSlogan} alt="slogan Multiledgers" />
        </Box>
      </Card>
    </>
  );
};
