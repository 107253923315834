import React, { FC, useContext, useState } from "react";

import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import SvgIcon from "@mui/material/SvgIcon";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { WizardContext } from "../../../context/wizardContext";

import { styled } from "@mui/material/styles";
import { fonts, colors } from "../../../style/themes";
import { InvoiceHistory } from "../../../components/invoiceHistory";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: "13px !important",
  borderRadius: 20,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#CAD8E6",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: "5px !important",
    backgroundColor: "#0096FF !important",
  },
}));

interface IDataFinancial {
  totalValue: number;
}

interface IUsageAmount {
  tokenUser: string;
}

interface IMenuPricing {
  id: number;
  title: string;
}

export const UsageAmount: FC<IUsageAmount> = ({ tokenUser }) => {
  const [selectMenuPricing, setSelectMenuPricing] = useState<number>(1);
  const menuPricing = [
    {
      id: 1,
      title: "Usage Amount",
    },
    {
      id: 2,
      title: "Invoice history",
    },
  ];
  // @ts-ignore
  const { dataFinancial } = useContext<IDataFinancial>(WizardContext);

  const handleItemClick = (idMenu: number) => {
    setSelectMenuPricing(idMenu);
  };

  if (dataFinancial) {
    return (
      <Paper
        sx={{
          boxSizing: "border-box",
          padding: "14px 12px",
          boxShadow: "none",
          background: "#F1F3F4",
          border: "1px solid #CBD5E1",
          borderRadius: "30px",
          height: "336px",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            sx={{
              paddingLeft: "13px",
              fontSize: fonts.size[300],
              fontFamily: fonts.family.secondary,
              fontWeight: fonts.weight.secondary,
              color: colors.secondary[500],
            }}
          >
            PRINCING
          </Typography>
          <Box display="flex">
            {menuPricing.map((value: IMenuPricing) => (
              <>
                <Typography
                  key={value.id}
                  onClick={() => handleItemClick(value.id)}
                  sx={{
                    paddingRight: "12px",
                    fontFamily: "Montserrat",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: "600",
                    color: colors.secondary[600],
                    cursor: "pointer",
                  }}
                >
                  {value.title}
                  {selectMenuPricing === value.id && (
                    <Box
                      sx={{
                        marginTop: "2px",
                        border: "1px solid #1781E2",
                        width: "98%",
                      }}
                    ></Box>
                  )}
                </Typography>
              </>
            ))}
          </Box>
        </Box>
        {selectMenuPricing === 1 && (
          <>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="start"
              sx={{ padding: "45px 22px 0 36px" }}
            >
              <Grid item lg={6} justifyContent="center" alignItems="start">
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "11px",
                    fontSize: fonts.size[80],
                    fontFamily: fonts.family.secondary,
                    fontWeight: fonts.weight.primary,
                    color: colors.secondary[600],
                  }}
                >
                  <SvgIcon viewBox="-3 -3 24 24">
                    <circle cx="9" cy="9" r="9" fill="#1781E2" />
                  </SvgIcon>
                  CURRENT VALUE
                </Typography>
                <Typography
                  sx={{
                    marginTop: "32px",
                    fontSize: fonts.size[700],
                    fontFamily: fonts.family.secondary,
                    fontWeight: fonts.weight[600],
                    color: colors.secondary[600],
                  }}
                >
                  {dataFinancial.totalValue.toLocaleString("pr-BR", {
                    style: "currency",
                    currency: "BRL",
                  })}
                </Typography>
              </Grid>
              <Grid item lg={6} justifyContent="center" alignItems="start">
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "11px",
                    fontSize: fonts.size[80],
                    fontFamily: fonts.family.secondary,
                    fontWeight: fonts.weight.primary,
                    color: colors.secondary[600],
                  }}
                >
                  <SvgIcon viewBox="-3 -3 24 24">
                    <circle cx="9" cy="9" r="9" fill="#CAD8E6" />
                  </SvgIcon>
                  ESTIMATED VALUE
                </Typography>
                <Typography
                  sx={{
                    marginTop: "32px",
                    fontSize: fonts.size[700],
                    fontFamily: fonts.family.secondary,
                    fontWeight: fonts.weight[600],
                    color: colors.secondary[600],
                  }}
                >
                  {dataFinancial.totalEstimateValue.toLocaleString("pr-BR", {
                    style: "currency",
                    currency: "BRL",
                  })}
                </Typography>
              </Grid>
            </Grid>
            <Box sx={{ padding: "45px 22px 0 36px" }}>
              <BorderLinearProgress
                variant="determinate"
                value={
                  !!dataFinancial.totalEstimateValue
                    ? (dataFinancial.totalValue /
                        dataFinancial.totalEstimateValue) *
                      100
                    : 0
                }
              />
            </Box>
          </>
        )}
        {selectMenuPricing === 2 && (
          <>
            <Grid
              container
              marginTop="19px"
              display="flex"
              justifyContent="center"
            >
              <Grid item md={12}>
                <Box
                  sx={{
                    overflow: 'auto',
                    height: '250px',
                    '::-webkit-scrollbar': {
                      border: '1px solid #D4D7DE',
                      width: '7px',
                      borderRadius: '7px'
                    },
                    '::-webkit-scrollbar-thumb': {
                      backgroundColor: '#D9D9D9',
                      borderRadius: '15px',
                      height: '170px',
                      width: '7px'
                    }
                    }}>
                  <InvoiceHistory tokenUser={tokenUser}/>
                </Box>
              </Grid>
            </Grid>
          </>
        )}
      </Paper>
    );
  } else {
    return (
      <Skeleton
        variant="rectangular"
        sx={{
          width: "100%",
          height: "336px",
          boxSizing: "border-box",
          border: "1px solid #CBD5E1",
          borderRadius: "30px",
        }}
      />
    );
  }
};
