import { Card } from "@mui/material";
import { DeployConfirmation } from "./DeployConfirmation/DeployConfirmation";
import { DeployNameDesc } from "../../components/Platform/Deploy/DeployWizard/WizardSteps/DeployNameDesc";
import { DeployStack } from "../../components/Platform/Deploy/DeployWizard/WizardSteps/DeployStack";
import { DeployStep0 } from "../../components/Platform/Deploy/DeployWizard/WizardSteps/DeployStep0";
import { DeployStep1 } from "../../components/Platform/Deploy/DeployWizard/WizardSteps/DeployStep1";
import { DeployVMSetup } from "../../components/Platform/Deploy/DeployWizard/WizardSteps/DeployVMSetup";
import { DeployProcess } from "./DeployProcess/DeployProcess";
import { DeployLicense } from "./DeployLicense/DeployLicense";

export type DeployStepProps = {
  step: number;
  handleNextStep: () => void;
  setSelectedOrg: (org: string) => void;
  setNetworkName: (name: string) => void;
  selectedOrg: string;
  networkName: string;
  tokenUser: string;
};

export const DeploySteps = ({
  step,
  handleNextStep,
  setSelectedOrg,
  setNetworkName,
  selectedOrg,
  networkName,
  tokenUser,
}: DeployStepProps) => {
  let stepComponent;
  let cardHeight = "620px";
  if (step === 0) {
    stepComponent = <DeployStep0 handleNextStep={handleNextStep} />;
    cardHeight = "761px";
  } else if (step === 1) {
    stepComponent = (
      <DeployNameDesc
        setSelectedOrg={setSelectedOrg}
        setNetworkName={setNetworkName}
        selectedOrg={selectedOrg}
        networkName={networkName}
        tokenUser={tokenUser}
      />
    );
  } else if (step === 2) {
    stepComponent = <DeployLicense tokenUser={tokenUser} />;
  } else if (step === 3) {
    stepComponent = <DeployStep1 />;
  } else if (step === 4) {
    stepComponent = <DeployStack />;
  } else if (step === 5) {
    stepComponent = <DeployVMSetup tokenUser={tokenUser} />;
  } else if (step === 6) {
    stepComponent = <DeployConfirmation />;
  } else if (step === 7) {
    stepComponent = <DeployProcess tokenUser={tokenUser} />;
  }

  return (
    <Card
      sx={{
        backgroundColor: "#FFFFFF",
        boxShadow: "none",
        borderRadius: "20px",
        height: cardHeight,
        paddingLeft: "50px",
        paddingRight: "43px",
        paddingBottom: "63px",
        paddingTop: "20px",
      }}
    >
      {stepComponent}
    </Card>
  );
};
