import axios from "axios";

export default function (TOKEN_ACESS?: string) {
  return axios.create({
    baseURL: `${process.env.REACT_APP_API_URL_ADDRESS}`,
    headers: {
      Authorization: `Bearer ${TOKEN_ACESS}`,
    },
  });
}
