import React, { useState } from "react";
import axios from "axios";
import { StyledOwnerWallet } from "../../Styles/OwnerWallet.styled";
import { mnemonicGenerate } from "@polkadot/util-crypto";
import { keyring as uiKeyring } from "@polkadot/ui-keyring";
import { cryptoWaitReady } from "@polkadot/util-crypto";

import walletIcon from "~/assets/wallet_icon.png";
import walletWarningInfo from "~/assets/warning_wallet_info.png";
import copyButton from "~/assets/copyButton.png";
import { DeployAuthorization } from "../../../components/deployAuthorization";
import { ApiPromise, WsProvider } from "@polkadot/api";
import { useSnackbar } from "notistack";

function MemberWallet(props: {
  setFormInfo: (arg0: any) => void;
  previousHandleClick: () => void;
  nextHandleClick: () => void;
  formInfo: {
    userFirstName: string;
    userLastName: string;
    userEmail: string;
    userPhone: string;
    userJobTitle: string;
    userCPF: string;
    userPassword: string;
    userWalletAddress: string;
    userWalletName: string;
    userWalletPassword: string;
    userToken: string;
  };
}) {
  const mnemonic = mnemonicGenerate();
  const { enqueueSnackbar } = useSnackbar();
  const [walletName, setWalletName] = useState(props.formInfo.userWalletName);
  const [rawEnterpriseMnemonicWords, setRawEnterpriseMnemonicWords] =
    useState(mnemonic);
  const [mnemonicWords, setMnemonicWords] = useState(
    rawEnterpriseMnemonicWords.split(" ")
  );
  const [walletAddress, setWalletAddress] = useState("");
  const [isOwnerWalletWasStoraged, setOwnerWalletWasStoraged] = useState(false);
  const [openAuthorization, setOpenAuthorization] = useState<boolean>(false);
  const [loadingAuthorization, setLoadingAuthorization] =
    useState<boolean>(false);
    const [passwordAuthorization, setPasswordAuthorization] = useState<string>('');

  const handleSubmit = () => {
    setOpenAuthorization(true);
  };

  const handleCloseAuthoraztion = () => {
    setOpenAuthorization(false);
  };
  const onSubmitAuthorization = async (event?: any ) => {
    setLoadingAuthorization(true);
    // o atributo da senha digitada pelo usuario é : passwordAuthorization
    // O atributo utilizado para o load é esse: setLoadingAuthorization

    const data = {
      ...props.formInfo,
      "userWalletAddress": walletAddress,
      // "signedTx": "0x723649823764239764923642342234672394762394823984562389456",
    };

    const provider = new WsProvider(process.env.REACT_APP_CHAIN_ADDRESS);
    const api = await ApiPromise.create({
      provider
    });

    const userPair = uiKeyring.getPair(data.userWalletAddress);
  
    try {
      userPair.unlock(passwordAuthorization);
    } catch (error) {
      setLoadingAuthorization(false);
      enqueueSnackbar('Invalid password', {
        variant: "error",
      });
      return
    }

    const genesisHash = api.genesisHash;
    const runtimeVersion = api.runtimeVersion;

    let nonce = await api.rpc.system.accountNextIndex(data.userWalletAddress);

    // TODO função de adicionar a wallet
    const txSignUpMember = api.tx.multiledgersIdentity
    .signUpMember(data.userToken, data.userWalletAddress).sign(userPair, {genesisHash, blockHash: genesisHash, nonce, runtimeVersion});
    
    await api.disconnect();

    try {
      const memberCreated = await axios.post(
        `${process.env.REACT_APP_API_URL_ADDRESS}` + "/member/signup",
        {
            signedTx: txSignUpMember,
            inviteToken: data.userToken, 
            firstName: data.userFirstName, 
            lastName: data.userLastName, 
            phone: data.userPhone,
            userWalletAddress: data.userWalletAddress,
            userWalletName: data.userWalletName,
            password: data.userPassword,
            cpf: data.userCPF,
            jobTitle: data.userJobTitle
          }
      );
    } catch (error) {
      console.log(error)
    }
    setLoadingAuthorization(false);
    props.setFormInfo(data);
    event.preventDefault();
    props.nextHandleClick();
  };

  const handleCheckboxClick = async (event: any) => {
    const createWallet = async () => {
      if (walletAddress) return;
      try{
        await cryptoWaitReady();
        uiKeyring.loadAll({ ss58Format: 42, type: 'sr25519' });
      } catch (error) {
        setWalletAddress("");
        console.log(error);
      } finally{
        try {
          const { pair, json } = uiKeyring.addUri(mnemonic, `${props.formInfo.userWalletPassword}`, { name: `${props.formInfo.userWalletName}` });
          setWalletAddress(pair.address);
          
        } catch (error) {
          console.log(error)
        }
      }
    }

    createWallet();
    setOwnerWalletWasStoraged(event.target.checked);
  };

  const listItems = mnemonicWords.map((word) => (
    <li key={word.toString()}>
      <a>{word}</a>
    </li>
  ));

  return (
    <StyledOwnerWallet>
      <div className="container-owner-wallet">
        <div className="header">
          <h1 className="header-title">Personal Wallet Generation</h1>
          <h5 className="header-subtitle">
            Please write your mnemonic seed and keep it in a safe place. The
            mnemonic can be used to restore your wallet.
          </h5>
        </div>
        <div className="content">
          <div className="wallet-info">
            <div className="wallet-info-icon">
              <img src={walletIcon} alt="walletIcon" />
            </div>
            <div className="wallet-more-info">
              <div className="wallet-more-info-name">
                {walletName == "" ? "<unknown> wallet" : `${walletName} wallet`}
              </div>
              <div className="wallet-address-button">
                {walletAddress ? (
                  <div className="wallet-more-info-address">
                    {walletAddress}
                  </div>
                ) : (
                  <div className="wallet-more-info-address">
                    Click in "I have saved my mnemonic..." to see your wallet
                    address
                  </div>
                )}
                <button
                  className="wallet-address-copy-button"
                  onClick={() => {
                    navigator.clipboard.writeText(walletAddress.toString());
                  }}
                >
                  <img src={copyButton} alt="walletIcon" />
                </button>
              </div>
            </div>
          </div>
          <div className="mnemonic-info">
            <div className="mnemonic-title">
              GENERATED 12 WORD MNEMONIC SEED:
            </div>
            <div className="mnemonic-words">
              <ul>{listItems}</ul>
            </div>
            <button
              className="mnemonic-copy-button"
              onClick={() => {
                navigator.clipboard.writeText(
                  mnemonicWords.toString().split(",").join(" ")
                );
              }}
            >
              Copy mnemonic words
            </button>
          </div>
          <div className="warning-info">
            <div className="warning-icon">
              <img src={walletWarningInfo} alt="walletIcon" />
            </div>
            <div className="warning-text">
              Keep it carefully to not lose your assets.
            </div>
          </div>
        </div>
        <div className="footer">
          <div className="footer-checkbox-mnemonic">
            <input
              className="checkbox-input"
              onClick={handleCheckboxClick}
              type="checkbox"
              id="topping"
              name="topping"
              value="Paneer"
            />
            I have saved my mnemonic seed safely
          </div>
          <div className="footer-next-step">
            {isOwnerWalletWasStoraged && !!walletAddress ? (
              <button
                onClick={handleSubmit}
                className="footer-next-step-button"
              >
                Create account
              </button>
            ) : (
              <button disabled className="footer-next-step-button-disabled">
                Create account
              </button>
            )}
          </div>
        </div>
        <DeployAuthorization
          open={openAuthorization}
          loadingAuthorization={loadingAuthorization}
          onSubmitAuthorization={onSubmitAuthorization}
          handleCloseAuthorization={handleCloseAuthoraztion}
          setPasswordAuthorization={setPasswordAuthorization}
          title="Registering"
          subText="This action will authorize participation in an organization."
          confirm="Sign"
          cancel="Cancel"
        />
      </div>
    </StyledOwnerWallet>
  );
}

export default MemberWallet;
