export const Money: React.FC = () => (
<svg
  width="39"
  height="38"
  viewBox="0 0 39 38"
  fill="none" 
  xmlns="http://www.w3.org/2000/svg">
    <path d="M19.9648 0C9.47243 0 0.964844 8.50759 0.964844 19C0.964844 29.4924 9.47243 38 19.9648 38C30.4573 38 38.9648 29.4924 38.9648 19C38.9648 8.50759 30.4573 0 19.9648 0ZM19.9648 34.7768C11.2537 34.7768 4.18806 27.7112 4.18806 19C4.18806 10.2888 11.2537 3.22321 19.9648 3.22321C28.676 3.22321 35.7416 10.2888 35.7416 19C35.7416 27.7112 28.676 34.7768 19.9648 34.7768ZM21.9878 18.0161L20.9106 17.7658V12.0701C22.5222 12.2906 23.5189 13.3 23.6885 14.5384C23.7097 14.708 23.8539 14.831 24.0235 14.831H25.9278C26.1271 14.831 26.284 14.6571 26.2671 14.4578C26.0084 11.8156 23.8327 10.1192 20.9276 9.82656V8.43973C20.9276 8.25312 20.7749 8.10045 20.5883 8.10045H19.3965C19.2099 8.10045 19.0573 8.25312 19.0573 8.43973V9.83928C16.0546 10.1319 13.705 11.7902 13.705 14.8862C13.705 17.7531 15.8171 19.1357 18.0352 19.6658L19.0827 19.933V25.985C17.2081 25.7348 16.1564 24.7339 15.9401 23.3853C15.9146 23.2241 15.7704 23.1054 15.605 23.1054H13.6456C13.4463 23.1054 13.2894 23.275 13.3064 23.4743C13.4972 25.8069 15.2657 27.9529 19.0403 28.2286V29.5603C19.0403 29.7469 19.193 29.8996 19.3796 29.8996H20.584C20.7706 29.8996 20.9233 29.7469 20.9233 29.556L20.9148 28.2116C24.2356 27.919 26.6106 26.142 26.6106 22.9527C26.6064 20.0094 24.736 18.6946 21.9878 18.0161ZM19.0785 17.329C18.841 17.2612 18.6416 17.1975 18.4423 17.117C17.0088 16.5996 16.343 15.7641 16.343 14.6868C16.343 13.1473 17.5093 12.2694 19.0785 12.0701V17.329ZM20.9106 25.9978V20.3275C21.0421 20.3656 21.1608 20.3953 21.2838 20.4208C23.2898 21.0315 23.9642 21.8797 23.9642 23.1817C23.9642 24.84 22.7173 25.8366 20.9106 25.9978Z" fill="#CBD5E1"/>
</svg>
);
