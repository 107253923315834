export const Check: React.FC = () => (
  <svg
    width="17"
    height="12"
    viewBox="0 0 17 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.24101 11.6143L0.736285 6.11483C0.556958 5.90543 0.569015 5.59329 0.76396 5.39834C0.958904 5.2034 1.27105 5.19134 1.48045 5.37067L6.23045 10.1207L15.5141 0.847613C15.7235 0.668286 16.0356 0.680343 16.2306 0.875288C16.4255 1.07023 16.4376 1.38238 16.2582 1.59178L6.24101 11.6143Z"
      fill="#04B30B"
    />
  </svg>
);
