import React, { FC } from 'react';

import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { fonts, colors } from '../../../style/themes';
import { ResourceDeployment } from '../../../components/resourceDeployment';
import { HeaderDeployment } from '../../../components/headerDeployment';
import { Box } from '@mui/material';
import { customThinScroll } from '../../../style/scrollBarStyle';

interface DeploymentCard {
  currentUpTime: number;
  user: any;
  dataValue: any | null;
  uptime: any;
  setUpdateListNetwork: (value: boolean) => void
  tokenUser: string;
  selectedSubitem: {
    dataKey: string;
    index: number;
  } | null;
  setSelectedSubitem: React.Dispatch<React.SetStateAction<{
    dataKey: string;
    index: number;
  } | null>>;
}

export const DeploymentCard: FC<DeploymentCard> = ({
  user,
  dataValue,
  uptime,
  setUpdateListNetwork,
  currentUpTime,
  setSelectedSubitem,
  tokenUser,
  selectedSubitem
}) => {

  if (dataValue) {
    return (
      <Paper
        sx={{
          boxSizing: 'border-box',
          boxShadow: 'none',
          padding: '14px 25px 10px 0',
          background: '#F1F3F4',
          border: '1px solid #CBD5E1',
          borderRadius: '30px',
          height: '422.09px'
        }}
      >
        <Typography
          sx={{
            fontSize: fonts.size[300],
            fontFamily: fonts.family.secondary,
            fontWeight: fonts.weight.secondary,
            color: colors.secondary[500],
            textAlign: 'left',
            paddingLeft: '25px',
            marginBottom: '22px'
          }}
        >
          Deployments
        </Typography>
        <HeaderDeployment />
        <Grid
          container
          direction="row"
          justifyContent="start"
          alignItems="center"
        >
          {dataValue &&
            <Box sx={{
              paddingRight: '36px',
              width: '100%',
              height: '290px',
              overflowY: 'auto',
              ...customThinScroll
            }}>
              <ResourceDeployment
                user={user}
                currentUpTime={currentUpTime}
                data={dataValue}
                uptime={uptime}
                selectedSubitem={selectedSubitem}
                setSelectedSubitem={setSelectedSubitem}
                tokenUser={tokenUser}
                setUpdateListNetwork={setUpdateListNetwork}
              />
            </Box>
          }
        </Grid>
      </Paper>
    );
  } else {
    return (
      <Skeleton
        variant="rectangular"
        sx={{
          width: '100%',
          height: '422.09px',
          boxSizing: 'border-box',
          border: '1px solid #CBD5E1',
          borderRadius: '30px',
        }} />
    )
  }
};
