import React, { useState } from "react";
import "./enterpriseSignUp.css";
// @ts-ignore
import MemberInitialForm from "./MemberInitialForm";
// @ts-ignore
import MemberProgressBar from "./MemberProgressBar";
// @ts-ignore
import logo from "~/assets/logo_white.png";
// @ts-ignore
import NewProgressBar from "./NewProgressBar.tsx";
import { AiOutlineArrowLeft } from "react-icons/ai";

function MemberEnterpriseSignUpContainer(props: {
  handleClickCreateAccount: any;
  email: any;
  setEmail: any;
  firstName: any;
  setFirstName: any;
  lastName: any;
  token: any;
  setLastName: any;
  phone: any;
  setPhone: any;
}) {
  const [signUpStep, setSignUpStep] = useState(0);

  const previousHandleClick = () => setSignUpStep(signUpStep - 1);
  const nextHandleClick = () => setSignUpStep(signUpStep + 1);

  return (
    <div className="multiledgers-sign-up-container">
      <div className="internal-container">
        <MemberProgressBar signUpStep={signUpStep} />
        <MemberInitialForm
          firstName={props.firstName}
          lastName={props.lastName}
          phone={props.phone}
          email={props.email}
          token={props.token}
          previousHandleClick={previousHandleClick}
          nextHandleClick={nextHandleClick}
          handleClickCreateAccount={props.handleClickCreateAccount}
          signUpStep={signUpStep}
        />
        {signUpStep > 0 && signUpStep <= 2 && (
          <div className="previous-step-div22">
            <AiOutlineArrowLeft/>
            <button onClick={previousHandleClick}>Previous Step</button>
          </div>
    )}
      </div>
    </div>
  );
}

export default MemberEnterpriseSignUpContainer;
