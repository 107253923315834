import { useContext, useEffect, useState } from "react";
import { Box, Card, Typography } from "@mui/material";
import { WizardContext } from "../../context/wizardContext";

interface IConsensusBeso {
  id: string;
  name: string;
}

export const ConsensusBeso = () => {
  // @ts-ignore
  const { deployData, setDeployData } = useContext(WizardContext);

  const [clickConsensus, setClickConsensus] = useState<number>(0);
  const [styleProcol, setStyleProcol] = useState<Object>();

  const consesus = [
    {
      id: 1,
      name: "QBFT",
    },
    {
      id: 2,
      name: "IBFT",
    },
    {
      id: 3,
      name: "Clique",
    },
    {
      id: 4,
      name: "Ethash",
    },
  ];

  const styleConsensus = {
    backgroundColor: "#d9e8f5",
    border: "1px solid #1781E2",
  };

  const selectConsensus = (id: number, name: string) => {
    if (name !== 'QBFT') return;
    setClickConsensus(id);
    setStyleProcol(styleConsensus);
    setDeployData({
      ...deployData,
      consesus: name,
      // buttonColor: "#1976d2",
    });
  };

  useEffect(() => {
    if (deployData.consesus === "QBFT") {
      setClickConsensus(1);
      setStyleProcol(styleConsensus);
    }
  }, [deployData]);

  return (
    <Card sx={{ boxShadow: "none" }}>
      <Box marginBottom="19px">
        <Typography
          fontFamily="Montserrat"
          color="#3E474D"
          fontSize="13px"
          fontWeight="600"
        >
          Consensus
        </Typography>
      </Box>
      <Box display="flex">
        {consesus.map(({ id, name }) => (
          <Box
            key={id}
            onClick={() => selectConsensus(id, name)}
            style={clickConsensus === id ? styleProcol : undefined}
            sx={{
              marginRight: "18px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontFamily: "Montserrat",
              color: '#11111',
              fontWeight: 600,
              background: name === "QBFT" ? "#F1F3F4" : "#f5f7f7",
              border: "1px solid #DEE1E6",
              width: "96px",
              height: "35px",
              cursor: name === "QBFT" ? "pointer" : "not-allowed",
            }}
          >
            {name}
          </Box>
        ))}
      </Box>
    </Card>
  );
};
