export const Member: React.FC = () => (
  <svg
    width="32" 
    height="33" 
    viewBox="0 0 32 33" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg">
      <path d="M29.8686 26.7345C29.0956 24.902 27.974 23.2375 26.5661 21.8337C25.1624 20.4258 23.4997 19.3033 21.6696 18.5282C21.6533 18.52 21.6369 18.5159 21.6205 18.5077C24.1732 16.6622 25.8326 13.6561 25.8326 10.2645C25.8326 4.64597 21.2845 0.09375 15.671 0.09375C10.0576 0.09375 5.50944 4.64597 5.50944 10.2645C5.50944 13.6561 7.16889 16.6622 9.72158 18.5118C9.70519 18.52 9.6888 18.5241 9.67241 18.5323C7.83677 19.3074 6.18961 20.4188 4.776 21.8378C3.3694 23.2427 2.24792 24.9069 1.47349 26.7386C0.712689 28.5318 0.302373 30.4544 0.264751 32.4022C0.263657 32.446 0.271328 32.4896 0.287311 32.5303C0.303293 32.5711 0.327265 32.6082 0.357813 32.6396C0.388361 32.6709 0.424867 32.6958 0.465181 32.7128C0.505494 32.7298 0.548799 32.7385 0.592544 32.7385H3.05099C3.23128 32.7385 3.37469 32.595 3.37879 32.4186C3.46073 29.2526 4.73093 26.2875 6.97631 24.0401C9.29955 21.7148 12.3849 20.4352 15.671 20.4352C18.9572 20.4352 22.0425 21.7148 24.3657 24.0401C26.6111 26.2875 27.8813 29.2526 27.9633 32.4186C27.9674 32.5991 28.1108 32.7385 28.2911 32.7385H30.7495C30.7933 32.7385 30.8366 32.7298 30.8769 32.7128C30.9172 32.6958 30.9537 32.6709 30.9842 32.6396C31.0148 32.6082 31.0388 32.5711 31.0547 32.5303C31.0707 32.4896 31.0784 32.446 31.0773 32.4022C31.0363 30.4419 30.6307 28.5349 29.8686 26.7345V26.7345ZM15.671 17.3184C13.7903 17.3184 12.0202 16.5843 10.6886 15.2514C9.35691 13.9186 8.62348 12.1469 8.62348 10.2645C8.62348 8.38208 9.35691 6.6104 10.6886 5.27754C12.0202 3.94469 13.7903 3.21059 15.671 3.21059C17.5517 3.21059 19.3218 3.94469 20.6535 5.27754C21.9851 6.6104 22.7186 8.38208 22.7186 10.2645C22.7186 12.1469 21.9851 13.9186 20.6535 15.2514C19.3218 16.5843 17.5517 17.3184 15.671 17.3184Z" fill="#CBD5E1"/>
</svg>
);

