import {
  Box,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { DeployStepHeading } from "./DeployStepHeading";
import axios from "axios";
import { AddSStandAlone } from "~/assets/icons/addStandAlone";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { WizardContext } from "../../../../../context/wizardContext";
import { ClipBoard } from "~/assets/icons/clipBoard";
import { Delete } from "~/assets/icons/delete";
import { Deny } from "~/assets/icons/deny";
import { Check } from "~/assets/icons/check";
import EditIcon from "@mui/icons-material/Edit";

export type DeployVMSetupProps = {
  tokenUser: string;
};

export const DeployVMSetup = ({ tokenUser }: DeployVMSetupProps) => {
  // @ts-ignore
  const { deployData, setDeployData } = useContext(WizardContext);
  const [backgroundColor, setBackgroundColor] = useState("#F5F5F5");
  const [validateButonVm, setValidateButonVm] = useState(false);
  const [errorIp, setErrorIp] = useState(false);
  const [thereisDomainn, setThereisDomainn] = useState(true);
  const [validateVMdomain, setValidateVMdomain] = useState(false);

  const [loadVmSetup, setLoadVmSetup] = useState(false);

  const [vmMessage, setVmMessage] = useState("");
  const [addLocked, setAddLocked] = useState(false);

  const [file, setFile] = useState<File | null>(null);
  const [inputKey, setInputKey] = useState(0);

  const [vmIcon, setVmIcon] = useState<JSX.Element>(<></>);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
      setDeployData({ ...deployData, fileSetup: e.target.files[0] });
    }
  };

  const validationButton = () => {
    if (deployData.currentVm === "4 - COLLATOR") {
      return (
        !validateButonVm ||
        loadVmSetup ||
        deployData.vms.length === 4 ||
        !deployData.name ||
        !deployData.mnemonic
      );
    }
    return !validateButonVm || loadVmSetup || deployData.vms.length === 4;
  };

  useEffect(() => {
    if (file || deployData.fileSetup) {
      setFile(file || deployData.fileSetup);
    }
  }, [file]);

  useEffect(() => {
    const { vmIp, vmUsername } = deployData;
    if (vmIp && vmUsername && file) {
      setValidateButonVm(true);
    } else {
      setValidateButonVm(false);
    }
  }, [deployData.vmIp, deployData.vmUsername, file]);

  const toggleBackground = () => {
    setBackgroundColor("#E5F7E6");
    setTimeout(() => {
      setBackgroundColor("#F5F5F5");
    }, 800);
  };

  const toggleBackgroundError = () => {
    setBackgroundColor("#F7E5E5");
    setTimeout(() => {
      setBackgroundColor("#F5F5F5");
    }, 800);
  };

  const updateCurrentVm = () => {
    const clearFields = {
      vmIp: "",
      vmUsername: "",
      domain: "",
      name: "",
      mnemonic: "",
    };

    if (deployData.currentVm === "1 - Database") {
      setDeployData({
        ...deployData,
        currentVm: "2 - Backend",
        ...clearFields,
      });
    } else if (deployData.currentVm === "2 - Backend") {
      setDeployData({
        ...deployData,
        currentVm: "3 - Frontend",
        ...clearFields,
      });
    } else if (deployData.currentVm === "3 - Frontend") {
      setDeployData({
        ...deployData,
        currentVm: "4 - COLLATOR",
        ...clearFields,
      });
    } else if (deployData.currentVm === "4 - COLLATOR") {
      setDeployData({ ...deployData, currentVm: "4 - lastNode" });
    }
  };

  const resetFile = () => {
    setFile(null);
    setInputKey(inputKey + 1);
  };

  const deleteStack = () => {
    setDeployData({
      ...deployData,
      currentVm: "1 - Database",
      vmIp: "",
      vmUsername: "",
      domain: "",
      mnemonic: "",
      name: "",
      vms: [],
      buttonColor: "#7b8a93",
      fileSetup: null,
    });
    resetFile();
    setThereisDomainn(true);
  };

  useEffect(() => {
    if (deployData.currentVm === "4 - lastNode") {
      setDeployData({ ...deployData, buttonColor: "#1781E2" });
    }
  }, [deployData.currentVm]);

  const validateNodeName = (nodeName: string) => {
    if (nodeName) {
      nodeName = nodeName
        .replace(" ", "")
        .replace(/[^\w\s]/gi, "")
        .replace("_", "");
      return nodeName;
    }
  };

  const testVm = () => {
    setAddLocked(true);
    setVmMessage("");
    setVmIcon(<></>);
    const varFormData = new FormData();

    const thereisIp = deployData.vms.some((value: any) => {
      return value.externalIP === deployData.vmIp;
    });
    let thereisDomain;

    // Essa regra vai ser aplicado a partir do 2 step
    // se o campo estiver vazio ja recebe false o setThereisDomainn, mas se tiver valor entra essa validacao
    const vmDomainField = deployData?.vms[1]?.domain;

    if (deployData.currentVm !== "1 - Database") {
      if (deployData.domain === "" && !vmDomainField) {
        setThereisDomainn(false);
      } else {
        setThereisDomainn(true);
      }
      if (thereisDomainn) {
        thereisDomain = deployData.vms.some((value: any) => {
          return (
            value.domain && value.domain.trim() === deployData.domain.trim()
          );
        });
        setValidateVMdomain(thereisDomain);
      }
    }

    if (vmDomainField !== "") {
      if (
        deployData.currentVm === "3 - Frontend" ||
        deployData.currentVm === "4 - COLLATOR"
      ) {
        if (deployData.domain === "") {
          setValidateVMdomain(true);
          thereisDomain = true;
          // return
        }
      }
    }

    setErrorIp(thereisIp);

    varFormData.append("organizationId", deployData.orgId);
    varFormData.append("ip", deployData.vmIp);
    varFormData.append("username", deployData.vmUsername);
    varFormData.append("licenseId", deployData.licenseId);

    if (file) {
      varFormData.append("file", file);
    }

    switch (deployData.currentVm) {
      case "1 - Database":
        varFormData.append("role", "database");
        break;
      case "2 - Backend":
        varFormData.append("role", "backend");
        break;
      case "3 - Frontend":
        varFormData.append("role", "frontend");
        break;
      case "4 - COLLATOR":
        varFormData.append("role", "collator");
        setDeployData({
          ...deployData,
          backgroundColor: "#1781E2",
        });
        break;
    }

    if (thereisIp) return;
    if (thereisDomain) return;

    setLoadVmSetup(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL_ADDRESS}/vm/available/tokenizer`,
        varFormData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + tokenUser,
          },
        }
      )
      .then((res) => {
        const vms = deployData.vms;
        if (res.status === 201 && vms.length < 4) {
          setLoadVmSetup(false);
          switch (deployData.currentVm) {
            case "1 - Database":
              vms.push({
                role: "database",
                externalIP: deployData.vmIp,
                username: deployData.vmUsername,
              });
              setDeployData({
                ...deployData,
                vms: vms,
              });
              break;
            case "2 - Backend":
              vms.push({
                role: "backend",
                externalIP: deployData.vmIp,
                username: deployData.vmUsername,
                domain: deployData.domain,
              });
              setDeployData({
                ...deployData,
                vms: vms,
              });
              break;
            case "3 - Frontend":
              vms.push({
                role: "frontend",
                externalIP: deployData.vmIp,
                username: deployData.vmUsername,
                domain: deployData.domain,
              });
              setDeployData({
                ...deployData,
                vms: vms,
              });
              break;
            case "4 - COLLATOR":
              vms.push({
                role: "collator",
                externalIP: deployData.vmIp,
                username: deployData.vmUsername,
                name: deployData.name,
                mnemonic: deployData.mnemonic,
                domain: deployData.domain,
              });
              setDeployData({
                ...deployData,
                vms: vms,
              });
              break;
          }
          setDeployData({
            ...deployData,
            vmIp: "",
            vmUsername: "",
            domain: "",
            vms: deployData.vms,
            buttonColor: "#1781E2",
          });
          updateCurrentVm();
          toggleBackground();
          setVmMessage("Well done, VM validated!");
          setVmIcon(<Check />);
          setAddLocked(false);
        }
      })
      .catch((err) => {
        setLoadVmSetup(false);
        console.log("err", err);
        toggleBackgroundError();
        // updateCurrentVm();
        setVmMessage("Invalid, please check your informations.");
        setVmIcon(<Deny />);
        setAddLocked(false);
      });
  };

  return (
    <Box>
      <DeployStepHeading
        title="VM Setup"
        description="Please import all 4 vM’s, one by one so we can proceed to the next phase"
      />
      <Box>
        <Grid container md={12}>
          <Grid item md={11}>
            <Typography
              fontFamily="Montserrat"
              fontWeight="600"
              color="#3E474D"
              fontSize={"13px"}
              sx={{
                paddingBottom: "10px",
              }}
            >
              Importing Stack
            </Typography>
          </Grid>
          <Grid item md={1}>
            <Typography
              fontFamily="Montserrat"
              fontWeight="600"
              color="#5F6368"
              fontSize={"13px"}
              sx={{
                paddingBottom: "10px",
              }}
            >
              {deployData.currentVm.charAt(0)}/4
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          backgroundColor: backgroundColor,
          border: "1px solid #7B8A93",
          width: "100%",
          height: "22px",
          borderRadius: "5px",
        }}
      >
        <Grid container md={12}>
          <Grid item md={11}>
            <Typography
              fontFamily="Montserrat"
              fontWeight="600"
              color="#3E474D"
              fontSize={"13px"}
              sx={{
                height: "15px",
                lineHeight: "15px",
                fontSize: "12px",
                textAlign: "left",
                color: "#7B8A93",
                paddingLeft: "4px",
                paddingTop: "2px",
                letterSpacing: "1px",
              }}
            >
              VM {deployData.currentVm}
            </Typography>
          </Grid>
          <Grid item md={1}>
            {loadVmSetup ? (
              <CircularProgress size={19} sx={{ fontSize: "12px" }} />
            ) : (
              <>{vmIcon}</>
            )}
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Typography
          fontFamily="Montserrat"
          fontWeight="600"
          color="#3E474D"
          fontSize={"13px"}
          textAlign="right"
          sx={{ paddingTop: "10px" }}
        >
          {vmMessage}
        </Typography>
      </Box>
      <Grid container md={12} sx={{ paddingTop: "20px" }}>
        <Grid item md={6}>
          <Typography
            fontFamily="Montserrat"
            fontWeight="600"
            color="#3E474D"
            fontSize={"13px"}
            sx={{
              height: "40px",
              lineHeight: "40px",
              textAlign: "left",
            }}
          >
            VM IP
          </Typography>
        </Grid>
        <Grid item md={6}>
          <OutlinedInput
            placeholder="Insert IP"
            type="string"
            value={deployData.vmIp}
            onChange={(e) =>
              setDeployData({
                ...deployData,
                vmIp: e.target.value.replace(" ", "").replace(/[^\d.]/g, ""),
              })
            }
            sx={{
              width: "100%",
              height: "40px",
              border: "none",
              borderRadius: "10px",
              padding: "0",
              boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
            }}
          />
          {errorIp && (
            <FormHelperText error>{"This ip is already in use"}</FormHelperText>
          )}
        </Grid>
      </Grid>
      <Grid container md={12} sx={{ paddingTop: "20px" }}>
        <Grid item md={6}>
          <Typography
            fontFamily="Montserrat"
            fontWeight="600"
            color="#3E474D"
            fontSize={"13px"}
            sx={{
              height: "40px",
              lineHeight: "40px",
              textAlign: "left",
            }}
          >
            OS Username
          </Typography>
        </Grid>
        <Grid item md={6}>
          <OutlinedInput
            placeholder="ex ubuntu"
            type="string"
            value={deployData.vmUsername}
            onChange={(e) =>
              setDeployData({ ...deployData, vmUsername: e.target.value })
            }
            sx={{
              width: "100%",
              height: "40px",
              border: "none",
              borderRadius: "10px",
              padding: "0",
              boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
            }}
          />
        </Grid>
      </Grid>
      {/* -- */}

      {deployData.currentVm !== "1 - Database" && (
        <>
          {thereisDomainn && (
            <Grid container md={12} sx={{ paddingTop: "20px" }}>
              <>
                <Grid item md={6}>
                  <Typography
                    fontFamily="Montserrat"
                    fontWeight="600"
                    color="#3E474D"
                    fontSize={"13px"}
                    sx={{
                      height: "40px",
                      lineHeight: "40px",
                      textAlign: "left",
                    }}
                  >
                    {`VM Domain `}
                  </Typography>
                </Grid>

                <Grid item md={6}>
                  <OutlinedInput
                    placeholder="vm domain"
                    type="string"
                    value={deployData.domain}
                    onChange={(e) =>
                      setDeployData({ ...deployData, domain: e.target.value })
                    }
                    sx={{
                      width: "100%",
                      height: "40px",
                      border: "none",
                      borderRadius: "10px",
                      padding: "0",
                      boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
                    }}
                  />
                  {validateVMdomain && (
                    <FormHelperText error>{"error on Domain"}</FormHelperText>
                  )}
                </Grid>
              </>
            </Grid>
          )}
        </>
      )}

      {(deployData.currentVm === "4 - COLLATOR" ||
        deployData.currentVm === "4 - lastNode") && (
        <>
          <Grid container md={12} sx={{ paddingTop: "20px" }}>
            <Grid item md={6}>
              <Typography
                fontFamily="Montserrat"
                fontWeight="600"
                color="#3E474D"
                fontSize={"13px"}
                sx={{
                  height: "40px",
                  lineHeight: "40px",
                  textAlign: "left",
                }}
              >
                Node name
              </Typography>
            </Grid>
            <Grid item md={6}>
              <OutlinedInput
                placeholder="e.g Node01 (no spaces)"
                type="string"
                value={deployData.name}
                onChange={(e) =>
                  setDeployData({
                    ...deployData,
                    name: validateNodeName(e.target.value),
                  })
                }
                sx={{
                  width: "100%",
                  height: "40px",
                  border: "none",
                  borderRadius: "10px",
                  padding: "0",
                  boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
                }}
              />
            </Grid>
          </Grid>
          <Grid container md={12} sx={{ paddingTop: "20px" }}>
            <Grid item md={6}>
              <Typography
                fontFamily="Montserrat"
                fontWeight="600"
                color="#3E474D"
                fontSize={"13px"}
                sx={{
                  height: "40px",
                  lineHeight: "40px",
                  textAlign: "left",
                }}
              >
                Mnemonic
              </Typography>
            </Grid>
            <Grid item md={6}>
              <OutlinedInput
                placeholder="e.g botton table cell..."
                error={false}
                type="string"
                value={deployData.mnemonic}
                onChange={(e) =>
                  setDeployData({ ...deployData, mnemonic: e.target.value })
                }
                sx={{
                  width: "100%",
                  height: "40px",
                  border: "none",
                  borderRadius: "10px",
                  padding: "0",
                  boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
                }}
              />
            </Grid>
          </Grid>
        </>
      )}
      {deployData.currentVm !== "4 - COLLATOR" &&
      deployData.currentVm !== "4 - lastNode" ? (
        <>
          <Grid
            container
            md={12}
            sx={{ paddingTop: "30px", paddingLeft: "0px" }}
          >
            <Grid
              item
              md={12}
              display="flex"
              width="100%"
              justifyContent="flex-end"
            >
              <Box>
                <Typography
                  fontFamily="Montserrat"
                  fontWeight="500"
                  color="#7B8A93"
                  fontSize={"13px"}
                  sx={{
                    paddingTop: "10px",
                    paddingRight: "10px",
                  }}
                >
                  UPLOAD VM
                  <br />
                  PRIVATE KEY
                </Typography>
              </Box>
              <Button
                variant="contained"
                component="label"
                sx={{
                  backgroundColor: "#F1F3F4",
                  height: "62px",
                  width: "30px",
                  border: "0.842697px solid #A2CBFE",
                  borderRadius: "50px",
                  padding: "0",
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
              >
                <input
                  type="file"
                  hidden
                  accept=".pem"
                  key={inputKey}
                  onChange={handleFileChange}
                />
                {file ? (
                  <EditIcon sx={{ color: "#7B8A93", fontSize: "25px" }} />
                ) : (
                  <ClipBoard />
                )}
              </Button>
            </Grid>
          </Grid>

          <Grid
            container
            md={12}
            display="flex"
            sx={{
              width: "100%",
              paddingTop: "20px",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: "row-reverse",
            }}
          >
            <Grid
              item
              md={6}
              display="flex"
              width="100%"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Box>
                <Typography
                  fontFamily="Montserrat"
                  fontWeight="500"
                  color="#7B8A93"
                  fontSize={"13px"}
                >
                  SETUP NEXT VM
                </Typography>
              </Box>
              <Box
                onClick={() => {
                  if (validationButton()) return;
                  testVm();
                }}
                sx={{
                  height: "40px",
                  width: "40px",
                  border: "0.5px solid #DEE1E6",
                  alignContent: "center",
                  borderRadius: "10px",
                  padding: "7px",
                  marginLeft: "25px",
                  transform: "translateY(-15px)",
                  marginTop: "20px",
                  cursor: validationButton() ? "not-alowed" : "pointer",
                  boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
                  backgroundColor: validationButton() ? "#EBEBE4" : "#fff",
                }}
              >
                <AddSStandAlone />
              </Box>
            </Grid>

            {deployData.currentVm === "4 - COLLATOR" && (
              <Grid item md={6} justifyContent="flex-end">
                <Box display="flex" width="100%" alignItems="center">
                  <Box>
                    <Typography
                      fontFamily="Montserrat"
                      fontWeight="500"
                      color="#7B8A93"
                      fontSize={"13px"}
                      textAlign="right"
                    >
                      CLEAR ALL
                    </Typography>
                  </Box>
                  <Box
                    onClick={() => {
                      !loadVmSetup && deleteStack();
                    }}
                    sx={{
                      height: "30px",
                      width: "30px",
                      border: "0.5px solid #DEE1E6",
                      alignContent: "center",
                      borderRadius: "10px",
                      padding: "8px",
                      marginLeft: "30px",
                      transform: "translateY(-15px)",
                      marginTop: "20px",
                      cursor: loadVmSetup ? "not-alowed" : "pointer",
                      backgroundColor: loadVmSetup ? "#EBEBE4" : "#fff",
                      boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
                    }}
                  >
                    <Delete />
                  </Box>
                </Box>
              </Grid>
            )}
          </Grid>
        </>
      ) : (
        <>
          {(deployData.currentVm === "4 - COLLATOR" ||
            deployData.currentVm === "4 - lastNode") && (
            <Grid
              container
              md={12}
              width="100%"
              justifyContent="space-between"
              alignItems="center"
              sx={{ paddingTop: "1px" }}
            >
              <Box>
                <Grid item>
                  <Box width="100%">
                    <Box>
                      <Typography
                        fontFamily="Montserrat"
                        fontWeight="500"
                        color="#7B8A93"
                        fontSize={"13px"}
                        marginBottom="20px"
                      >
                        CLEAR ALL
                      </Typography>
                    </Box>
                    <Box
                      onClick={() => {
                        !loadVmSetup && deleteStack();
                      }}
                      sx={{
                        height: "30px",
                        width: "30px",
                        border: "0.5px solid #DEE1E6",
                        alignContent: "center",
                        borderRadius: "10px",
                        padding: "8px",
                        marginLeft: "20px",
                        transform: "translateY(-15px)",
                        // marginTop: "20px",
                        cursor: loadVmSetup ? "not-alowed" : "pointer",
                        backgroundColor: loadVmSetup ? "#EBEBE4" : "#fff",
                        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
                      }}
                    >
                      <Delete />
                    </Box>
                  </Box>
                </Grid>
              </Box>
              <Box>
                <Box>
                  <Typography
                    fontFamily="Montserrat"
                    fontWeight="500"
                    color="#7B8A93"
                    fontSize={"13px"}
                  >
                    SETUP NEXT VM
                  </Typography>
                </Box>
                <Box
                  onClick={() => {
                    if (validationButton()) return;
                    testVm();
                  }}
                  sx={{
                    height: "40px",
                    width: "40px",
                    border: "0.5px solid #DEE1E6",
                    alignContent: "center",
                    borderRadius: "10px",
                    padding: "7px",
                    marginLeft: "25px",
                    transform: "translateY(-15px)",
                    marginTop: "20px",
                    cursor: validationButton() ? "not-alowed" : "pointer",
                    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
                    backgroundColor: validationButton() ? "#EBEBE4" : "#fff",
                  }}
                >
                  <AddSStandAlone />
                </Box>
              </Box>

              <Box>
                <Box width="100%">
                  <Typography
                    fontFamily="Montserrat"
                    fontWeight="500"
                    color="#7B8A93"
                    fontSize={"11px"}
                    sx={{
                      paddingTop: "10px",
                    }}
                  >
                    UPLOAD VM
                    <br />
                    PRIVATE KEY
                  </Typography>
                </Box>
                <Button
                  variant="contained"
                  component="label"
                  sx={{
                    backgroundColor: "#F1F3F4",
                    height: "62px",
                    width: "30px",
                    border: "0.842697px solid #A2CBFE",
                    borderRadius: "50px",
                    padding: "0",
                    "&:hover": {
                      cursor: "pointer",
                    },
                  }}
                >
                  <input
                    type="file"
                    hidden
                    accept=".pem"
                    key={inputKey}
                    onChange={handleFileChange}
                  />
                  {file ? (
                    <EditIcon sx={{ color: "#7B8A93", fontSize: "25px" }} />
                  ) : (
                    <ClipBoard />
                  )}
                </Button>
              </Box>
            </Grid>
          )}
        </>
      )}

      {/* DIFERENTE */}
      {deployData.currentVm !== "4 - COLLATOR" &&
        deployData.currentVm !== "4 - lastNode" && (
          <Grid
            container
            md={12}
            sx={{ paddingTop: "10px", paddingLeft: "280px" }}
          >
            <Grid item md={12}>
              <Box
                display="flex"
                width="100%"
                alignItems="center"
                justifyContent="flex-end"
              >
                <Box>
                  <Typography
                    fontFamily="Montserrat"
                    fontWeight="500"
                    color="#7B8A93"
                    fontSize={"13px"}
                    textAlign="right"
                  >
                    CLEAR ALL
                  </Typography>
                </Box>
                <Box
                  onClick={deleteStack}
                  sx={{
                    height: "30px",
                    width: "30px",
                    border: "0.5px solid #DEE1E6",
                    alignContent: "center",
                    borderRadius: "10px",
                    padding: "8px",
                    marginLeft: "30px",
                    transform: "translateY(-15px)",
                    marginTop: "20px",
                    cursor: "pointer",
                    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
                  }}
                >
                  <Delete />
                </Box>
              </Box>
            </Grid>
          </Grid>
        )}
    </Box>
  );
};
