export const Deny: React.FC = () => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.5 16.5C4.08172 16.5 0.5 12.9183 0.5 8.5C0.5 4.08172 4.08172 0.5 8.5 0.5C12.9183 0.5 16.5 4.08172 16.5 8.5C16.5 12.9183 12.9183 16.5 8.5 16.5Z"
      stroke="#E14942"
    />
    <path
      d="M8.99092 8.49986L11.4812 5.5315C11.5229 5.48217 11.4878 5.40723 11.4233 5.40723H10.6663C10.6217 5.40723 10.579 5.42715 10.5496 5.4613L8.49572 7.90979L6.44187 5.4613C6.41341 5.42715 6.37072 5.40723 6.32519 5.40723H5.56816C5.50365 5.40723 5.46855 5.48217 5.51029 5.5315L8.00052 8.49986L5.51029 11.4682C5.50094 11.4792 5.49494 11.4927 5.493 11.507C5.49107 11.5213 5.49328 11.5358 5.49937 11.5489C5.50547 11.562 5.51518 11.5731 5.52738 11.5808C5.53957 11.5885 5.55372 11.5926 5.56816 11.5925H6.32519C6.36977 11.5925 6.41246 11.5726 6.44187 11.5384L8.49572 9.08993L10.5496 11.5384C10.578 11.5726 10.6207 11.5925 10.6663 11.5925H11.4233C11.4878 11.5925 11.5229 11.5176 11.4812 11.4682L8.99092 8.49986Z"
      fill="#E14942"
    />
  </svg>
);
