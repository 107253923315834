import { Box, Card, Grid, Typography } from "@mui/material";
import Header from "../../components/Platform/Header/Header"
import { HomeHeader } from '../../components/Platform/Home/HomeHeader/HomeHeader';
import { makeStyles } from '@material-ui/core/styles';
import { RecoveryWallet } from "./RecoveryWallet";


const useStyles = makeStyles(theme => ({
	[theme.breakpoints.down(1690)]: {
		cardContainer: {
			maxWidth: '1200px'
		}
	}
}));

export const Wallet = ({ user, userName, tokenUser, thereisOrganizationHeader }: any) => {
	const classes = useStyles();

	type TypeTitleCard = { title: string };
	const TitleCard: React.FC<TypeTitleCard> = ({ title }) => {
		return (
			<Typography
				color="#3A4049"
				fontFamily="Montserrat"
				fontSize="2.25rem"
				fontWeight="600"
				lineHeight="48px"
			>
				{title}
			</Typography>
		);
	};

	return (
		<>
			<Header userName={userName} thereisOrganizationHeader={thereisOrganizationHeader} />
			<HomeHeader />
			<Grid container md={12}>
				<Grid
					item
					md={12}
					marginTop="28px"
					display="flex"
					justifyContent="center"
				>
					<Card sx={{ marginTop: '36px', boxShadow: 'none' }}>
						<Box
							className={classes.cardContainer}
							sx={{
								width: '80vw',
								minHeight: '738px',
								backgroundColor: 'rgba(202,213,232,0.3)',
								borderRadius: '32px',
								padding: '61px'
							}}
						>
							<Card
								sx={{
									boxShadow: 'none',
									backgroundColor: 'transparent',
								}}
							>
								<Box
									display="flex"
									alignItems="center"
									justifyContent="space-between"
								>
									<TitleCard title="Wallet Recovery" />
									<Box display="flex" alignItems="center">
										<Typography
											color="#3A4049"
											fontSize="1.125rem"
											fontWeight="400"
											lineHeight="24px"
											marginRight="45px"
										>
											Recovering your wallet
										</Typography>
									</Box>
								</Box>
								<>{<RecoveryWallet tokenUser={tokenUser} wallet_address={"Hash..."} />}</>
							</Card>
						</Box>
					</Card>
				</Grid>
			</Grid>
		</>
	)
}