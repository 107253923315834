import React, { useState } from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { StyledPaymentMethod } from "../../Styles/PaymentMethod.styled";
// @ts-ignore
import creditLogo from "~/assets/cerditCardIcon.png";
// @ts-ignore
import contractIcon from "~/assets/contractIcon.png";




function PaymentMethod(props: {
  previousHandleClick: () => void;
  nextHandleClick: () => void;
  setFormInfo: (arg0: any) => void;
  formInfo: {
    userFirstName: string;
    userLastName: string;
    userEmail: string;
    userPhone: string;
    userJobTitle: string;
    userCPF: string;
    userPassword: string;
    enterpriseName: string;
    enterpriseCNPJ: string;
    enterpriseZipCode: string;
    entepriseAddress: string;
    entepriseNumber: string;
    entepriseComplement: string;
    entepriseCountry: string;
    entepriseState: string;
    entepriseCity: string;
    paymentMethod: string;
    userWalletAddress: string;
    userWalletName: string;
    userWalletPassword: string;
    enterpriseWalletAddress: string;
    enterpriseWalletName: string;
    enterpriseWalletPassword: string;
};
}) {
  
  const [isOtherMethodsSelected, setisOtherMethodsSelected] = useState(false);

  const handlePaymentButtonClick = (event: { }) => {
    setisOtherMethodsSelected(!isOtherMethodsSelected);
  }

  const handleSubmit = (event: { preventDefault: () => void;}) => {

    const data = {
      ...props.formInfo,
      "paymentMethod": "others"
    };

    
    props.setFormInfo(data)
    event.preventDefault(); // 👈️ prevent page refresh
    props.nextHandleClick();
  };
  return (
    <StyledPaymentMethod>
      <div className="Container">
        <div className="Header">
        <h1 className="paymentmethod-header">
        Please select your prefered
        <br/> payment method.
      </h1>
      </div>
        <div className="content">
          <div className="horizontal-div">
            <div className="payment-card">
              <button className="payment-button" disabled>
              <div className="paymentmethod-card">
                <img src={creditLogo} alt="" />
              </div>
              </button>
              </div>
                <div className="paymentmethod-divisor">
            </div>
            {isOtherMethodsSelected ?
            <div className="selected-container">
              <div className="payment-contract-selected">
              <button onClick={handlePaymentButtonClick}>
                <div className="paymentmethod-contract">
                  <img src={contractIcon} alt="" />
                </div>
              </button>
              </div>
              <div>
                <p>Thank you for your choice, soon a staff member will contact you.</p>
              </div>
            </div>
            :
            <div className="payment-contract">
            <button onClick={handlePaymentButtonClick}>
              <div className="paymentmethod-contract">
                <img src={contractIcon} alt="" />
              </div>
            </button>
            </div>}
          </div>
          <div className="horizontal-div">
            <div className="payment-card">Credit Card</div>
            <div className="payment-contract">Other Methods</div>
          </div>
        </div>
        <div className="footer">
          <div className="previous-step">
            <div style={{display: 'flex'}}>
              <AiOutlineArrowLeft scale={2} style={{alignSelf: 'center'}}/>
            <button onClick={props.previousHandleClick} className="previous-step-button"
            style={{cursor: 'pointer'}}>
              Previous Step
            </button>
            </div>
          </div>
          <div className="next-step">
            {isOtherMethodsSelected ?
          <button className="next-step-button-selected" onClick={handleSubmit} style={{cursor: 'pointer'}}>
            Select Payment
          </button>
          :
          <button className="next-step-button" disabled>
            Select Payment
          </button>
            }
          </div>
        </div>
      </div>
    </StyledPaymentMethod>
  );
}

export default PaymentMethod;
