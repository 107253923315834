// Core
import { border, colors } from "../themes";

export const customScroll = {
    '&::-webkit-scrollbar': {
        height: '14px',
        width: '20px',
    },

    '&::-webkit-scrollbar-track': {
        border: `1px solid ${colors.secondary[200]}`,
        borderRadius: border.radius.secondary,
    },

    '&::-webkit-scrollbar-thumb': {
        background: colors.primary[300],
        borderRadius: border.radius.secondary,
    },

    '&::-webkit-scrollbar-thumb:hover': {
        background: colors.primary[300],
    },
}
export const customThinScroll = {
    '&::-webkit-scrollbar': {
        height: '14px',
        width: '5.1px',
    },

    '&::-webkit-scrollbar-track': {
        border: `1px solid #7B8A93`,
        borderRadius: border.radius.secondary,
    },

    '&::-webkit-scrollbar-thumb': {
        background: 'rgba(123, 138, 147, 1)',
        borderRadius: border.radius.secondary,
    },

    '&::-webkit-scrollbar-thumb:hover': {
        background: colors.primary[300],
    },
}