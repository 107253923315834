import {
  Box,
  Card,
  InputAdornment,
  InputBase,
  MenuItem,
  OutlinedInput,
  Select,
  styled,
  Typography,
} from "@mui/material";
import corda from "../../../../../assets/imgs/corda.png";
import substrate from "../../../../../assets/imgs/substrate.png";
import nameSubstrate from "../../../../../assets/imgs/nameSubstrate.png";
import besu from "../../../../../assets/imgs/besu.png";
import jwt_decode from "jwt-decode";

import { useContext, useEffect, useState } from "react";
import { DeployBaasStepHeading } from "./DeployBaasStepHeading";
import { useLocation } from "react-router-dom";
import { SelectChangeEvent } from "@mui/material";

import { UserService } from "../../../../../services/user.services";
import { WizardContext } from "../../../../../context/wizardContext";

interface TOrganizationList {
  id: string;
  name: string;
}

interface TEnterpriseList {
  id: string;
  name: string;
  orgs: Array<any>;
}

const userService = new UserService();

export const DeployBaasNameDesc = ({ tokenUser, setSelectedOrg }: any) => {
  const [organizationList, setOrganizationList] = useState<
    Array<TOrganizationList>
  >([]);

  const [enterpriseList, setEnterpriseList] = useState<Array<TEnterpriseList>>(
    []
  );

  // @ts-ignore
  const { deployData, setDeployData } = useContext(WizardContext);

  const location = useLocation();
  const { idOrg } = location?.state || "";
  const [idOrganization, setIdOrganization] = useState<string | any>(idOrg);
  const [tokenValidate, setTokenValidate] = useState<string>(tokenUser);
  const [clickProtocol, setClickProtocol] = useState<number>(0);
  const [versionSelect, setVersionSelect] = useState<string>(
    deployData.version
  );

  const decodedToken: any = jwt_decode(tokenUser || "");

  const [styleProcol, setStyleProcol] = useState<Object>();

  const BootstrapInput = styled(InputBase)(() => ({
    "& .MuiInputBase-input": {
      display: "flex",
      padding: "8px 15px",
      alignItems: "center",
      textAlign: "start",
      borderRadius: "10px",
      backgroundColor: "#FAFAFA",
      border: "1px solid #A2CBFE",
      fontWeight: "500",
      fontFamily: "Montserrat",
      fontSize: "12px",
      color: "#7B8A93",
      letterSpacing: "1px",
      "&:focus": {
        borderRadius: "10px",
      },
    },
    ".MuiSvgIcon-root": {
      right: "10px",
    },
  }));

  const protocols = [
    {
      id: 1,
      name: "substrate",
      imgName: substrate,
    },
    {
      id: 2,
      name: "besu",
      imgName: besu,
    },
    {
      id: 3,
      name: "R3 Corda",
      imgName: corda,
    },
  ];

  useEffect(() => {
    const { selectedOrg, enterpriseId, networkName, protocolType } = deployData;
    if (selectedOrg && enterpriseId && networkName && protocolType) {
      setDeployData({ ...deployData, buttonColor: "#1976d2" });
    } else {
      setDeployData({ ...deployData, buttonColor: "#7b8a93" });
    }
  }, [
    deployData.selectedOrg,
    deployData.enterpriseId,
    deployData.networkName,
    deployData.protocolType,
  ]);

  const changeOrganization = (event: SelectChangeEvent) => {
    const orgNameSelect = event.target.value;

    const idOrgSelect = organizationList.find(
      (org) => org.name === orgNameSelect
    )?.id;

    setIdOrganization(idOrgSelect);
    setSelectedOrg(idOrgSelect);
    setDeployData({
      ...deployData,
      selectedOrg: orgNameSelect,
      orgId: idOrgSelect,
    });
  };

  const changeEnterprise = (event: SelectChangeEvent) => {
    setOrganizationList([]);
    const enterpriseNameSelect = event.target.value;

    const idEnterpriseSelect = enterpriseList.find(
      (enterprise) => enterprise.name === enterpriseNameSelect
    )?.id;

    const orgsSelected = enterpriseList.find(
      (enterprise) => enterprise.name === enterpriseNameSelect
    )?.orgs;

    setDeployData({
      ...deployData,
      selectedEnterprise: enterpriseNameSelect,
      enterpriseId: idEnterpriseSelect,
    });

    if (orgsSelected) {
      const respFilter = orgsSelected.filter((orgs: any) => {
        if (orgs.permission === "stakeholder") {
          setSelectedOrg("");
          setDeployData({
            ...deployData,
            selectedEnterprise: enterpriseNameSelect,
            selectedOrg: "",
            orgId: "",
          });
        }

        return orgs.permission !== "stakeholder";
      });

      respFilter.map((org: any) => {
        setOrganizationList((oldArray) => [
          ...oldArray,
          { id: org.id, name: org.name },
        ]);
      });
    }
  };

  const getOrgList = async () => {
    try {
      const respOrganizations = await userService.getUserOrgList(
        decodedToken.sub,
        tokenValidate
      );
      if (respOrganizations.data.length) {
        respOrganizations.data.map((org: any) => {
          setOrganizationList((oldArray) => [
            ...oldArray,
            { id: org.id, name: org.name },
          ]);
        });
      }
    } catch (error) {}
  };

  const getEnterpriseList = async () => {
    try {
      const respEnterprise = await userService.getUserEnterpriseList(
        decodedToken.sub,
        tokenValidate
      );

      if (respEnterprise.data.length) {
        respEnterprise.data.map((ent: any) => {
          setEnterpriseList((oldArray) => [
            ...oldArray,
            { id: ent.id, name: ent.name, orgs: ent.orgs },
          ]);
        });
      }
    } catch (error) {}
  };

  const styleTypeProtocol = {
    backgroundColor: "#d9e8f5",
    border: "1px solid #1781E2",
  };

  const protocolType = (protocolType: string, id: number) => {
    id === 1
      ? setVersionSelect("4.0")
      : id === 2
      ? setVersionSelect("23.10.0")
      : setVersionSelect("4.5");

    setClickProtocol(id);
    setStyleProcol(styleTypeProtocol);
    setDeployData({
      ...deployData,
      protocolType: protocolType,
      buttonColor: " ",
      version: id === 1 ? "4.0" : id === 2 ? "23.10.0" : "4.5",
    });
  };

  useEffect(() => {
    getEnterpriseList();
    if (deployData.selectedEnterprise) {
      getOrgList();
    }

    switch (deployData.protocolType) {
      case "substrate":
        setClickProtocol(1);
        setStyleProcol(styleTypeProtocol);
        setDeployData({
          ...deployData,
          consesus: "",
          buttonColor: " ",
        });
        break;
      case "besu":
        setClickProtocol(2);
        setStyleProcol(styleTypeProtocol);
        break
      case "R3 Corda":
        setClickProtocol(3);
        setStyleProcol(styleTypeProtocol);
        break
    }
  }, []);

  const handleVersion = (e: any) => {
    setVersionSelect(e.target.value);
    setDeployData({ ...deployData, version: e.target.value });
  };

  const VersionCard = () => {
    return (
      <Card sx={{ boxShadow: "none", paddingTop: "0px" }}>
        <Box>
          <Select
            fullWidth
            input={<BootstrapInput />}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            variant="standard"
            value={versionSelect}
            label="select"
            onChange={(e) => handleVersion(e)}
            endAdornment={
              <InputAdornment
                position="end"
                sx={{
                  position: "absolute",
                  right: "40px",
                }}
              >
                <span
                  style={{
                    transform: "rotate(180deg)",
                    display: "block",
                    borderRight: "1px solid #A2CBFE",
                    height: "40px",
                  }}
                ></span>
              </InputAdornment>
            }
            sx={{
              minWidth: "168px",
              ":before": {
                borderRight: "1px solid #A2CBFE",
                height: "40px",
              },
            }}
          >
            <MenuItem value={"0"}>Select</MenuItem>

            {deployData.protocolType === "besu" ? (
              <MenuItem value={"23.10.0"}>23.10.0</MenuItem>
            ) : deployData.protocolType === "substrate" ? (
              <MenuItem value={"4.0"}>4.0</MenuItem>
            ) : (
              <MenuItem value={"4.5"}>4.5</MenuItem>
            )}
          </Select>
        </Box>
      </Card>
    );
  };

  return (
    <Box>
      <DeployBaasStepHeading
        title="Name and Description"
        description="Select the organization responsable for your network and the protocol you need to run on"
      />
      <Box>
        <Typography
          fontFamily="Montserrat"
          fontWeight="600"
          color="#3E474D"
          fontSize={"13px"}
          sx={{
            paddingBottom: "10px",
          }}
        >
          Enterprise
        </Typography>
        <Box>
          <Select
            fullWidth
            defaultValue={1}
            input={<BootstrapInput />}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            variant="standard"
            value={deployData.selectedEnterprise}
            label="Age"
            onChange={(e) => changeEnterprise(e)}
            endAdornment={
              <InputAdornment
                position="end"
                sx={{
                  position: "absolute",
                  right: "40px",
                }}
              >
                <span
                  style={{
                    transform: "rotate(180deg)",
                    display: "block",
                    borderRight: "1px solid #A2CBFE",
                    height: "40px",
                  }}
                ></span>
              </InputAdornment>
            }
            sx={{
              ":before": {
                borderRight: "1px solid #A2CBFE",
                height: "100%",
              },
            }}
          >
            {enterpriseList.map(({ id, name }) => (
              <MenuItem key={id} value={name}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Box>
      <Box sx={{ paddingTop: "16px" }}>
        <Typography
          fontFamily="Montserrat"
          fontWeight="600"
          color="#3E474D"
          fontSize={"13px"}
          sx={{
            paddingBottom: "10px",
          }}
        >
          Organization
        </Typography>
        <Box>
          <Select
            fullWidth
            defaultValue={1}
            input={<BootstrapInput />}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            variant="standard"
            value={deployData.selectedOrg}
            label="Age"
            onChange={(e) => changeOrganization(e)}
            endAdornment={
              <InputAdornment
                position="end"
                sx={{
                  position: "absolute",
                  right: "40px",
                }}
              >
                <span
                  style={{
                    transform: "rotate(180deg)",
                    display: "block",
                    borderRight: "1px solid #A2CBFE",
                    height: "40px",
                  }}
                ></span>
              </InputAdornment>
            }
            sx={{
              ":before": {
                borderRight: "1px solid #A2CBFE",
                height: "40px",
              },
            }}
          >
            {organizationList.length > 0 &&
              organizationList.map(({ id, name }) => (
                <MenuItem key={id} value={name}>
                  {name}
                </MenuItem>
              ))}
          </Select>
        </Box>
      </Box>
      <Box>
        <Typography
          fontFamily="Montserrat"
          fontWeight="600"
          color="#3E474D"
          fontSize={"13px"}
          sx={{
            paddingTop: "16px",
            paddingBottom: "10px",
          }}
        >
          Network Name
        </Typography>
        <OutlinedInput
          placeholder="e.g NetworkName (no spaces)"
          type="string"
          value={deployData.networkName}
          onChange={(e) =>
            setDeployData({
              ...deployData,
              networkName: e.target.value
                .replace(" ", "")
                .replace(/[^\w\s]/gi, "")
                .replace("_", ""),
            })
          }
          sx={{
            "& .MuiOutlinedInput-notchedOutline": {
              border: "1px solid #A2CBFE",
              "&:hover": {
                borderColor: "#A2CBFE",
              },
            },
            width: "100%",
            height: "40px",
            backgroundColor: "#FAFAFA",
            borderRadius: "10px",
            padding: "0",
            boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
          }}
        />
      </Box>
      <Box>
        <Box>
          <Typography
            fontFamily="Montserrat"
            fontWeight="600"
            color="#3E474D"
            fontSize={"13px"}
            sx={{
              paddingTop: "31.41px",
              marginBottom: "15px",
            }}
          >
            Protocol
          </Typography>
          <Card
            sx={{
              display: "flex",
              "@media (max-width: 1500px)": {
                flexDirection: "column",
              },
              boxShadow: "none",
              marginBottom: "37px",
              justifyContent: "space-between",
            }}
          >
            <Box display="flex">
              {protocols.map(({ name, imgName, id }) => (
                <>
                  <Box display="flex" flexDirection="column">
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontFamily: "Montserrat",
                        fontWeight: "500",
                        lineHeight: "14px",
                        color: "#5F6368",
                        letterSpacing: "1px",
                        marginBottom: "8px",
                      }}
                    >
                      {name}
                    </Typography>
                    <Box
                      onClick={() => protocolType(name, id)}
                      style={clickProtocol === id ? styleProcol : undefined}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginRight: "16px",
                        width: "96px",
                        height: "35px",
                        backgroundColor: "#F1F3F4",
                        border: "1px solid #DEE1E6",
                        cursor: "pointer",
                      }}
                    >
                      <img src={imgName} alt="procols" />
                      {name === "substrate" && (
                        <Box
                          display="flex"
                          alignItems="center"
                          flexDirection="column"
                          paddingTop="7px"
                        >
                          <Typography
                            fontFamily="Montserrat"
                            fontWeight="400"
                            fontSize="9px"
                            lineHeight="3px"
                          >
                            multiledgers
                          </Typography>
                          <div>
                            <img src={nameSubstrate} alt="substrate" />
                          </div>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </>
              ))}
            </Box>
          </Card>
          <Box width="100%" display="flex" flexDirection="row-reverse">
            <Box alignSelf="flex-end">
              <VersionCard />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
