import styled from "styled-components";

export const StyledOwnerWalletRegister = styled.div`
  padding-bottom: 18%;
  padding-left: 3%;

  .container-owner-wallet{
    background-color: #1781E2;
    height: 880px;
    width: 100%;
    padding: 52px 35px 45px 40px;
  }

  .header-title{
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 0px;
    text-align: left;
    padding-top: 0px;
  }
  
  .header-subtitle{
    margin-top: 45px;
    width: 550px;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 400;
    line-height: 33px;
    letter-spacing: 0px;
    text-align: left;
    margin: 0px;
    margin-top: 12px;
  }

  .content { 
    // border: 1px solid black;
    width: 600px;
    height: 390px;
    margin-top: 90px;

    h1 {
        margin: 0px;
        margin-bottom: 8px;
        font-size: 20px;
    }

    h5 {
        margin: 0px;
        margin-bottom: 26px;
        margin-top: 8px;
        font-size: 16px;
    }

    input {
        width: 411px;
        height: 44px;
        padding-left: 16px;
        margin: 0px;
        display: inline-block;
        border: 1px solid #CAD5E8;
        border-radius: 4px;
        box-sizing: border-box;
        background-color: #fff;
        ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #CAD5E8;
        opacity: 1; /* Firefox */
        }
    }
  }

  .footer{
    display: flex;
    margin-top: 170px;
    height: 45px;
    width: 100%;
    // border: 1px solid black;
    justify-content: right;
    justify-itens: right;
    justify-self: right;
    align-content: right;

    button {
        display: flex;
        width: 280px;
        margin: 0px;
        padding: 0px;
        justify-content: center;
        align-self: right;
        line-height: 45px;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0px;
        text-align: center;
        color: #48535C;
        text-transform: capitalize;

        img {
            height: 35px;
            width: 40px;
            margin: 0px;
            padding: 0px;
            margin-left: 6px;
            filter: grayscale(100%);
            align-self: center;
        }
    }
}




`;
