import React from 'react';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import './ProgessBar.css';

function ProgressBar(props: { signUpStep: number; }) {

    return (
        <div className="test-deve">
        <div className="container-progress">
            <div className="progress-container">
                <div className="progress" id="progress"> </div>
                <div className={`circle ${props.signUpStep > 0 ? `active`: ''}`}>{props.signUpStep > 0 ? `✓`: '1'}</div>
                <div className={`circle ${props.signUpStep > 1 ? `active`: ''}`}>{props.signUpStep > 1 ? `✓`: '2'}</div>
                <div className={`circle ${props.signUpStep > 2 ? `active`: ''}`}>{props.signUpStep > 2 ? `✓`: '3'}</div>
                <div className={`circle ${props.signUpStep > 4 ? `active`: ''}`}>{props.signUpStep > 4 ? `✓`: '4'}</div>
                <div className={`circle ${props.signUpStep > 6 ? `active`: ''}`}>{props.signUpStep > 6 ? `✓`: '5'}</div>
                <div className={`circle ${props.signUpStep > 6 ? `active`: ''}`}>{props.signUpStep > 6 ? `✓`: '6'}</div>
            </div>
        </div>
        <div className="step-labels">
            <div className="step-label">
                <h2>Owner</h2>
                <p>Info and security</p>
            </div>
            <div className="step-label">
                <h2>Enterprise</h2>
                <p>Company details</p>
            </div>
            <div className="step-label">
                <h2>Payment</h2>
                <p>Select a method</p>
            </div>
            <div className="step-label">
                <h2>Owner Wallet</h2>
                <p>Create an owner wallet</p>
            </div>
            <div className="step-label">
                <h2>Enterprise Wallet</h2>
                <p>Create an enterprise wallet </p>
            </div>
            <div className="step-label">
                <h2>Conclusion</h2>
            </div>
        </div>
        </div>
    );
}

export default ProgressBar;
