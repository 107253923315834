import React, { FC } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import SvgIcon from '@mui/material/SvgIcon';
import { fonts, colors } from '../../../style/themes';
import FlagCard from '../../../assets/imgs/flagCard.png'
import { formatTextLimit } from '../../../utils/formatTextLenght';

interface ISubItem {
  data: { [key: string]: string }
  selectedKit: string
}

const SubItem: FC<ISubItem> = ({ data, selectedKit }) => {
  return (
    <Box
      sx={{
        width: '95.6%',
        margin: '8px 0 8px auto',
        display: 'flex',
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: '100%',
          padding: '9px 12px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexWrap: 'nowrap',
          border: '1.14608px solid #1781E2',
          background: '#FAFAFA',
          borderRadius: '11.4608px'
        }}>
        <SvgIcon viewBox="-3 -3 24 24">
          <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M1.88878 2.83331H15.111C15.6326 2.83331 16.0554 3.25616 16.0554 3.77776V8.6747C15.7887 8.34083 15.4698 8.05222 15.111 7.81998V3.77776H1.88878V13.2222H9.63795L9.68045 13.2978L9.24128 13.8361L8.97211 14.1666H1.88878C1.36718 14.1666 0.944336 13.7438 0.944336 13.2222V3.77776C0.944336 3.25616 1.36718 2.83331 1.88878 2.83331ZM3.30545 5.66665H11.3332V6.4222H3.30545V5.66665ZM8.49989 7.55554H3.30545V8.31109H8.49989V7.55554ZM12.9648 8.13635C11.729 8.1275 10.6138 8.87661 10.1547 10.0242C9.69541 11.1725 9.98699 12.485 10.8893 13.3308L9.97323 14.45L10.3982 16.1547L12.1266 14.0486C12.6736 14.2104 13.2559 14.2104 13.8029 14.0486L15.5313 16.1547L15.9563 14.45L15.0402 13.3308C15.9425 12.485 16.2341 11.1725 15.7748 10.0242C15.3157 8.87661 14.2006 8.1275 12.9648 8.13635ZM10.9603 11.1258C10.9499 12.2461 11.8469 13.1641 12.9671 13.1797C14.0979 13.1849 15.0203 12.2752 15.0307 11.1444C15.0306 10.0241 14.1251 9.11449 13.0048 9.10936C11.8844 9.10424 10.9706 10.0055 10.9603 11.1258ZM3.30545 10.8611H8.02767V11.6166H3.30545V10.8611Z" fill="#1781E2" />
          </svg>
        </SvgIcon>
        <Box
          sx={{
            fontSize: fonts.size[80],
            fontFamily: fonts.family.secondary,
            fontWeight: fonts.weight[600],
            color: colors.primary[100],
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '94px',
            height: '25px',
            background: '#7B8A93',
            borderRadius: '5px',
            marginLeft: '15.94px',
            marginRight: '14px',
          }}>
          {data?.['id'].slice(0, 13)}...
        </Box>
        <Box
          sx={{
            fontSize: fonts.size[80],
            fontFamily: fonts.family.secondary,
            fontWeight: fonts.weight[600],
            color: colors.primary[100],
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '94px',
            height: '25px',
            background: '#7B8A93',
            borderRadius: '5px',
            marginRight: '22.98px',
          }}>
            VM
          {/* {data?.['vm_sub_name']} */}
        </Box>
        <Box
          sx={{
            fontSize: fonts.size[100],
            fontFamily: fonts.family.secondary,
            fontWeight: fonts.weight[600],
            color: colors.primary[800],
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
            width: '107px',
            height: '25px',
          }}>
            {formatTextLimit(data?.['name'], 13)}
          {/* {'Node - substrate'} */}
        </Box>
        <SvgIcon>
          <svg viewBox="-3 0 3 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.5">
              <path d="M1.20384 0.650268C1.04575 0.650268 0.8892 0.683776 0.74314 0.748879C0.597079 0.813982 0.464366 0.909404 0.352576 1.0297C0.240787 1.14999 0.15211 1.2928 0.0916097 1.44997C0.0311093 1.60715 -2.9848e-05 1.7756 -2.98549e-05 1.94572C-2.98619e-05 2.11584 0.0311093 2.2843 0.0916096 2.44147C0.15211 2.59864 0.240786 2.74145 0.352576 2.86175C0.464366 2.98204 0.597079 3.07746 0.74314 3.14257C0.8892 3.20767 1.04575 3.24118 1.20384 3.24118C1.36194 3.24118 1.51848 3.20767 1.66454 3.14257C1.8106 3.07746 1.94332 2.98204 2.05511 2.86175C2.1669 2.74145 2.25557 2.59864 2.31608 2.44147C2.37658 2.2843 2.40771 2.11584 2.40771 1.94572C2.40771 1.7756 2.37658 1.60715 2.31608 1.44997C2.25558 1.2928 2.1669 1.14999 2.05511 1.0297C1.94332 0.909404 1.8106 0.813982 1.66454 0.748879C1.51848 0.683776 1.36194 0.650269 1.20384 0.650268ZM1.20384 7.12754C0.884555 7.12754 0.578346 7.26403 0.352576 7.50697C0.126806 7.74992 -3.01041e-05 8.07942 -3.01181e-05 8.423C-3.0132e-05 8.76657 0.126806 9.09608 0.352576 9.33902C0.578346 9.58197 0.884555 9.71845 1.20384 9.71845C1.52313 9.71845 1.82934 9.58197 2.05511 9.33902C2.28088 9.09608 2.40771 8.76657 2.40771 8.423C2.40771 8.07942 2.28088 7.74992 2.05511 7.50697C1.82934 7.26403 1.52313 7.12754 1.20384 7.12754ZM1.20384 13.6048C0.884555 13.6048 0.578346 13.7413 0.352576 13.9842C0.126806 14.2272 -3.03672e-05 14.5567 -3.03812e-05 14.9003C-3.03951e-05 15.2438 0.126806 15.5733 0.352576 15.8163C0.578346 16.0592 0.884555 16.1957 1.20384 16.1957C1.52313 16.1957 1.82934 16.0592 2.05511 15.8163C2.28088 15.5733 2.40771 15.2438 2.40771 14.9003C2.40771 14.5567 2.28088 14.2272 2.05511 13.9842C1.82934 13.7413 1.52313 13.6048 1.20384 13.6048Z" fill="#48535C" fillOpacity="0.5" />
            </g>
          </svg>
        </SvgIcon>
        <Box
          sx={{
            fontSize: fonts.size[100],
            fontFamily: fonts.family.secondary,
            fontWeight: fonts.weight[600],
            color: colors.primary[800],
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
            width: '128px',
            height: '25px',
            marginLeft: '6px',
          }}>
          {'Multiledgers Network'}
        </Box>
        <Box display="flex" alignItems="center">
          <Box
            sx={{
              // width: '80px',
              marginRight: selectedKit !== 'baas' ? '40px' : '14px'
            }}>
            <Typography
              sx={{
                fontSize: fonts.size[100],
                fontFamily: fonts.family.secondary,
                fontWeight: fonts.weight[600],
                color: colors.primary[900],
              }}>
              {Number(data?.['current_value']).toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
            </Typography>
            <Typography
              sx={{
                fontSize: fonts.size[80],
                fontFamily: fonts.family.secondary,
                fontWeight: fonts.weight[600],
                color: colors.primary[800],
              }}>
              {'Current Value'}
            </Typography>
          </Box>
          <Box
            sx={{
              marginRight: selectedKit !== 'baas' ? '44px' : '14px',
              fontSize: fonts.size[80],
              fontFamily: fonts.family.secondary,
              fontWeight: fonts.weight[600],
              color: colors.primary[800],
            }}>
            <Typography
              sx={{
                fontSize: fonts.size[100],
                fontFamily: fonts.family.secondary,
                fontWeight: fonts.weight[600],
                color: colors.primary[900],
              }}>
              {Number(data?.['value_hour']).toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
            </Typography>
            <Typography
              sx={{
                fontSize: fonts.size[80],
                fontFamily: fonts.family.secondary,
                fontWeight: fonts.weight[600],
                color: colors.primary[800],
              }}>
              {'Per hour'}
            </Typography>
          </Box>
          {selectedKit === 'baas' &&
            <Box
              sx={{
                marginRight: '10px'
              }}>
              <Typography
                sx={{
                  fontSize: fonts.size[100],
                  fontFamily: fonts.family.secondary,
                  fontWeight: fonts.weight[600],
                  color: colors.primary[900],
                }}>
                {Number(data?.['month_value']).toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </Typography>
              <Typography
                sx={{
                  fontSize: fonts.size[80],
                  fontFamily: fonts.family.secondary,
                  fontWeight: fonts.weight[600],
                  color: colors.primary[800],
                }}>
                {'Month Est. Value'}
              </Typography>
            </Box>
          }
        </Box>
        <Box
          sx={{
            width: '70px',
          }}>
          <Typography
            sx={{
              fontSize: fonts.size[100],
              fontFamily: fonts.family.secondary,
              fontWeight: fonts.weight[600],
              color: colors.secondary[900],
            }}>
            {'Deployed'}
          </Typography>
          <Typography
            sx={{
              fontSize: fonts.size[80],
              fontFamily: fonts.family.secondary,
              fontWeight: fonts.weight[600],
              color: colors.primary[800],
            }}>
            {'Status'}
          </Typography>
        </Box>
        <Box
          sx={{
            padding: '5px 8px',
            fontSize: fonts.size[100],
            fontFamily: fonts.family.secondary,
            fontWeight: fonts.weight[600],
            color: colors.secondary[600],
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '94px',
            height: '25px',
            background: '#FFFFFF',
            borderRadius: '5px',
          }}>
          <img src={FlagCard}></img>
          <SvgIcon sx={{ fontSize: '17px' }}>
            <svg viewBox="-3 0 3 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g opacity="0.5">
                <path d="M1.20384 0.650268C1.04575 0.650268 0.8892 0.683776 0.74314 0.748879C0.597079 0.813982 0.464366 0.909404 0.352576 1.0297C0.240787 1.14999 0.15211 1.2928 0.0916097 1.44997C0.0311093 1.60715 -2.9848e-05 1.7756 -2.98549e-05 1.94572C-2.98619e-05 2.11584 0.0311093 2.2843 0.0916096 2.44147C0.15211 2.59864 0.240786 2.74145 0.352576 2.86175C0.464366 2.98204 0.597079 3.07746 0.74314 3.14257C0.8892 3.20767 1.04575 3.24118 1.20384 3.24118C1.36194 3.24118 1.51848 3.20767 1.66454 3.14257C1.8106 3.07746 1.94332 2.98204 2.05511 2.86175C2.1669 2.74145 2.25557 2.59864 2.31608 2.44147C2.37658 2.2843 2.40771 2.11584 2.40771 1.94572C2.40771 1.7756 2.37658 1.60715 2.31608 1.44997C2.25558 1.2928 2.1669 1.14999 2.05511 1.0297C1.94332 0.909404 1.8106 0.813982 1.66454 0.748879C1.51848 0.683776 1.36194 0.650269 1.20384 0.650268ZM1.20384 7.12754C0.884555 7.12754 0.578346 7.26403 0.352576 7.50697C0.126806 7.74992 -3.01041e-05 8.07942 -3.01181e-05 8.423C-3.0132e-05 8.76657 0.126806 9.09608 0.352576 9.33902C0.578346 9.58197 0.884555 9.71845 1.20384 9.71845C1.52313 9.71845 1.82934 9.58197 2.05511 9.33902C2.28088 9.09608 2.40771 8.76657 2.40771 8.423C2.40771 8.07942 2.28088 7.74992 2.05511 7.50697C1.82934 7.26403 1.52313 7.12754 1.20384 7.12754ZM1.20384 13.6048C0.884555 13.6048 0.578346 13.7413 0.352576 13.9842C0.126806 14.2272 -3.03672e-05 14.5567 -3.03812e-05 14.9003C-3.03951e-05 15.2438 0.126806 15.5733 0.352576 15.8163C0.578346 16.0592 0.884555 16.1957 1.20384 16.1957C1.52313 16.1957 1.82934 16.0592 2.05511 15.8163C2.28088 15.5733 2.40771 15.2438 2.40771 14.9003C2.40771 14.5567 2.28088 14.2272 2.05511 13.9842C1.82934 13.7413 1.52313 13.6048 1.20384 13.6048Z" fill="#48535C" fillOpacity="0.5" />
              </g>
            </svg>
          </SvgIcon>
          {'9245'}
        </Box>

      </Box>
    </Box>
  );
};

export default SubItem;
