import Api from "./api/axios-config";

export class EnterpriseService {
  async getEnterpriseResourcesAmount(enterpriseID: string, token: string) {
    return Api(token).get(`/enterprise/${enterpriseID}/resources/total`);
  }

  async getEntepriseMembersAmount(enterpriseID: string, token: string) {
    return Api(token).get(`/enterprise/${enterpriseID}/members`);
  }

  async getEntepriseNetworks(enterpriseID: string, token: string) {
    return Api(token).get(`/resourceGroup/enterprise`);
  }
}
