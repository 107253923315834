import { Box, Grid, Skeleton } from "@mui/material";

export function SkeletonHome() {
	return (
		<>
			<Grid
				marginTop="100px"
				container
				md={12}
				sx={{ marginTop: "120px", justifyItems: "space-around" }}
			>
				<Grid item md={4} sx={{ paddingLeft: "8%" }} alignSelf="stretch">
					<Skeleton
						variant="rounded"
						sx={{ borderRadius: "20px", marginRight: "60px" }}
						height="100%"
					/>
				</Grid>
				<Grid item md={8} sx={{ paddingRight: "8%" }} alignSelf="center">
					<Grid item md={12}>
						<Skeleton
							variant="rounded"
							sx={{
								borderRadius: "20px",
								marginRight: "20px",
								marginBottom: "30px",
							}}
							height="60px"
						/>
					</Grid>
					<Grid container md={12} gap={3} wrap="nowrap" marginBottom="22px">
						<Grid item md={4}>
							<Skeleton
								variant="rounded"
								sx={{ borderRadius: "20px" }}
								height={190}
							/>
						</Grid>
						<Grid item md={4}>
							<Skeleton
								variant="rounded"
								sx={{ borderRadius: "20px" }}
								height={190}
							/>
						</Grid>
						<Grid item md={2} lg={4}>
							<Skeleton
								variant="rounded"
								sx={{ borderRadius: "20px" }}
								height={190}
							/>
						</Grid>
					</Grid>
					<Grid container md={12} gap={3} wrap="nowrap">
						<Grid item md={8}>
							<div
								style={{
									position: "relative",
									height: "100%",
									width: "100%",
								}}
							>
								<Skeleton
									variant="rounded"
									sx={{ borderRadius: "20px" }}
									height={410}
								/>
							</div>
						</Grid>
						<Grid item md={3.83}>
							<Skeleton
								variant="rounded"
								sx={{ borderRadius: "20px" }}
								height={410}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
}
