import { Box, Typography } from "@mui/material";
import { Money } from "~/assets";
import dayjs from "dayjs";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  Title,
  Tooltip,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  Title,
  Tooltip
);
interface IQuickMiniFinancial {
  dataTable: any;
  orgPage?: boolean;
}

const QuickMiniFinancial = ({
  dataTable,
  orgPage = false,
}: IQuickMiniFinancial) => {
  let msgEmpty;
  if (dataTable.length === 0) {
    msgEmpty = "No Data";
  }

  const labels = dataTable.map((item: any) => dayjs(item.day).format("D MMM"));
  const costs = dataTable.map((item: any) => item.cost);

  const totalCost: number = costs.reduce(
    (accumulator: number, currentValue: number) => accumulator + currentValue,
    0
  );
  const formattedCost: string = totalCost.toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
  });

  const data = {
    labels,
    tooltip: {
      enabled: false,
      mode: "nearest",
      intersect: false,
      backgroundColor: "rgba(75,192,192,0.7)",
      titleFontColor: "#fff",
      bodyFontColor: "#fff",
      displayColors: false,
    },
    datasets: [
      {
        fill: {
          target: "origin",
          above: "#DEE1E6",
        },
        data: costs,
        borderColor: "#06F810",
        tension: 0.4,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    layout: {
      padding: {
        // bottom: orgPage ? 100 : 75,
      },
    },
    elements: {
      point: {
        radius: 3,
      },
    },
    scales: {
      x: {
        display: false,
        grid: {
          display: true,
        },
      },
      y: {
        display: false,
        grid: {
          display: true,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        titleMarginBottom: -10,
        interaction: {
          mode: "nearest",
        },
        callbacks: {
          label: function (context: any) {
            let label = context.dataset.label || "";

            if (label) {
              label += ": ";
            }
            if (context.parsed.y !== null) {
              label += new Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(context.parsed.y);
            }
            return `${context.label}: ${label}`;
          },
        },
        backgroundColor: "#DEE1E6",
        titleColor: "transparent",
        bodyColor: "#5F6368",
        cornerRadius: 20,
        displayColors: false,
        boxWidth: 100,
        rtl: false,
      },
    },
  };

  return (
    <div
      className="info-card first-row-card"
      style={{
        height: orgPage ? "120px" : "",
        borderRadius: "30px",
        paddingBottom: "20px",
      }}
    >
      <div className="members-heading">
        <Box display="flex">
          <h3 className="home-active-licenses">QUICK FINANCIAL</h3>
          <Typography
            marginLeft="20px"
            color="#7B8A93"
            fontSize="16px"
            fontWeight="600"
            fontFamily="Montserrat"
          >
            {formattedCost}
          </Typography>
        </Box>
        <div className="node-icon-image">
          <Money />
        </div>
      </div>
      <Typography
        marginLeft="35px"
        marginTop="6px"
        fontFamily="Montserrat"
        fontWeight="400"
        fontSize={12}
        letterSpacing="0.25px"
        color={"#7B8A93"}
        marginBottom="10px"
      >
        Expenses up to Date
      </Typography>
      <Box
        sx={{
          justifyContent: "left",
        }}
      >
        <Box
          sx={{
            marginLeft: "25px",
          }}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            color="#7B8A93"
            paddingTop={dataTable.length < 1 ? "10px" : "0"}
          >
            {msgEmpty}
          </Box>
        </Box>
      <Line options={options} data={data} height="80px" />
        <Box sx={{ marginTop: "0px", marginLeft: "20px" }}></Box>
      </Box>
    </div>
  );
};

export default QuickMiniFinancial;
