import React, { FC, useEffect, useRef } from 'react';

import Grid from '@mui/material/Grid';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

import { useParams } from 'react-router-dom';

import { IObjectData6Page4 } from '../../type';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

interface IProps {
  setSelectedSubitem: Function;
  isChartZooming: boolean;
  filteredData: IObjectData6Page4[] | null;
}
interface IChartData {
  data: IObjectData6Page4[];
  label: string[];
}

interface IColorLineSet {
  borderColor: string;
  backgroundColor: string;
}


export const ChartVmStatus: FC<IProps> = (props) => {
  const { setSelectedSubitem, filteredData, isChartZooming } = props;
  const { dataKey, index } = useParams();
  const wrapperInstance = useRef<HTMLDivElement>(null);

  // const [chartData, setChartData] = useState<IChartData>({ data: [], label: [] })

  const colorLineSet: IColorLineSet[] = [
    { borderColor: 'rgb(53, 162, 235)', backgroundColor: 'rgba(53, 162, 235, 0.5)' },
    { borderColor: 'rgb(255, 99, 132)', backgroundColor: 'rgba(255, 99, 132, 0.5)' },
    { borderColor: 'rgb(24, 116, 28)', backgroundColor: 'rgba(24, 116, 28, 0.5)' },
    { borderColor: 'rgb(31, 60, 80)', backgroundColor: 'rgba(31, 60, 80, 0.5)' },
    { borderColor: 'rgb(160, 25, 55)', backgroundColor: 'rgba(160, 25, 55, 0.5)' },
    { borderColor: 'rgb(68, 77, 17)', backgroundColor: 'rgba(68, 77, 17, 0.5)' },

  ]
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    tension: 0.4,
    plugins: {
    },
  };

  const getDataObj = () => {
    if (filteredData?.length) {
      const valueCounter: string[] = filteredData
        ? Object.keys(filteredData[0]).filter(i => (i !== 'x' && i !== 'timestamp'))
        : []
      return {
        labels: filteredData?.map(i => i.x),
        datasets: valueCounter.map((item: string, index: number) => {
          return {
            label: item,
            data: filteredData,
            ...colorLineSet[index],
            parsing: {
              yAxisKey: item
            }
          }
        }
        )
      }
    }
    else {
      return {
        labels: [],
        datasets: []
      }
    }
  };

  useEffect(() => {
    if (dataKey && index) {
      setSelectedSubitem({ dataKey, index: +index })
    }
  }, [])

  return (
    <Grid ref={wrapperInstance} sx={{ height: '86%', display: 'flex', alignItems: 'center' }}>

      <Line width={isChartZooming ? '' : '879'} height={isChartZooming ? '' : '200'}
        options={options}
        data={getDataObj()}
      />
    </Grid>
  )
}
